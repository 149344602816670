import React, {createContext, ReactElement} from 'react';
import {IAppState, getNewAppState} from './interface';
import {AppStateActionTypes} from './reducer';

export interface IAppContext {
    state: IAppState;
    dispatch: React.Dispatch<AppStateActionTypes>;
    notify: (title: string, body: React.ReactNode) => void;
}

export const AppContext = createContext<IAppContext>({
    state: getNewAppState(),
    dispatch: () => {},
    notify: () => {},
})