import React from 'react';
import {IMessageModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel} from 'components/forms';

export const MessageViewData : IViewData<IMessageModel> = ({
    data: {
        message,
        filters,
        },
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedLabel
                label="Message"
                help={[]}
                value={message}
                />
            <WrappedLabel
                label="Filters"
                help={[]}
                value={filters}
                />
        </SubHeader>
    </>
