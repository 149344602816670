import React from 'react';
import {IAuditLogModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel} from 'components/forms';
import {DateTime} from 'luxon';

export const AuditLogViewData : IViewData<IAuditLogModel> = ({
    data: {
        id, //: UUID;
        access_id, //: UUID;
        access_table, //: 'user_account' | 'token',
        user_name, //: string;
        item_id, //: UUID;
        item_table, //: string;
        action_taken, //: string;
        explanation, //: string;
        payload, //: string | null;
        action_datetime, //: number;
    },
}) => 
<>
<SubHeader label='General' help={[]}>
    <WrappedLabel
        label="ID"
        help={[]}
        value={id}
        />
    <WrappedLabel
        label="Access Id"
        help={[]}
        value={access_id}
        />
    <WrappedLabel
        label="Access Table"
        help={[]}
        value={access_table}
        />
    <WrappedLabel
        label="User Name"
        help={[]}
        value={user_name}
        />
    <WrappedLabel
        label="Item Id"
        help={[]}
        value={item_id}
        />
    <WrappedLabel
        label="Item Table"
        help={[]}
        value={item_table}
        />
    <WrappedLabel
        label="Action"
        help={[]}
        value={action_taken}
        />
    <WrappedLabel
        label="Explanation"
        help={[]}
        value={explanation}
        />
    <WrappedLabel
        label="Payload"
        help={[]}
        value={payload || ''}
        />
    <WrappedLabel
        label="Date Time"
        help={[]}
        value={DateTime.fromSeconds(action_datetime).toFormat('DD HH:mm:ss')}
        />
    </SubHeader>
</>