import React from 'react';
import {
    DashboardOutlined,
    SettingOutlined,
    DatabaseOutlined,
    ProjectOutlined,
    PieChartOutlined,
    LineChartOutlined,
    TeamOutlined,
    UserOutlined,
    TagOutlined,
    TableOutlined,
    BarChartOutlined,
    ProfileOutlined,
    ScheduleOutlined,
    AuditOutlined,
    GlobalOutlined,
    DesktopOutlined,
    CloudServerOutlined,
    NotificationOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { IAppState } from '../state';

import {
    Dashboard,
    CustomerListView,
    JobListView,
    UserListView,
    TokenListView,
    CustomerItemEdit,
    JobItemEdit,
    UserItemEdit,
    TokenItemEdit,
    CurrentUserView,
    StateTimeView,
    CompletedItemTableView,
    CompletedItemGraphView,
    TopLossesView,
    ScheduleListView,
    ScheduleItemEdit,
    SubscriptionListView,
    SubscriptionItemEdit,
    MessageListView,
    MessageItemEdit,
    JobOverviewListView,
    JobSubscriptionViewListView,
    AuditLogListView,
    UtilitiesView,
    DocsView,
} from '../views';

export interface IRoute {
    visible?: boolean;
    menuHide?: boolean;
    label?: string;
    icon?: React.ReactElement;
    element?: React.ReactElement;
    routes?: Record<string, IRoute>;
}

export const routes = (state: IAppState): Record<string, IRoute> => ({
    '/home': {
        label: 'Home',
        routes: {
            '/rbi': {
                label: 'RBI',
                menuHide: !state.userAccount?._is_rbi,
                icon: <DesktopOutlined />,
                routes: {
                    '/job-overview': {
                        label: 'Job Overview',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <GlobalOutlined />,
                        element: <JobOverviewListView />,
                    },
                    '/subscription-overview': {
                        label: 'Subscriptions',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <AuditOutlined />,
                        element: <JobSubscriptionViewListView />,
                    },
                    '/customers': {
                        label: 'Customers',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <ProjectOutlined />,
                        element: <CustomerListView />,
                    },
                    '/customers/edit/:customer_id': {
                        label: 'Customers',
                        menuHide: true,
                        element: <CustomerItemEdit />,
                    },
                    '/customers/new': {
                        label: 'Customers',
                        menuHide: true,
                        element: <CustomerItemEdit isNew />,
                    },
                    '/messages': {
                        label: 'Messages',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <NotificationOutlined />,
                        element: <MessageListView />,
                    },
                    '/messages/edit/:message_id': {
                        label: 'Messages',
                        menuHide: true,
                        element: <MessageItemEdit />,
                    },
                    '/messages/new': {
                        label: 'Messages',
                        menuHide: true,
                        element: <MessageItemEdit isNew />,
                    },
                    '/audit-log': {
                        label: 'Audit Log',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <CloudServerOutlined />,
                        element: <AuditLogListView />,
                    },
                    '/utilities': {
                        label: 'Utilities',
                        menuHide: !state.userAccount?._is_rbi,
                        icon: <SettingOutlined />,
                        element: <UtilitiesView />,
                    },
                }
            },
            '/dashboard': {
                menuHide: !state.customer,
                label: 'Dashboard',
                icon: <DashboardOutlined />,
                element: <Dashboard />,
            },
            '/time-tracker': {
                menuHide: !state.customer || !state.job || !state.config,
                label: 'Time Tracker',
                icon: <PieChartOutlined />,
                element: <StateTimeView />,
            },
            '/completed-items': {
                menuHide: !state.customer || !state.job || !state.config,
                label: 'Completed Items',
                icon: <DatabaseOutlined />,
                routes: {
                    '/table': {
                        menuHide: !state.customer || !state.job || !state.config,
                        label: 'Table',
                        icon: <TableOutlined />,
                        element: <CompletedItemTableView />,
                    },
                    '/chart': {
                        menuHide: !state.customer || !state.job || !state.config,
                        label: 'Chart',
                        icon: <BarChartOutlined />,
                        element: <CompletedItemGraphView />,
                    },
                }
            },
            '/top-losses': {
                menuHide: !state.customer || !state.job || !state.config,
                label: 'Top Losses',
                icon: <LineChartOutlined />,
                element: <TopLossesView />,
            },
            '/schedules': {
                label: 'Schedules',
                menuHide: !state.customer || !state.job,
                icon: <ScheduleOutlined />,
                element: <ScheduleListView />,
            },
            '/schedules/edit/:schedule_id': {
                label: 'Schedules',
                menuHide: true,
                element: <ScheduleItemEdit />,
            },
            '/schedules/new': {
                label: 'Schedules',
                menuHide: true,
                element: <ScheduleItemEdit isNew />,
            },
            '/admin': {
                label: 'Admin',
                menuHide: !state.userAccount?.is_admin && !state.userAccount?._is_rbi,
                icon: <SettingOutlined />,
                routes: {

                    '/user-accounts': {
                        label: 'User Accounts',
                        menuHide: !state.customer,
                        icon: <TeamOutlined />,
                        element: <UserListView />,
                    },
                    '/user-accounts/edit/:user_account_id': {
                        label: 'User Accounts',
                        menuHide: true,
                        element: <UserItemEdit />,
                    },
                    '/user-accounts/new': {
                        label: 'Users',
                        menuHide: true,
                        element: <UserItemEdit isNew />,
                    },
                    '/tokens': {
                        label: 'Tokens',
                        menuHide: !state.customer || !state.job,
                        icon: <TagOutlined />,
                        element: <TokenListView />,
                    },
                    '/tokens/edit/:token_id': {
                        label: 'Tokens',
                        menuHide: true,
                        element: <TokenItemEdit />,
                    },
                    '/tokens/new': {
                        label: 'Tokens',
                        menuHide: true,
                        element: <TokenItemEdit isNew />,
                    },
                    '/subscriptions': {
                        label: 'Subscriptions',
                        menuHide: !state.userAccount?._is_rbi || !state.customer || !state.job,
                        icon: <AuditOutlined />,
                        element: <SubscriptionListView />,
                    },
                    '/subscriptions/edit/:subscription_id': {
                        label: 'Subscriptions',
                        menuHide: true,
                        element: <SubscriptionItemEdit />,
                    },
                    '/subscriptions/new': {
                        label: 'Subscriptions',
                        menuHide: true,
                        element: <SubscriptionItemEdit isNew />,
                    },
                }
            },
            '/jobs': {
                label: 'Jobs',
                menuHide: !state.customer,
                icon: <ProfileOutlined />,
                element: <JobListView />,
            },
            '/jobs/edit/:job_id': {
                label: 'Jobs',
                menuHide: true,
                element: <JobItemEdit />,
            },
            '/jobs/new': {
                label: 'Jobs',
                menuHide: true,
                element: <JobItemEdit isNew />,
            },
            [`/${state.userAccount?.id || ''}`]: {
                menuHide: !state.userAccount,
                label: state.userAccount?.name || '',
                icon: <UserOutlined />,
                element: <CurrentUserView />,
            },
            '/docs': {
                label: 'Docs',
                icon: <InfoCircleOutlined />,
                element: <DocsView />,
            },
        },
    },
})
