import React, { useState, useEffect, useContext } from 'react';
import { Typography, TableColumnProps, Button, Spin, Tooltip } from 'antd';
import { EditTwoTone, EyeTwoTone, CloudServerOutlined, UserOutlined, SelectOutlined } from '@ant-design/icons';
import { AppContext } from '../../../state';
import { TableView } from './table-view';
import { useLoadData } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { StandardItemView, IViewData } from '../forms';
import { AuditLogListView } from '../../../views';

interface IProps<T, TV> {
    title: string;
    allowEdit?: boolean;
    itemKey?: string;
    loadItemPermission: () => Promise<boolean>;
    loadItems: (props: { refresh: boolean }) => Promise<T[]>;
    loadViewItem?: (props: { item: T, refresh: boolean }) => Promise<TV | null>;
    ViewData?: IViewData<TV>;
    smallColumns: TableColumnProps<T>[];
    largeColumns: TableColumnProps<T>[];
    columnEditNav?: (item: T) => string;
    columnViewNav?: (item: T) => string;
    itemLogId?: (item: T) => string;
    accessLogId?: (item: T) => string;
    itemNewNav?: string;
    onSelect?: (item: T) => void;
    isItemLoading?: (item: T) => boolean;
    itemIsLoading?: boolean;
}

export const ItemListView = <T extends Object, TV extends object>({
    title,
    allowEdit = false,
    itemKey = 'id',
    loadItemPermission,
    loadItems,
    loadViewItem,
    ViewData,
    smallColumns,
    largeColumns,
    columnEditNav,
    columnViewNav,
    itemLogId,
    accessLogId,
    itemNewNav,
    onSelect,
    isItemLoading = () => false,
    itemIsLoading = false,
}: IProps<T, TV>) => {
    const [viewItem, setViewItem] = useState<T | null>(null);
    const [auditItem, setAuditItem] = useState<T | null>(null);
    const [auditAccess, setAuditAccess] = useState<T | null>(null);
    const [items, setItems] = useState<T[]>([]);
    const { state: { userAccount, isMobile } } = useContext(AppContext);
    const {
        loading,
        setLoading,
        message,
        setMessage,
    } = useLoadData({});

    const navigate = useNavigate();

    useEffect(() => {
        if (!items.length)
            load({ refresh: false });
    }, [])

    const load = async (props: { refresh: boolean }) => {
        setLoading(true);
        try {
            if (await loadItemPermission()) {
                setItems(await loadItems(props));
                setMessage('');
            } else setMessage('Function not allowed.');
        } catch (e) {
            console.log(e);
            setMessage('Error getting data.');
        }
        setLoading(false);
    }

    const selectColumn: TableColumnProps<T> = ({
        title: 'Select',
        dataIndex: itemKey,
        key: itemKey,
        render: (_, item) => (
            isItemLoading(item)
                ? <Spin size='small' />
                : <Button disabled={itemIsLoading} type='primary' onClick={() => onSelect ? onSelect(item) : null} size='small'>
                    <SelectOutlined /> Select
                </Button>
        )
    });

    const viewActionButton = (item: T) =>
        columnViewNav
            ? <Tooltip title='View'>
                <Button style={{ marginRight: 5 }} onClick={() => setViewItem(item)} size='small'>
                    <EyeTwoTone twoToneColor='#000' />
                </Button>
            </Tooltip>
            : null;

    const editActionButton = (item: T) =>
        columnEditNav
            ? <Tooltip title='Edit'>
                <Button style={{ marginLeft: 10 }} onClick={() => navigate(columnEditNav(item))} size='small'>
                    <EditTwoTone twoToneColor={'#04f'} />
                </Button>
            </Tooltip>
            : null;

    const itemLogButton = (item: T) =>
        userAccount?._is_rbi && itemLogId
            ? <Tooltip title='Item Audit Log'>
                <Button style={{ marginLeft: 10 }} onClick={() => setAuditItem(item)} size='small'>
                    <CloudServerOutlined style={{ color: '#077' }} />
                </Button>
            </Tooltip>
            : null;


    const accessLogButton = (item: T) =>
        userAccount?._is_rbi && accessLogId
            ? <Tooltip title='Access Audit Log'>
                <Button style={{ marginLeft: 10 }} onClick={() => setAuditAccess(item)} size='small'>
                    <UserOutlined style={{ color: '#077' }} />
                </Button>
            </Tooltip>
            : null;

    const columns: TableColumnProps<T>[] = [
        {
            title: 'Actions',
            dataIndex: itemKey,
            key: 'id',
            render: (_, item) => (
                <>
                    {viewActionButton(item)}
                    {allowEdit ? editActionButton(item) : null}
                    {itemLogButton(item)}
                    {accessLogButton(item)}
                </>
            ),
        },
        ...(isMobile ? smallColumns : largeColumns).map(col => ({ ...col, key: itemKey })),
        ...(
            onSelect
                ? [selectColumn]
                : []
        )
    ]

    if (message) return <>
        {message}
    </>

    if (auditItem && itemLogId)
        return (
            <>
                <Button type='primary' style={{ width: 200 }} onClick={() => setAuditItem(null)}>Back</Button>
                <AuditLogListView item_id={itemLogId(auditItem) || ''} />
            </>
        )

    if (auditAccess && accessLogId)
        return (
            <>
                <Button type='primary' style={{ width: 200 }} onClick={() => setAuditAccess(null)}>Back</Button>
                <AuditLogListView access_id={accessLogId(auditAccess) || ''} />
            </>
        )

    return (
        <>

            <Typography.Title>{title}</Typography.Title>
            <TableView
                allowEdit={allowEdit}
                loading={loading}
                items={items}
                columns={columns}
                onRefresh={() => load({ refresh: true })}
                onNew={() => itemNewNav ? navigate(itemNewNav) : null}
            />
            {(loadViewItem && ViewData) ?
                <StandardItemView
                    onLoad={async ({ refresh }) => viewItem ? loadViewItem({ item: viewItem, refresh }) : null}
                    ViewData={ViewData}
                    onClose={() => setViewItem(null)}
                    visible={viewItem !== null}
                /> : null}

        </>
    )
}
