import { UUID } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export interface ISubscriptionModel {
    id: UUID;
    job_id: UUID;
    length_years: number;
    length_days: number;
    order_number: string;
    part_number: string;
    part_quantity: number;
}

interface INewModelProps {
    job_id: UUID;
}

const updateKeys: Extract<keyof ISubscriptionModel, string>[] = [
    'length_years',
    'length_days',
    'order_number',
    'part_number',
    'part_quantity',
]

export const subscriptionModelData: IModelData<ISubscriptionModel, INewModelProps> = {
    table: 'subscription',
    selectKeys: [
        'id',
        'job_id',
        ...updateKeys,
    ],
    insertKeys: [
        'job_id',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ job_id }: INewModelProps) => ({
        id: '',
        job_id,
        length_years: 0,
        length_days: 0,
        order_number: '',
        part_number: '',
        part_quantity: 1,

    }),
    validate: ({ partial }) => item => validator<ISubscriptionModel>({
        item,
        partial,
        config: {
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            length_years: {
                number: {
                    minValue: 0,
                    maxValue: 10,
                },
            },
            length_days: {
                number: {
                    minValue: 0,
                    maxValue: 365,
                },
            },
            order_number: {
                string: {
                    minLength: 1,
                }
            },
            part_number: {
                string: {
                    minLength: 1,
                }
            },
            part_quantity: {
                number: {
                    minValue: 1,
                }
            },
        },

    })
}
