import React from 'react';
import {IShiftData} from '__core/models';

import {BlockDisplay} from './block-display';

const HOURS = new Array(24).fill(0).map((_,i) => i);
const TIMES = new Array(24*4).fill(0).map((_,i) => i);
const DAYS = ['Monday', 'Tuesday','Wednesday', 'Thursday','Friday','Saturday','Sunday']

interface IProps {
    shifts: IShiftData[];
    onChange: (range: {day: number, time: number}) => void;
    colors: string[];
}

export const DayEdit = ({ 
    shifts, 
    onChange,
    colors,
}: IProps) =>
<div>
    {
        DAYS.map((day,dayIndex) => 
            <div>
                <h4 style={{margin: 2}}>
                    {day}
                </h4>
                <div style={{display: 'flex'}} draggable="false">
                    {HOURS.map((time,index) =>
                        <div 
                        key={index} 
                        style={{
                            margin: -1,
                            width: '6%',  
                            border: `1px solid #888`,
                            textAlign: 'center',
                            fontSize:'75%', 
                            minWidth: 12,
                            backgroundColor: `${index%2?'#aaa':'#ddd'}` }}>
                            {time.toFixed(0).padStart(2,'0')}
                        </div>
                    )}
                </div>
                <div style={{display:'flex', height: 60}}>
                    {
                        TIMES.map( (_,timeIndex: any) => 
                        <div 
                            key={timeIndex} 
                            draggable="false"
                            style={{ 
                                display: 'inline-block', 
                                height: '100%', 
                                width: '1.5%',
                                minWidth: 3,
                                backgroundColor: '#fff', 
                                border: '1px solid rgba(0,0,0,0.5)'
                            }} 
                            onMouseDown={ () => { onChange({day: dayIndex, time: timeIndex})}}
                            onMouseMove={ () => { onChange({day: dayIndex, time: timeIndex})}}>
                            <BlockDisplay colors={colors.filter((c,i) => shifts[i].times[dayIndex][timeIndex])}/>
                        </div>
                    )}
                </div>
            </div>
        )
    }
</div>