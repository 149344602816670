import React, { useContext } from 'react';
import { ICompletedItemModel, ICompletedItemRenderConfig } from '__core/models';
import { AppContext } from '../../../state';
import { } from './';
import { summarize } from './summarize';
import { GraphItemView } from './graph-item';
import { GraphSelectionView } from './graph-selection-view';

interface IProps {
    data: ICompletedItemModel[];
}

export const GraphView = ({ data }: IProps) => {

    const { state } = useContext(AppContext);

    if (!state.job || !state.config) return null;
    const metric = state.job.is_metric;

    const timeZone = state.job!.time_zone;
    const completedItemConfig = state.config.config.completedItems;

    const tonsTag = Object.entries(completedItemConfig.data).find(([key, value]) => value.header.toLowerCase().includes('weight'));
    const tonsTagRenderConfig: ICompletedItemRenderConfig = {
        header: 'Tons',
        tag: '',
        model: {
            decimals: 1,
            scale: 1 / 2000,
            unit: ['ton', 'mton'],
        }
    }

    const { summary, divBy } = summarize({ startTime: state.timeIntervalSelect.startTime, endTime: state.timeIntervalSelect.endTime, timeZone, data })

    return (
        <>
            <div style={{ margin: 5 }}>
                <GraphSelectionView />
            </div>
            <div style={{ width: '100%' }}>
                {tonsTag && state.completedItemCharts.tons.use
                    ? <>
                        {state.completedItemCharts.tons.showSum
                            ? <GraphItemView
                                renderConfig={tonsTagRenderConfig}
                                getDataFunction={item => Number(item.data[Number(tonsTag[0])])}
                                data={summary}
                                type='Sum'
                                divBy={divBy}
                                metric={metric}
                                mobile={state.isMobile}
                            />
                            : null
                        }
                        {
                            state.completedItemCharts.tons.showItemAverage
                                ? <GraphItemView
                                    renderConfig={tonsTagRenderConfig}
                                    getDataFunction={item => Number(item.data[Number(tonsTag[0])])}
                                    data={summary}
                                    type='Item Average'
                                    divBy={divBy}
                                    metric={metric}
                                    mobile={state.isMobile}
                                />
                                : null
                        }
                    </>
                    : null
                }
                {
                    Object.entries(state.completedItemCharts).map(([name, settings]) => {
                        if (!settings.use) return null;

                        const nameKeys = name.split(';');

                        const getDataFunction: (item: ICompletedItemModel) => number =
                            nameKeys[0] === 'data'
                                ? (item: ICompletedItemModel) => Number(item.data[Number(nameKeys[1])] || 0)
                                : nameKeys[0] === 'extended'
                                    ? (item: ICompletedItemModel) => Number(item.extended ? item.extended[Number(nameKeys[1])] || 0 : 0)
                                    : nameKeys[0] === 'duration'
                                        ? (item: ICompletedItemModel) => item.end_time - item.start_time
                                        : nameKeys[0] === 'quantity'
                                            ? (item: ICompletedItemModel) => item.count
                                            : () => 0;

                        const renderConfig: ICompletedItemRenderConfig | undefined =
                            nameKeys[0] === 'data'
                                ? completedItemConfig.data[Number(nameKeys[1])]
                                : nameKeys[0] === 'extended'
                                    ? completedItemConfig.extended[Number(nameKeys[1])]
                                    : nameKeys[0] === 'duration'
                                        ? { header: 'Duration', tag: '', model: { time: true } }
                                        : nameKeys[0] === 'quantity'
                                            ? { header: 'Quantity', tag: '', model: {} }
                                            : undefined;

                        return (
                            <>
                                {
                                    settings.showSum
                                        ? <GraphItemView
                                            renderConfig={renderConfig}
                                            getDataFunction={getDataFunction}
                                            data={summary}
                                            type='Sum'
                                            divBy={divBy}
                                            metric={metric}
                                            mobile={state.isMobile}
                                        />
                                        : null
                                }
                                {
                                    settings.showItemAverage
                                        ? <GraphItemView
                                            renderConfig={renderConfig}
                                            getDataFunction={getDataFunction}
                                            data={summary}
                                            type='Item Average'
                                            divBy={divBy}
                                            metric={metric}
                                            mobile={state.isMobile}
                                        />
                                        : null
                                }
                            </>
                        );
                    })
                }
            </div>
        </>
    )
}
