import React, {useState} from 'react';

interface IProps {
    initialLoading?: boolean;
    initialMessage?: string;
}

export const useLoadData = ({initialLoading = false, initialMessage = ''}: IProps) => {
    const [loading, setLoading] = useState(initialLoading);
    const [message, setMessage] = useState(initialMessage)

    return {
        loading,
        setLoading,
        message,
        setMessage,
    }
}