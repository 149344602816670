import { DateTime } from 'luxon';

export const formatDurationSeconds = (seconds: number) => {
    const s = Math.trunc(seconds);
    return s < 60
        ? `${s}s`
        : s < 3600
            ? `${Math.trunc(s / 60)}m:${(s % 60).toString().padStart(2, '0')}s`
            : `${Math.trunc(s / 3600)}h:${Math.trunc((s % 3600) / 60).toString().padStart(2, '0')}m:${(s % 60).toString().padStart(2, '0')}s`
}

export const formatTimeSince = (time: number = 0) => {
    const diff = Math.trunc(Date.now() / 1000) - time;
    return time === 0
        ? '---'
        : diff < 60
            ? `${Math.trunc(diff)} sec ago`
            : diff < 3600
                ? `${Math.trunc(diff / 60)} min ago`
                : diff < 86400
                    ? `${Math.trunc(diff / 3600)} hour${Math.trunc(diff / 3600) > 1 ? 's' : ''} ago`
                    : `${Math.trunc(diff / 86400)} day${Math.trunc(diff / 86400) > 1 ? 's' : ''} ago`
}

export const formatUnixTime = (time: number) => DateTime.fromSeconds(time).toFormat('yyyy-MM-dd HH:mm:ss');