import React from 'react';
import {ICustomerModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel, WrappedList} from 'components/forms';


export const CustomerViewData : IViewData<ICustomerModel> = ({
    data: {
        name, 
        description,
        email_domains
    },
}) => 
<>
    <SubHeader label='General' help={[]}>
        <WrappedLabel
            label='Name'
            help={[]}
            value={name}
            />
        <WrappedLabel
            label='Description'
            help={[]}
            value={description}
            />
        <WrappedList
            label='Email Domains'
            help={[]}
            value={email_domains.split(';').map(x => x.trim())}
            />
    </SubHeader>
</>
