import React, { useState } from 'react';
import {IShiftData} from '__core/models';
import {Selector} from './selector';
import {DayEdit} from './day-edit';

const COLORS = [
    '#C00',
    '#0C0',
    '#007',
    '#AA0',
    '#C0C',
    '#0AA',
    '#444',
]

interface IRange {
    day: number;
    time: number;
}

interface IProps {
    shifts : IShiftData[];
    onChange?: (data: IShiftData[]) => void;
}

export const ColoredWeekSchedule = ({
    shifts,
    onChange,
}:IProps) => { 

    const [rangeActive, setRangeActive] = useState(false);
    const [overlap, setOverlap] = useState(false);
    const [selected, setSelected] = useState<string|null>(null);
    const [rangeStart, setRangeStart] = useState<IRange|null>(null);
    const [rangeLast, setRangeLast] = useState<IRange|null>(null);
    const [savedData, setSavedData] = useState<IShiftData[]|null>(null);

    const onRangeChange = (range: IRange) => {

        if (!rangeActive) return;
        
        const _rangeStart = rangeStart || range;

        if( !rangeStart) setRangeStart(_rangeStart);
        
        if ( rangeLast && (rangeLast.day === range.day && rangeLast.time === range.time)) return;

        const sortedRange : IRange[] = [
            {
                day: Math.min(_rangeStart.day, range.day),
                time: Math.min(_rangeStart.time, range.time),
            },
            {
                day: Math.max(_rangeStart.day, range.day),
                time: Math.max(_rangeStart.time, range.time),
            }
        ];

        console.log(range, sortedRange);

        const isInRange = (checkRange: IRange) => 
                checkRange.day >= sortedRange[0].day
            &&  checkRange.day <= sortedRange[1].day
            &&  checkRange.time >= sortedRange[0].time
            &&  checkRange.time <= sortedRange[1].time

        setSavedData(shifts.map(shift => 
            shift.name !== selected
            ?   overlap && selected
                ?   shift
                :   {
                    name: shift.name,
                    times: shift.times.map((day, dayIndex) => day.map((time,timeIndex) => isInRange({day: dayIndex, time: timeIndex}) ? false : time)),
                    }
            :   {
                    name: shift.name,
                    times: shift.times.map((day, dayIndex) => day.map((time,timeIndex) => isInRange({day: dayIndex, time: timeIndex}) ? true : time)),
                }
            ))

        setRangeLast(range);
    }

    const onRangeReset = (commit = false) => {
        setRangeActive(false);
        if (commit && savedData && onChange)
            onChange(savedData);
        setRangeStart(null);
        setRangeLast(null);
        setSavedData(null);
    }

    const onRangeMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation();
        
        if(event.buttons === 1)
            setRangeActive(true);
        else{
            onRangeReset();
        }
    }

    const onRangeMouseUp = (event: React.MouseEvent) => {
        event.stopPropagation();
        if(rangeActive)
            onRangeReset(true);
    }

    return (
        <div
            style={{userSelect:'none'}}
            onMouseDown={onChange ? onRangeMouseDown : undefined}
            onMouseUp={onChange ? onRangeMouseUp : undefined}
            onClick={e=>e.stopPropagation()}>
            <Selector 
                editable={!!onChange}
                items={shifts.map(({name}, i) => ({name, color: COLORS[i]}) )}
                overlap={overlap}
                selected={selected}
                onChangeOverlap={() => setOverlap(!overlap)}
                onChangeSelected={setSelected} />
            <DayEdit 
                shifts={savedData || shifts}
                colors={COLORS.slice(0,shifts.length)}
                onChange={onRangeChange}/>
        </div>
    );
}
