import { UUID } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export interface IAuditLogModel {
    id: UUID;
    access_id: UUID;
    access_table: 'user_account' | 'token',
    user_name: string;
    item_id: UUID;
    item_table: string;
    action_taken: string;
    explanation: string;
    payload: string | null;
    action_datetime: number;
}

interface INewModelProps {
    // intentionally blank interface
}

const insertKeys: Extract<keyof IAuditLogModel, string>[] = [
    'access_id',
    'access_table',
    'user_name',
    'item_id',
    'item_table',
    'action_taken',
    'explanation',
    'payload',
    'action_datetime',
]

export const auditLogModelData: IModelData<IAuditLogModel, INewModelProps> = {
    table: 'audit_log',
    selectKeys: ['id', ...insertKeys],
    insertKeys,
    updateKeys: [],
    getNewModel: () => ({
        id: '',
        access_id: '',
        access_table: 'token',
        user_name: '',
        item_id: '',
        item_table: '',
        action_taken: '',
        explanation: '',
        payload: null,
        action_datetime: 0,
    }),
    validate: ({ partial }) => item => validator<IAuditLogModel>({
        item,
        partial,
        config: {},
    })
}
