import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

/**
 * publish to npm package or update RBI HMI if this updates
 */
export interface ICompletedItemModel {
    id?: UUID;
    job_id?: UUID;
    continue?: boolean;
    start_time: UnixTime;
    end_time: UnixTime;
    count: number;
    data: Record<number, string | number | boolean>;
    extended?: Record<number, string | number | boolean>;
}

const updateKeys: Extract<keyof ICompletedItemModel, string>[] = [
    'end_time',
    'count',
    'data',
    'extended',
]

export const completedItemModelData: IModelData<ICompletedItemModel> = {
    table: 'completed_item',
    selectKeys: updateKeys,
    insertKeys: ['job_id', 'start_time', ...updateKeys],
    updateKeys,
    getNewModel: () => ({
        start_time: 0,
        end_time: 0,
        count: 0,
        data: {},
        extended: {},
    }),
    validate: ({ partial }) => item => validator<ICompletedItemModel>({
        item,
        partial,
        config: {
            start_time: {
                number: { minValue: 0 },
            },
            end_time: {
                number: { minValue: 0 }
            },
            count: {
                number: { minValue: 1 }
            },
            data: {
                type: 'object'
            },
            extended: {
                type: 'object'
            },
        }
    })
}
