import React, { useContext } from 'react';
import { ICustomerModel, customerModelData } from '__core/models';
import { AppContext } from '../../state';
import { useParams } from 'react-router-dom';
import { IFormData, StandardForm, SubHeader, WrappedInput, WrappedMultiInput } from 'components/forms';

interface IProps {
    isNew?: boolean;
}

export const CustomerItemEdit = ({
    isNew,
}: IProps) => {
    const { customer_id = '' } = useParams();
    const { state } = useContext(AppContext);

    const formData: IFormData<ICustomerModel> = ({
        data: {
            name,
            description,
            email_domains
        },
        update,
    }) =>
        <>
            <SubHeader label='General' help={[]}>
                <WrappedInput
                    label="Name"
                    help={[]}
                    value={name}
                    onChange={name => update({ name })}
                />
                <WrappedInput
                    label="Description"
                    help={[]}
                    value={description}
                    onChange={description => update({ description })}
                />
                <WrappedMultiInput
                    label="Email Domains"
                    help={[]}
                    value={email_domains.split(';').map(x => x.trim()).filter(x => x.startsWith('@'))}
                    onChange={email_domains => update({ email_domains: email_domains.join(';') })}
                />
            </SubHeader>
        </>

    const { insert, update, delete: _delete } = state.api.write.customer;

    return <StandardForm
        title='Customer'
        loadItemPermission={async () => !!customer_id}
        onLoad={isNew ? undefined : async () => state.api.read.customer.item({ customer_id, refresh: true })}
        getNew={() => customerModelData.getNewModel()}
        onCancelNavigate='/rbi/customers'
        onInsert={async item => insert({ item })}
        onUpdate={async item => update({ customer_id, item })}
        // onDelete={async () => _delete({customerId})}
        formData={formData}
    />
}
