import React, {useState, useEffect, useContext} from 'react';
import {Form, Spin, Drawer, Button} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import {useLoadData} from '../../hooks';
import {AppContext} from '../../../state/context';

interface IViewDataProps<T> {
    data: T,
}

export type IViewData<T> = React.FunctionComponent<IViewDataProps<T>>;//= (props: IViewDataProps<T>) => JSX.Element;

interface IProps<T> {
    onLoad: (props: {refresh: boolean}) => Promise<T | null>;
    ViewData: IViewData<T>;
    onClose: () => void;
    visible: boolean;
}

export const StandardItemView = <T extends Object>({
    onLoad,
    ViewData,
    onClose,
    visible,
}: IProps<T>) => {

    const {state: {isMobile}} = useContext(AppContext);

    const [item, setItem] = useState<T | null>(null);

    const {
        loading,
        setLoading,
        message,
        setMessage,
    } = useLoadData({});
    
    useEffect(() => {
        if (visible) load();
    },[visible])

    const load = async () => {
        setLoading(true);
        try {
            const loadedItem = await onLoad!({refresh: false});
            if (loadedItem === null) throw new Error();
            setItem( loadedItem);
            setMessage('');
        } catch(e){
            console.log(e);
            setMessage('Error getting data.');
        }
        setLoading(false);
    }

    const ViewContents = () => {
        if (message) return <>{message}</>
        if (loading) return <Spin tip='Loading...' size='large' />;
        if (!item) return <>Oops! Something went wrong!</>
        return (
            <Form 
                style={{width: '100%'}}
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
                labelWrap
                
            >
                <ViewData data={item}/>
            </Form>
        )
    }

    return (
        <Drawer 
            visible={visible} 
            onClose={() => onClose()}
            width={isMobile ? '100%' : 600}
            >
            <Button size='small' onClick={() => onLoad({refresh: true})}><ReloadOutlined/>Reload</Button>
            <ViewContents/>
        </Drawer>
    )
}
