import React from 'react';
import {ICompletedItemRenderConfig, ICompletedItemModel} from '__core/models'
import {ISummary} from './summarize';
import {DateTime} from 'luxon';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid} from 'recharts';
import {NumericModel} from '@redbudindustries/node-numeric-model';

interface IProps {
    renderConfig?: ICompletedItemRenderConfig;
    getDataFunction?: (item: ICompletedItemModel) => number;
    data: ISummary[];
    type: 'Sum' | 'Item Average';
    divBy: string;
    metric?: boolean
    mobile: boolean;
}

export const GraphItemView = ({renderConfig, getDataFunction, data, type, divBy, metric, mobile}:IProps) => {
    if (!renderConfig || !getDataFunction) return null;
    

    if (!renderConfig.model) return null;

    const series = data.reduce<{startTime: number, value: number}[]>(
        (a,c) => [
            ...a, 
            {
                startTime: c.startTime, 
                value: c.data.reduce((a,c,i)=> {
                    const addend1 = a;
                    const addend2 = getDataFunction(c);
                    return type === 'Sum'
                        ?   addend1 + addend2
                        :   addend1 * (i/(i+1)) + addend2 * (1/(i+1));
                },0)
            }
        ],[]);

    const total = type === 'Sum' 
        ? series.reduce<number>((a,c) => a + c.value,0)
        : data.reduce<number[]>(
            (a,c) => [
                ...a,
                ...c.data.map(getDataFunction),
            ],[]).reduce((a,c,i)=> a * (i/(i+1)) + c * (1/(i+1)),0);

    const intervalAverage = type === 'Sum' ? total/series.length : 0;

    const xFormat = divBy === 'hour'
        ?   'HH'
        :   divBy === 'day' || divBy === 'week'
        ?   'MM/dd'
        :   'MM';
        
    const model = new NumericModel({...renderConfig.model, maxLength: 12}).setMetric(metric);

    return (
        <div>
            <h3><b>{renderConfig.header}</b> - {type}: {model.getValueString(total)} {model.getUnitString()}{type === 'Sum' ? ` -  Interval Average: ${model.getValueString(intervalAverage)} ${model.getUnitString()}` : ''}</h3>
            <ResponsiveContainer width='100%' height={mobile ? 200 : 400}>
                <BarChart data={series}>
                    <XAxis dataKey="startTime" tickFormatter={value => DateTime.fromSeconds(Number(value)).toFormat(xFormat)}/>
                    <YAxis tickFormatter={value => model.getValueString(Number(value))} tickCount={10}/>
                    <Tooltip formatter={value => `${model.getValueString(Number(value))} ${model.getUnitString()}`} labelFormatter={value => DateTime.fromSeconds(Number(value)).toFormat(xFormat)}/>
                    <CartesianGrid stroke="#f5f5f5" fill='#ccf'/>
                    <Bar 
                        dataKey={'value'} 
                        fill='#225' 
                        yAxisId={0} 
                        isAnimationActive={false} 
                        shape={props => <rect x={props.x} y={props.y} width={props.width} height={props.height} fill='#225' rx={props.width*0.02} ry={props.width*0.02} />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
