import { ICompletedItemFilter } from "../../../../state";

export const numericFilter = (filter: ICompletedItemFilter, name: string, value: number): boolean => {
    if (filter[name] && filter[name].use) {
        if (filter[name].type === 'limits') {
            //console.log(name, filter[name], value, Number(filter[name].value), value <= Number(filter[name].max))
            return value >= Number(filter[name].value) && value <= Number(filter[name].max)
        } else {
            return value === Number(filter[name].value)
        }
    } else {
        return true;
    }
}

export const stringFilter = (filter: ICompletedItemFilter, name: string, value: string): boolean => {
    if (filter[name] && filter[name].use) {
        if (filter[name].type === 'limits') {
            //console.log(name, filter[name], value, Number(filter[name].value), value <= Number(filter[name].max))
            return value >= filter[name].value && value <= filter[name].max
        } else {
            //console.log(name, value, filter[name].value, value.includes(filter[name].value))
            return value.includes(filter[name].value)
        }
    } else {
        //console.log(value, filter[name]);
        return true;
    }
}
/*
export const numericFilter = (filter: ICompletedItemFilter, name: string, value: number): boolean => 
    filter[name] && filter[name].use
?   filter[name].type === 'limits'
    ?   value >= Number(filter[name].value) && value <= Number(filter[name].max)
    :   value === Number(filter[name].value)
:   true
*/

export const selectFilter = (filter: ICompletedItemFilter, name: string, value: string): boolean =>
    filter[name] && filter[name].use
        ? filter[name].value === value
        : true