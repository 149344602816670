import React from 'react';
import {Select} from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label: React.ReactNode | string;
    help: string[];
    value : T;
    options: Record<string,T> | string[],
    onChange: (value: T) => void;
}

export const WrappedSelect = <T extends string | number | boolean>({
    label,
    help,
    value,
    options,
    onChange,
}: IProps<T>) => {
    const _options = Array.isArray(options) ? options.map(option => [option,option]) : Object.entries(options);
    return (
        <StandardFormItem label={label} help={help}>
            <Select onChange={e => onChange(e)} value={value} showSearch>
                    {_options.map(([name, value]) => <Select.Option value={value}>{name}</Select.Option>)}

            </Select>
        </StandardFormItem>
    );
}