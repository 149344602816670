import React, { useContext } from 'react';
import { ICompletedItemModel, ICompletedItemRenderConfig } from '__core/models';
import { Table, TableColumnsType, Button, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import { NumericModel, INumericModelPartial, IUnit } from '@redbudindustries/node-numeric-model';
import { useSorter, useWindowDims } from 'hooks';
import { AppContext, IAppState } from '../../../state';
import CsvDownloader from 'react-csv-downloader';
import { DownloadOutlined } from '@ant-design/icons';
import { HEADER_TOOLTIPS } from '../header-tooltips';

interface IProps {
    data: ICompletedItemModel[];
}

const getDurationNumericModelProps = (state: IAppState): INumericModelPartial => ({
    time: state.timeIntervalSelect.durationDisplay === 'time',
    scale: state.timeIntervalSelect.durationDisplay === 'minutes' ? 1 / 60 : 1,
    decimals: state.timeIntervalSelect.durationDisplay === 'minutes' ? [2, 2] : [0, 0],
})

export const TableView = ({ data }: IProps) => {

    const { state } = useContext(AppContext);

    const innerDim = useWindowDims();

    const { genericSorter } = useSorter();

    if (!state.job || !state.config) return null;

    const completedItemConfig = state.config.config.completedItems;

    const metric = state.job.is_metric;
    const timeZone = state.job!.time_zone;

    const render = (type: 'data' | 'extended') => (item: ICompletedItemModel, index: number): string => {
        try {
            const c = completedItemConfig[type][index];
            if (!item[type]) return 'Error';
            const d = item[type]![index];
            if (typeof d === 'undefined') return "";
            if (!c) return 'Error';
            if (c.asString) return d.toString();
            if (c.states) return c.states[d.toString()] || `Error - ${d}`;
            if (c.flags) {
                const nD = Number(d);
                return Object.entries(c.flags).reduce<string[]>((acc, [k, v]) => {
                    if (((nD >> Number(k)) & 1) === 1) acc.push(v);
                    return acc
                }, []).join(', ');
            }
            if (c.model)
                return new NumericModel(c.model.time ? { ...c.model, ...getDurationNumericModelProps(state) } : c.model).setMetric(metric).setValue(Number(d)).getValueString();

            return d.toString();
        } catch (e: any) {
            console.log(e.message);
            return "ERROR";
        }
    }

    const dataKeys = Object.keys(completedItemConfig.data || {});
    dataKeys.map(n => Number(n)).sort();

    const extendedKeys: string[] = state.timeIntervalSelect.extended ? Object.keys(completedItemConfig.extended || {}) : [];
    extendedKeys.map(n => Number(n)).sort();

    const headerDurationUnit = state.timeIntervalSelect.durationDisplay === 'time' ? '' : state.timeIntervalSelect.durationDisplay === 'seconds' ? ' (s)' : ' (min)';

    const columns: TableColumnsType<ICompletedItemModel> = [
        {
            title: <Tooltip title={HEADER_TOOLTIPS.date}>Date</Tooltip>,
            key: 'end_time',
            dataIndex: 'end_time',
            render: (_, { end_time }) => DateTime.fromSeconds(end_time).setZone(timeZone).toFormat('yyyy-MM-dd'),
            ...genericSorter({ name: 'end_time' }),
        },
        {
            title: <Tooltip title={HEADER_TOOLTIPS.startTime}>Start Time</Tooltip>,
            key: 'end_time',
            dataIndex: 'start_time',
            render: (_, { start_time, end_time }) => {
                const startTime = DateTime.fromSeconds(start_time).setZone(timeZone);
                const endTime = DateTime.fromSeconds(end_time).setZone(timeZone);
                return `${startTime.toFormat('HH:mm:ss') + (endTime.day === startTime.day ? '' : ` (-${endTime.day - startTime.day})`)}`;
            },
            ...genericSorter({ name: 'start_time' }),
        },
        {
            title: <Tooltip title={HEADER_TOOLTIPS.endTime}>End Time</Tooltip>,
            key: 'end_time',
            dataIndex: 'end_time',
            render: (_, { end_time }) => DateTime.fromSeconds(end_time).setZone(timeZone).toFormat('HH:mm:ss'),
            ...genericSorter({ name: 'end_time' }),
        },
        {
            title: <Tooltip title={HEADER_TOOLTIPS.duration}>Duration{headerDurationUnit}</Tooltip>,
            key: 'end_time',
            dataIndex: '',
            render: (_, item) => new NumericModel({
                ...getDurationNumericModelProps(state),
            }).setValue(item.end_time - item.start_time).getValueString(),
            sorter: (a, b) => (a.end_time - a.start_time) - (b.end_time - b.start_time),
        },
        {
            title: <Tooltip title={HEADER_TOOLTIPS.quantity}>Quantity</Tooltip>,
            key: 'end_time',
            dataIndex: 'count',
            render: (_, item) => item.count,
            ...genericSorter({ name: 'count' }),
        },
        ...dataKeys.map(key => {
            const nKey = Number(key);
            const itemConfig = completedItemConfig.data[nKey];
            return {
                title: itemConfig ? <Tooltip title={itemConfig.description || ''}>{`${itemConfig.header}${itemConfig.model?.time ? headerDurationUnit : itemConfig.model?.unit ? ` (${Array.isArray(itemConfig.model.unit) ? itemConfig.model?.unit[metric ? 1 : 0] : itemConfig.model?.unit})` : ''}`}</Tooltip> : '???',
                key: 'end_time',
                dataIndex: `data[${nKey}]`,
                render: (_: any, item: ICompletedItemModel) => render('data')(item, nKey),
                sorter: (a: ICompletedItemModel, b: ICompletedItemModel) => Number(a.data[nKey]) - Number(b.data[nKey]),
            };
        }),
        /*
        {
            title: <Tooltip title={HEADER_TOOLTIPS.quantity}>Linear Feet (ft)</Tooltip>,
            key: 'end_time',
            dataIndex: 'count',
            render: (_, item) => {
                const value = item.count * Number(item.data[4]) / 12.0;
                return new NumericModel({ unit: ['ft', 'm'], decimals: 1 }).setMetric(metric).setValue(Number(value)).getValueString();
            },
            sorter: (a: ICompletedItemModel, b: ICompletedItemModel) => a.count * Number(a.data[4]) - b.count * Number(b.data[4]),
        },
        */
        ...extendedKeys.map(key => {
            const nKey = Number(key);
            const itemConfig = completedItemConfig.extended[nKey];
            return {
                title: itemConfig ? <Tooltip title={itemConfig.description || ''}>{`${itemConfig.header}${itemConfig.model?.unit ? ` (${Array.isArray(itemConfig.model.unit) ? itemConfig.model?.unit[metric ? 1 : 0] : itemConfig.model?.unit})` : ''}`}</Tooltip> : '???',
                key: 'end_time',
                dataIndex: `extended[${nKey}]`,
                render: (_: any, item: ICompletedItemModel) => render('extended')(item, nKey),
                sorter: (a: ICompletedItemModel, b: ICompletedItemModel) => Number(a.extended![nKey]) - Number(b.extended![nKey]),
            };
        }),
    ]

    const getCsv = (): Record<string, any>[] => {
        return data.map(item => columns.reduce((a, c) => {
            //@ts-ignore
            let title: string = c.title!.props!.children;
            if (Array.isArray(title)) title = title.join('');
            const value = `"${c.render!(null, item, 0)!.toString()}"`;
            return {
                ...a,
                [title]: value,
            }
        }, {})
        );
    }

    const tableWidth = 150 * columns.length;

    const footer = <CsvDownloader datas={() => getCsv()} filename={'time-tracker-completed-items'} extension='.csv'>
        <Button type='primary' size='small'><DownloadOutlined />Download CSV</Button>
    </CsvDownloader>

    return (
        <div style={{ width: '100%' }}>
            <Table
                dataSource={data}
                showSorterTooltip={false}
                size='small'
                style={{ width: 'auto' }}
                scroll={{ y: innerDim.y - 450, x: tableWidth }}
                pagination={{ 'pageSizeOptions': [10, 25, 50, 100], showSizeChanger: true, defaultPageSize: 50 }}
                columns={columns}
                footer={() => footer}
            />
        </div>
    )
}
