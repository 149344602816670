import React from 'react';
import {Form} from 'antd';

interface IProps {
    label?: React.ReactNode | string;
    name?: string;
    help: string[],
    children?: React.ReactNode
}

export const StandardFormItem = ({
    label, 
    help, 
    children
}: IProps) => {

    return (
        <>
            <Form.Item label={label}>
                {children}
            </Form.Item>
        </>
    )
}
