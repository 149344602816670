import React, {useContext} from 'react';
import {AppContext} from '../../state';
import {ICompletedItemModel, ICompletedItemRenderConfig} from '__core/models';
import {GraphItemView} from '../completed-item/graph-view/graph-item';
import {summarize} from '../completed-item/graph-view/summarize';

interface IProps {
    startTime: number,
    endTime: number,
    items: ICompletedItemModel[];
    timeZone: string;
}

export const CompletedItemGraph = ({startTime, endTime, items, timeZone}: IProps) => {
    const {state} = useContext(AppContext);

    if (!state.config) return null;

    const completedItemConfig = state.config.config.completedItems;

    const tonsTag = Object.entries(completedItemConfig.data).find(([key,value]) => value.header.toLowerCase().includes('weight'));
    const tonsTagRenderConfig : ICompletedItemRenderConfig = {
        header: 'Tons',
        tag:'',
        model: {
            decimals: 1,
            scale: 1/2000,
            unit: ['ton', 'mton'],
        }
    }

    if (!tonsTag) return null;

    const {summary, divBy} = !items ? {summary: [], divBy: 'day'} : summarize({startTime, endTime, data: items || [], timeZone});

    return (
        <GraphItemView 
            renderConfig={tonsTagRenderConfig}
            getDataFunction={item => Number(item.data[Number(tonsTag[0])])}
            data={summary} 
            type='Sum' 
            divBy={divBy}
            metric={state.job?.is_metric}
            mobile={state.isMobile}
        />
    );
}
