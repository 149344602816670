import React, {useContext} from 'react';
import {ISubscriptionModel} from '__core/models';
import {TableColumnProps} from 'antd';
import {AppContext, useFilters} from '../../state';
import {ItemListView} from 'components/lists/item-list-view';
import { SubscriptionViewData } from './item-view';
import {useSorter} from 'hooks';
import {subscriptionDurationFormat} from './utils';

export const SubscriptionListView = () => {

    const {state} = useContext(AppContext);

    const {genericFilters} = useFilters('subscriptions');
    const {genericSorter} = useSorter();

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';

    const smallCols : TableColumnProps<ISubscriptionModel>[] = [
        {
            title: 'Order',
            dataIndex: 'id',
            render: (_,{order_number,length_years,length_days}) => (
                <>
                <div>{order_number}</div>
                <div><i>{subscriptionDurationFormat({length_years, length_days})}</i></div>
                </>
            ),
            ...genericSorter({name: 'Order'}),
            ...genericFilters({name: 'Order'}),
        },
    ]

    const largeCols : TableColumnProps<ISubscriptionModel>[] = [        
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            ...genericSorter({name: 'order_number'}),
            ...genericFilters({name: 'order_number'}),
        },        
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            ...genericSorter({name: 'active'}),
            ...genericFilters({name: 'active'}),
        },
        {
            title: 'Quantity',
            dataIndex: 'part_quantity',
            ...genericSorter({name: 'part_quantity'}),
            ...genericFilters({name: 'part_quantity'}),
        },
        {
            title: 'Duration',
            dataIndex: 'length_years',
            render: (_, {length_years, length_days}) => subscriptionDurationFormat({length_years,length_days}),
            ...genericSorter({name: 'length_years'}),
            ...genericFilters({name: 'length_years'})
        },
    ]

    return (
        <ItemListView
            title='Subscriptions'
            allowEdit={state.userAccount?._is_rbi && state.userAccount?.is_admin}
            itemKey='id'
            loadItemPermission={async () => !!customer_id && !!job_id}
            loadItems={async ({refresh}) => state.api.read.subscription.list({customer_id, job_id, refresh})}
            loadViewItem={async ({item: {id}, refresh}) => state.api.read.subscription.item({customer_id, job_id, subscription_id: id, refresh})}
            ViewData={SubscriptionViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            itemLogId={item => item.id}
            columnEditNav={item => `/admin/subscriptions/edit/${item.id}`}
            columnViewNav={item => `/admin/subscriptions/view/${item.id}`}
            itemNewNav='/admin/subscriptions/new'
        />
    )
}
