import { UnixTime, UUID } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export const userJobAccessTypes = [
    'None',
    'Read Only',
    'Read Write',
] as const;

export type UserJobAccessType = typeof userJobAccessTypes[number];

export interface IUserPreferences {
    darkMode?: boolean;
}

export interface IUserAccountModel {
    id: UUID;
    customer_id: UUID;
    active: boolean;
    name: string;
    email: string;
    is_admin: boolean;
    jobs: Record<UUID, UserJobAccessType>;
    _is_rbi?: boolean;
    preferences: IUserPreferences;
    last_alive: UnixTime;
}

interface INewModelProps {
    customer_id: UUID;
    email: string;
}

const updateKeys: Extract<keyof IUserAccountModel, string>[] = [
    'name',
    'active',
    'is_admin',
    'jobs',
    'preferences',
    'last_alive',
]

export const userAccountModelData: IModelData<IUserAccountModel, INewModelProps> = {
    table: 'user_account',
    selectKeys: ['id', 'customer_id', ...updateKeys],
    insertKeys: [
        'id', // id is inserted because it will come from cognito user pool
        'customer_id',
        'email',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ customer_id, email }: INewModelProps) => ({
        id: '',
        customer_id,
        name: '',
        email,
        jobs: {},
        active: true,
        is_admin: false,
        preferences: {},
        last_alive: 0,
    }),
    validate: ({ partial }) => item => validator<IUserAccountModel>({
        item,
        partial,
        config: {
            customer_id: {
                name: 'Customer Reference',
                type: 'UUID',
            },
            active: {
                type: 'boolean',
            },
            name: {
                string: {
                    minLength: 1,
                }
            },
            email: {
                string: {
                    minLength: 5,
                }
            },
            is_admin: {
                name: 'Administrator',
                type: 'boolean',
            },
            preferences: {
                type: 'object',
            },
            jobs: {
                type: 'object',
            },
        },
    })
}
