import produce from 'immer';
import { IAppState, getNewAppState } from './interface';
import { getApi } from '__core/api';
import { ICompletedItemRenderConfig } from '__core/models';
import { IUserAccountModel, ICustomerModel, IJobModel, IJobConfigModel, IScheduleModel, IUserPreferences } from '__core/models';
import { ITimeIntervalSelect, getDefaultTimeIntervalSelect } from 'components/views/time-interval-select';
import { ICompletedItemFilter } from './completed-item-filters';
import { ICompletedItemCharts } from './completed-item-charts';

interface IActionNone {
    type: 'none';
}

interface IActionSetMobile {
    type: 'set-mobile';
    mobile: boolean;
}

interface IActionSetUser {
    type: 'set-user';
    userAccount: IUserAccountModel;
    userAuth: string;
}

interface IActionUpdateUserPreferences {
    type: 'update-user-preferences';
    preferences: IUserPreferences;
}


interface IActionLogout {
    type: 'logout';
}

interface IActionSetCompany {
    type: 'set-customer';
    customer: ICustomerModel;
}

interface IActionSetJob {
    type: 'set-job';
    job: IJobModel;
    config?: IJobConfigModel;
}

interface IActionSetTimeInterval {
    type: 'set-time-interval';
    timeIntervalSelect: ITimeIntervalSelect;
}

interface IActionSetSchedule {
    type: 'set-schedule',
    schedule: IScheduleModel;
}
interface IActionSetFilter {
    type: 'set-filter',
    group: string;
    item: string;
    filter: string;
}

interface IActionSetCompletedItemFilter {
    type: 'set-completed-item-filter',
    filter: ICompletedItemFilter,
}

interface IActionSetCompletedItemCharts {
    type: 'set-completed-item-charts',
    charts: ICompletedItemCharts,
}

interface IActionChangeJobMetric {
    type: 'change-job-metric',
    metric: boolean,
}


export type AppStateActionTypes =
    | IActionNone
    | IActionSetMobile
    | IActionSetUser
    | IActionUpdateUserPreferences
    | IActionLogout
    | IActionSetCompany
    | IActionSetJob
    | IActionSetTimeInterval
    | IActionSetSchedule
    | IActionSetFilter
    | IActionSetCompletedItemFilter
    | IActionSetCompletedItemCharts
    | IActionChangeJobMetric;

export const appStateReducer = (state: IAppState, action: AppStateActionTypes): IAppState => {
    switch (action.type) {
        case 'none': return state;
        case 'set-mobile': return produce(state, draft => {
            draft.isMobile = action.mobile;
        });
        case 'set-user': return produce(state, draft => {
            draft.userAccount = action.userAccount;
            draft.userAuth = action.userAuth;
            draft.filters = {};
            draft.api = getApi(draft.userAuth);
        });
        case 'update-user-preferences': return produce(state, draft => {
            if (draft.userAccount) draft.userAccount.preferences = action.preferences;
        });
        case 'logout': return getNewAppState();
        case 'set-customer': return produce(state, draft => {
            draft.customer = action.customer;
            draft.job = undefined;
            draft.schedule = undefined;
            draft.config = undefined;
        });
        case 'set-job': return produce(state, draft => {
            draft.job = action.job;
            const findLengthField = (Object.entries(action.config?.config.completedItems.data || {}).find(([_, data]) =>
                data.header === 'Length' && data.model && data.model.unit?.toString() === ['in', 'mm'].toString()
            ));
            let newData: ICompletedItemRenderConfig | undefined = undefined;
            if (findLengthField && action.config) {
                draft.calculateLinearFootage = Number(findLengthField[0]);
                newData = {
                    header: 'Total Length',
                    tag: '',
                    description: 'Calculated Length times Count',
                    model: {
                        unit: ['ft', 'm'],
                        decimals: 1,
                    },
                }
            } else {
                draft.calculateLinearFootage = undefined;
            }
            if (action.config) {
                if (!newData)
                    draft.config = action.config;
                else
                    draft.config = {
                        ...action.config,
                        config: {
                            ...action.config.config,
                            completedItems: {
                                ...action.config.config.completedItems,
                                data: {
                                    ...action.config.config.completedItems.data,
                                    [Number(draft.calculateLinearFootage) + 0.001]: newData,
                                },
                            }
                        }
                    }
            }

            draft.schedule = undefined;
            draft.timeIntervalSelect = getDefaultTimeIntervalSelect(action.job.time_zone);
            draft.completedItemFilter = {};
            draft.completedItemCharts = { tons: { use: true, showSum: true } };
        });
        case 'set-time-interval': return produce(state, draft => {
            draft.timeIntervalSelect = action.timeIntervalSelect;
        });
        case 'set-schedule': return produce(state, draft => {
            draft.schedule = action.schedule;
        });
        case 'set-filter': return produce(state, draft => {
            if (!draft.filters[action.group]) draft.filters[action.group] = {};
            draft.filters[action.group][action.item] = action.filter;
        })
        case 'set-completed-item-filter': return produce(state, draft => {
            draft.completedItemFilter = action.filter;
        })
        case 'set-completed-item-charts': return produce(state, draft => {
            draft.completedItemCharts = action.charts;
        })
        case 'change-job-metric': return produce(state, draft => {
            if (draft.job) draft.job.is_metric = action.metric;
        })
        default: return state;
    }
}
