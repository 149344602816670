import React, { useState, useContext } from 'react';
import { Button } from 'antd';
import { AppContext } from '../../state';
import { useShowMessages } from 'hooks';

export const UtilitiesView = () => {

    const [applySubActive, setApplySubActive] = useState(false);
    const { state } = useContext(AppContext);

    const {
        successNotification,
        errorNotification,
    } = useShowMessages();

    const applyJobSubscriptions = async () => {
        setApplySubActive(true);
        const result = await state.api.write.utils.updateAllSubscriptions();
        showMessages(result.messages);
        await state.api.read.jobOverviewViewModel.list({ refresh: true });
        setApplySubActive(false);
    }

    const showMessages = (messages: Record<string, string[]>) => {
        if (messages._success) successNotification(messages._success[0] || 'Success');
        if (messages._error) errorNotification(messages._error[0] || 'Error');
        Object.entries(messages).forEach(([title, msgArray]) => {
            if (!title.startsWith('_')) {
                msgArray.forEach(msg => errorNotification(msg));
            }
        })
    }

    if (!state.userAccount?._is_rbi || !state.userAccount.is_admin)

        return <>Not Authorized</>

    return (
        <>
            <div>
                <Button style={{ margin: 10, width: 300 }} type='primary' disabled={applySubActive} onClick={applyJobSubscriptions}>Update All Job Subscription Expirations</Button>
            </div>
        </>
    )
}
