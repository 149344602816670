import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';
import { INumericModelPartial } from '@redbudindustries/node-numeric-model';

/**
 * publish to npm package or update RBI HMI if this updates
 */

export type IStateTimeType = 'blank' | 'run' | 'setup' | 'down' | 'standby';
export interface IStateTimeRenderConfig {
    states: Record<number, { text: string, type: IStateTimeType }>;
    types: Record<IStateTimeType, { background: string, foreground: string }>;
}

export interface ICompletedItemRenderConfig {
    header: string,
    tag: string,
    description?: string;
    model?: INumericModelPartial
    /**
     * optional state config. 
     * If present, use data value as key to look up rendering
     */
    states?: Record<string, string>;
    /**
     * option flags config
     * *Only for Integer data values*
     * If present, use the index of the bits that are set to 1 as the key for values to display
     * Show values as comma separate list
     */
    flags?: Record<number, string>;

    /**
     * set to display as string
     */
    asString?: boolean;

}

export interface ICompletedItemRenderConfigMap {
    data: Record<number, ICompletedItemRenderConfig>;
    extended: Record<number, ICompletedItemRenderConfig>;
}

export interface IJobConfigData {
    stateTime: IStateTimeRenderConfig,
    completedItems: ICompletedItemRenderConfigMap;
}
export interface IJobConfigModel {
    job_id?: UUID;
    version: string;
    config: IJobConfigData;
}

/**
 * End Copy Area To RBI HMI
 */

interface INewModelProps {
    job_id: UUID;
}

const updateKeys: Extract<keyof IJobConfigModel, string>[] = [
    'version',
    'config',
]

export const jobConfigModelData: IModelData<IJobConfigModel, INewModelProps> = {
    table: 'job_config',
    selectKeys: updateKeys,
    insertKeys: [
        'job_id',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ job_id }: INewModelProps) => ({
        job_id,
        version: '',
        config: {
            stateTime: {
                states: {},
                types: {
                    blank: { background: "#FFF", foreground: "#000" },
                    run: { background: "#2F2", foreground: "#000" },
                    down: { background: '#f00', foreground: '#fff' },
                    standby: { background: "#FF0", foreground: "#000" },
                    setup: { background: "#00F", foreground: "#FFF" }
                }
            },
            completedItems: {
                data: {},
                extended: {},
            },
        },
    }),
    validate: ({ partial }) => item => validator<IJobConfigModel>({
        item,
        partial,
        config: {
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            version: {
                string: {
                    minLength: 1,
                },
            },
            config: {
                type: 'object',
            },
        },

    })
}
