import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../state';
import { TimeIntervalSelect, DataError } from 'components/views';
import { Typography, Spin } from 'antd';
import { numericFilter, selectFilter, stringFilter } from './filter-view/filter-items';
import { CompletedItemFilterView } from './filter-view';
import { NumericModel } from '@redbudindustries/node-numeric-model';
import { applyShiftSchedule } from './utils/apply-shift-schedule';
import { ICompletedItemModel } from '__core/models';

interface IProps {
    innerView: (props: { data: ICompletedItemModel[] }) => JSX.Element | null;
}

export const CompletedItemGenericView = ({ innerView }: IProps) => {

    const { state, dispatch } = useContext(AppContext);
    const [data, setData] = useState<ICompletedItemModel[] | null>(null);
    const [filteredData, setFilteredData] = useState<ICompletedItemModel[]>([]);
    const [error, setError] = useState(false);

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';
    const timeZone = state.job?.time_zone || 'America/Chicago';
    const config = state.config;
    const schedule = state.schedule;
    const metric = state.job?.is_metric || false;

    useEffect(() => {
        let cancel = false;
        setData(null);
        setError(false);
        (async () => {
            try {

                const newDataBuffer: ICompletedItemModel[][] = [];
                let count = 10000;
                let startTime = state.timeIntervalSelect.startTime;
                let tryCount = 0;
                while (count >= 10000 && tryCount <= 20) {
                    const readData = await state.api.read.completedItem.list({
                        customer_id,
                        job_id,
                        startTime,
                        endTime: state.timeIntervalSelect.endTime,
                        extended: state.timeIntervalSelect.extended,
                        refresh: state.timeIntervalSelect.endTime > Date.now() / 1000,
                    });
                    count = readData.length;
                    tryCount++;
                    newDataBuffer.push(readData);
                    if (readData.length) startTime = readData[readData.length - 1].end_time + 1;
                }
                const newDataBufferFiltered = newDataBuffer.filter(data => data.length);
                newDataBufferFiltered.sort((a, b) => a[0].start_time - b[0].start_time);
                const newData = newDataBufferFiltered.reduce<ICompletedItemModel[]>((a, c) => [...a, ...c], []);
                if (!cancel) {
                    const filteredShiftData = schedule && state.timeIntervalSelect.shiftName
                        ? applyShiftSchedule({
                            startTime: state.timeIntervalSelect.startTime,
                            endTime: state.timeIntervalSelect.endTime,
                            data: newData,
                            schedule,
                            shiftName: state.timeIntervalSelect.shiftName,
                            timeZone,
                        })
                        : newData;
                    const calculatedData = state.calculateLinearFootage
                        ? filteredShiftData.map(d => ({
                            ...d,
                            data: {
                                ...d.data,
                                [Number(state.calculateLinearFootage) + 0.001]: d.count * Number(d.data[state.calculateLinearFootage || 0]) / 12,
                            }
                        }))
                        : filteredShiftData;
                    setData(calculatedData);
                }
            } catch (e: any) {
                setError(true);
            }
        })();
        return () => { cancel = true };
    }, [customer_id, job_id, state.timeIntervalSelect, setData]);

    const requiredDataFilterKeys = Object.values(state.completedItemFilter).filter(({ use, group }) => use && group === 'data').map(({ index }) => index);
    const requiredExtendedFilterKeys = state.timeIntervalSelect.extended ? Object.values(state.completedItemFilter).filter(({ use, group }) => use && group === 'extended').map(({ index }) => index) : [];

    //con
    useEffect(() => {
        if (!state.config) return;
        const newFilteredData = (data || []).filter(item =>
            Object.keys(item.data).filter(key => requiredDataFilterKeys.includes(Number(key))).length === requiredDataFilterKeys.length
            && Object.keys(item.extended || {}).filter(key => requiredExtendedFilterKeys.includes(Number(key))).length === requiredExtendedFilterKeys.length
            && numericFilter(state.completedItemFilter, 'duration', item.end_time - item.start_time)
            && numericFilter(state.completedItemFilter, 'quantity', item.count)
            && Object.keys(item.data).every(key => {
                try {
                    const nKey = Number(key);
                    if (!(nKey in completedItemConfig.data)) return true;
                    const itemConfig = completedItemConfig.data[nKey];
                    if (itemConfig.asString) return stringFilter(state.completedItemFilter, itemConfig.header, item.data![nKey].toString());
                    if (itemConfig.states) return selectFilter(state.completedItemFilter, itemConfig.header, item.data![nKey].toString());
                    const value = Number(item.data![nKey]);
                    const useValue = itemConfig.model ? new NumericModel(itemConfig.model).setValue(value).setMetric(metric).getValueScaled() : value;
                    return numericFilter(state.completedItemFilter, itemConfig.header, useValue)
                } catch (e: any) {
                    console.log(e.message);
                    return false;
                }
            })
            && Object.keys(item.extended || {}).every(key => {
                try {
                    const nKey = Number(key);
                    if (!(nKey in completedItemConfig.extended)) return true;
                    const itemConfig = completedItemConfig.extended[nKey];
                    if (itemConfig.asString) return stringFilter(state.completedItemFilter, itemConfig.header, item.extended![nKey].toString());
                    if (itemConfig.states) return selectFilter(state.completedItemFilter, itemConfig.header, item.extended![nKey].toString())
                    const value = Number(item.extended![nKey]);
                    const useValue = itemConfig.model ? new NumericModel(itemConfig.model).setValue(value).setMetric(metric).getValueScaled() : value;
                    return numericFilter(state.completedItemFilter, itemConfig.header, useValue)
                } catch (e: any) {
                    console.log(e.message);
                    return false;
                }
            }));
        setFilteredData(newFilteredData);
    }, [data, state.completedItemFilter, setFilteredData])

    if (!state.config) return null;

    const completedItemConfig = state.config.config.completedItems;

    return (
        <div
            style={{
                height: '100%',
                overflowY: 'auto',
                background: '#fff',
            }}
        >
            <Typography.Title>Completed Items</Typography.Title>
            <div
                style={{
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 99,
                    backgroundColor: "#f7f7f7",
                    borderRadius: 5,
                }}
            >
                <TimeIntervalSelect
                    data={state.timeIntervalSelect}
                    onChange={timeIntervalSelect => dispatch({ type: 'set-time-interval', timeIntervalSelect })}
                    timeZone={timeZone}
                    shiftList={(schedule?.shifts || []).map(({ name }) => name)}
                    showExtended
                    wrap={state.isMobile} />
            </div>
            {error
                ? <DataError />
                : data && config
                    ? <>
                        <CompletedItemFilterView itemLength={filteredData.length} />
                        {innerView({ data: filteredData })}
                    </>
                    : <Spin tip='Loading...' size='large' />
            }
        </div>
    )
}
