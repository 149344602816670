import { ICompletedItemModel, IScheduleModel } from "__core/models"
import {DateTime} from 'luxon';

interface IProps {
    startTime: number,
    endTime: number,
    data: ICompletedItemModel[],
    schedule: IScheduleModel,
    shiftName: string;
    timeZone: string;
}

export const applyShiftSchedule = ({startTime, endTime, data, schedule, shiftName, timeZone}: IProps) : ICompletedItemModel[] => {
    const shift = schedule.shifts.find(({name}) => name === shiftName);
    if (!shift) return data;

    const filteredIntervalData = data.filter(({end_time}) => {
        const dateTime = DateTime.fromSeconds(end_time).setZone(timeZone);
        const dayIndex = dateTime.weekday - 1;
        const timeIndex = dateTime.hour * 4 + Math.trunc(dateTime.minute / 15);
        return shift.times[dayIndex][timeIndex];
    });

    return filteredIntervalData
}