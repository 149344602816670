import React, { useState } from 'react';
import { Card, Select, Button, Switch } from 'antd';
import {
    LeftOutlined,
    RightOutlined,
    CalendarFilled,
} from '@ant-design/icons';
import Calendar from 'react-calendar';
import { DateTime, DateTimeUnit } from 'luxon';
import { UnixTime } from '../../../__core/utils/types';
import { formatUnixTime, formatDurationSeconds } from '../../../utils/time-format';
import { TimeSelectModal } from './time-select-modal';

import './style.css';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';

export type TimeIntervalSpanType = 'day' | 'week' | 'month' | 'quarter' | 'year';
export type DurationDisplayType = 'time' | 'seconds' | 'minutes';

export interface ITimeIntervalSelect {
    startTime: UnixTime;
    endTime: UnixTime;
    span: TimeIntervalSpanType;
    shiftName?: string;
    hideStandby: boolean;
    hideOutliers: boolean;
    extended: boolean;
    durationDisplay: DurationDisplayType;
    custom: boolean;
}

export const getDefaultTimeIntervalSelect = (timeZone: string): ITimeIntervalSelect => {
    const startTimeLux = DateTime.now().setZone(timeZone);
    const newStartTime = startTimeLux.startOf('day');

    const startTime = newStartTime.toSeconds();
    const endTime = newStartTime.plus({ day: 1 }).toSeconds();
    return {
        startTime,
        endTime,
        span: 'day',
        hideStandby: false,
        hideOutliers: false,
        extended: false,
        durationDisplay: 'time',
        custom: false,
    }
}

interface IProps {
    data: ITimeIntervalSelect;
    onChange: (data: ITimeIntervalSelect) => void;
    timeZone: string;
    shiftList?: string[];
    showExtraFilters?: boolean;
    showExtended?: boolean;
    wrap?: boolean;
}

export const TimeIntervalSelect = ({ data, onChange, timeZone, shiftList, showExtraFilters, showExtended, wrap }: IProps) => {

    const {
        startTime,
        endTime,
        span,
        shiftName,
        hideStandby,
        hideOutliers,
        extended,
        durationDisplay,
        custom,
    } = data;

    const navigate = useNavigate();

    const [calendarVisible, setCalendarVisible] = useState(false);
    const [startTimeShow, setStartTimeShow] = useState(false);
    const [endTimeShow, setEndTimeShow] = useState(false);

    const startTimeLux = DateTime.fromSeconds(startTime).setZone(timeZone);
    const endTimeLux = DateTime.fromSeconds(endTime).setZone(timeZone);

    const partialUpdate = (newData: Partial<ITimeIntervalSelect>) => {
        onChange({
            ...data,
            ...newData,
            custom: false,
        })
    };

    const update = (start: DateTime, span: TimeIntervalSpanType) => {
        const newStartTime = start.setZone(timeZone).startOf(span);
        onChange({
            startTime: newStartTime.toSeconds(),
            endTime: newStartTime.plus({ [span]: 1 }).toSeconds(),
            span,
            shiftName,
            hideOutliers,
            hideStandby,
            extended,
            durationDisplay,
            custom,
        })
    }

    const changeStartTime = (start: DateTime) => update(start, data.span)

    const changeSpan = (span: TimeIntervalSpanType) =>
        update(startTimeLux, span);

    const changeDurationDisplay = (durationDisplay: DurationDisplayType) => partialUpdate({ durationDisplay })

    const setToday = () => update(DateTime.now(), data.span)

    const maxDetail: any = {
        'day': 'month',
        'week': 'month',
        'month': 'year',
        'quarter': 'year',
        'year': 'decade',
    }[span]

    const format = {
        'year': {
            date: 'yyyy',
            span: false,
        },
        'quarter': {
            date: 'yyyy-MM',
            span: true,
        },
        'month': {
            date: 'yyyy-MM',
            span: false,
        },
        'week': {
            date: 'yyyy-MM-dd',
            span: true,
        },
        'day': {
            date: 'yyyy-MM-dd',
            span: false,
        },
    }[span];

    const navSelect = <>
        <Button size='small' type='primary' style={{ margin: 2 }} onClick={() => changeStartTime(startTimeLux.minus({ [span]: 1 }))}><LeftOutlined /></Button>
        <Button size='small' type='primary' style={{ margin: 2 }} onClick={() => setCalendarVisible(true)}><CalendarFilled />{startTimeLux.toFormat(format!.date)}{format!.span ? ` to ${endTimeLux.minus({ second: 1 }).toFormat(format!.date)}` : ''}</Button>
        <Button size='small' type='primary' style={{ margin: 2 }} onClick={() => changeStartTime(startTimeLux.plus({ [span]: 1 }))}><RightOutlined /></Button>
        <Button size='small' type='default' style={{ background: '#0b0', margin: 2, color: '#fff' }} onClick={setToday}><CalendarFilled /> Today </Button>
    </>

    const spanSelect =
        <Select
            size='small'
            style={{
                marginLeft: 5,
                width: 150,
            }}
            value={span}
            onChange={changeSpan}
        >
            <Select.Option value='day'>View Day</Select.Option>
            <Select.Option value='week'>View Week</Select.Option>
            <Select.Option value='month'>View Month</Select.Option>
            <Select.Option value='quarter'>View Quarter</Select.Option>
            <Select.Option value='year'>View Year</Select.Option>
        </Select>

    const durationDisplaySelect =
        <Select
            size='small'
            style={{
                marginLeft: 5,
                width: 150,
            }}
            value={durationDisplay}
            onChange={changeDurationDisplay}
        >
            <Select.Option value='time'>hh:mm:ss</Select.Option>
            <Select.Option value='seconds'>Seconds</Select.Option>
            <Select.Option value='minutes'>Minutes</Select.Option>
        </Select>

    const shiftSelect = shiftList && shiftList.length
        ? <Select
            size='small'
            style={{
                marginLeft: 5,
                width: 150,
            }}
            value={shiftName || ''}
            onChange={shiftName => partialUpdate({ shiftName })}
        >
            <Select.Option value=''>--No Shift Filter--</Select.Option>
            {shiftList.map(shift => <Select.Option key={shift} value={shift}>{shift}</Select.Option>)}
        </Select>
        : <Button type='primary' size='small' style={{ margin: 2 }} onClick={() => navigate('/schedules')}>Schedule Select</Button>


    const stateFilterSelect = showExtraFilters
        ? <>
            <span>
                Hide Standby <Switch size='small' checked={hideStandby} onClick={() => partialUpdate({ hideStandby: !hideStandby })} />
            </span>
            <span style={{ marginLeft: 10 }}>
                Hide Outliers <Switch size='small' checked={hideOutliers} onClick={() => partialUpdate({ hideOutliers: !hideOutliers })} />
            </span>
        </>
        : null

    const itemFilterSelect = showExtended
        ? <>
            <span>
                Extended Data <Switch size='small' checked={extended} onClick={() => partialUpdate({ extended: !extended })} />
            </span>
            <span style={{ marginLeft: 10 }}>
                Duration Format {durationDisplaySelect}
            </span>
        </>
        : null

    const standardDisplay =
        <>
            {wrap
                ? <>
                    <div>
                        {navSelect}
                    </div>
                    <div style={{ margin: 5 }}>
                        {spanSelect}
                        {shiftSelect}
                    </div>
                    <div style={{ margin: 5 }}>
                        {stateFilterSelect}
                        {itemFilterSelect}
                    </div>
                    <div>

                    </div>
                </>
                : <div>
                    <span>
                        {navSelect}
                    </span>
                    <span style={{ margin: 5 }}>
                        {spanSelect}
                        {shiftSelect}
                    </span>
                    <span style={{ margin: 5 }}>
                        {stateFilterSelect}
                        {itemFilterSelect}
                    </span>
                </div>
            }
            {calendarVisible
                ? <div style={{ position: "relative" }}>
                    <div style={{ minWidth: 290, position: "absolute", top: 0 }}>
                        <Calendar
                            className="caltest"
                            showWeekNumbers
                            maxDetail={maxDetail}
                            value={startTimeLux.toJSDate()}
                            onChange={(date: Date) => {
                                setCalendarVisible(false);
                                changeStartTime(DateTime.fromJSDate(date).startOf(span as DateTimeUnit))
                            }} />
                    </div>
                </div>
                : null
            }
        </>

    const customDisplay =
        <div>
            <Button size='small' type='primary' style={{ margin: 2 }} onClick={() => setStartTimeShow(true)} >{formatUnixTime(startTime)}</Button>
            to <Button size='small' type='primary' style={{ margin: 2 }} onClick={() => setEndTimeShow(true)}>{formatUnixTime(endTime)}</Button>
            [{formatDurationSeconds(endTime - startTime)}]
        </div>

    return (
        <Card
            size='small'
            style={{

                position: "sticky",
                top: 0,
                zIndex: 99,
                backgroundColor: "#fff",
                borderRadius: 5,
            }}>
            { /*Custom <Switch size='small' checked={custom} onClick={() => partialUpdate({ custom: !custom })} />*/}
            {
                custom
                    ? customDisplay
                    : standardDisplay
            }
            {
                startTimeShow
                    ? <TimeSelectModal
                        time={startTime}
                        timeZone={timeZone}
                        onSave={time => {
                            partialUpdate({ startTime: time });
                            setStartTimeShow(false);
                        }}
                        onClose={() => setStartTimeShow(false)} />
                    : null
            }
            {
                endTimeShow
                    ? <TimeSelectModal
                        time={endTime}
                        timeZone={timeZone}
                        onSave={time => {
                            partialUpdate({ endTime: time });
                            setEndTimeShow(false);
                        }}
                        onClose={() => setEndTimeShow(false)} />
                    : null
            }
        </Card>
    )
}