import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { timeZoneList } from '../__dep/time-zones';
import { supportedLanguages } from '../utils/languages';
import { IModelData } from './_generic-model-data-interface';

export interface IMessageModel {
    id: UUID;
    message: string;
    filters: string;
}

interface INewModelProps {
}

const updateKeys: Extract<keyof IMessageModel, string>[] = [
    'message',
    'filters',
]

export const messageModelData: IModelData<IMessageModel, INewModelProps> = {
    table: 'message',
    selectKeys: [
        'id',
        ...updateKeys
    ],
    insertKeys: updateKeys,
    updateKeys,
    getNewModel: ({}:  INewModelProps) => ({
        id: '',
        message: '',
        filters: '',
    }),
    validate: ({ partial }) => item => validator<IMessageModel>({
        item,
        partial,
        config: {
            message: {
                type: 'string',
            },
            filters: {
                type: 'string',
            },
        },

    })
}
