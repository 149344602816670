import React from 'react';
import { StandardFormItem } from './standard-form-item';
import {DateTime} from 'luxon';

interface IProps {
    label: React.ReactNode | string;
    help: string[];
    value : number;
}

export const WrappedDateDisplay = ({
    label,
    help,
    value,
}: IProps) => {
    return (
        <StandardFormItem label={label} help={help}>
            {DateTime.fromSeconds(value).toFormat('DD')}
        </StandardFormItem>
    );
}