import React, {useContext} from 'react';
import {Menu, Breadcrumb} from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import {Routes, Route, useNavigate} from 'react-router-dom';
import {AppContext} from '../state';
import {routes, IRoute} from './routes';

interface IAppNaVProps {
    onClose?: () => void,
}

export const AppNav = ({onClose}: IAppNaVProps) => {
    const {state} = useContext(AppContext);
    const navigate = useNavigate();
    const _navigate = (path: string) => {
        navigate(path);
        if (onClose) onClose();
    }
    const getMenu = (currentRoute: string, newRoutes?: Record<string, IRoute>) : ItemType[] | undefined => 
        !newRoutes 
        ?   undefined 
        :   Object.entries(newRoutes)
            .filter(([path, {menuHide}]) => path && !menuHide)
            .reduce<ItemType[]>((acc, [path,{label = '', routes, icon}]) => {
                const newRoute : string = `${currentRoute}${path}`;
                return [
                    ...acc, 
                    {
                        key: label,
                        label,
                        icon,
                        onClick: () => routes ? null : _navigate(newRoute),
                        children: getMenu(newRoute,routes),
                    }
                ];
            }, [])

    return <Menu 
        mode='inline'
        defaultSelectedKeys={['1']}
        selectable={false}
        style={{height: '100%', borderRight: 0, background: '#eeefef'}}
        items={getMenu('', routes(state)['/home'].routes)}
        />
};

export const AppBreadcrumbs = () => {
    const {state} = useContext(AppContext);
    const navigate = useNavigate();
    const getBreadcrumbs = (currentRoute: string, currentLabels: string[], newRoutes?: Record<string,IRoute>) : React.ReactElement[] => {
       return  !newRoutes
        ?   []
        :   Object.entries(newRoutes).reduce<React.ReactElement[]>((acc, [path, {label, routes}]) => {
                const newRoute = `${currentRoute}${path}`;
                const newLabels = label ? [...currentLabels, label] : currentLabels
                return [
                    ...acc,
                    <Route path={newRoute} key={newRoute} element={
                        <>
                            {newLabels.map((lbl, i) => {
                             return <Breadcrumb.Item key={i} onClick={() => navigate(newRoute.split('/').slice(0,i+2).join('/'))}>
                                    <span style={{cursor: 'pointer'}} >{lbl}</span>
                                </Breadcrumb.Item>
                            }
                                )}
                        </>
                    }/>,
                    ...getBreadcrumbs(newRoute, newLabels, routes),
                ];
            },[])
        }
    return (
        <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate('/')}><span style={{cursor: 'pointer'}}>Home</span></Breadcrumb.Item>
                <Routes>
                    {getBreadcrumbs('', [], routes(state)['/home'].routes)}
                </Routes>
        </Breadcrumb>
    )
}

export const AppContent = () => {
    const {state} = useContext(AppContext);
    const routeData = routes(state);
    const navigate = useNavigate();
    const makeRouteMenu = (rootRoute: string, routes?: Record<string, IRoute>) : React.ReactElement => 
        !routes
        ?   <div onLoad={() => navigate('/')}>Not Found</div>
        :   <Menu 
                mode='inline'
                defaultSelectedKeys={['1']}
                selectable={false}
                style={{height: '100%', borderRight: 0}}
                items={Object.entries(routes)
                    .filter(([path, {menuHide}]) => path && !menuHide)
                    .map<ItemType>(([path, {icon,label}]) => ({
                        key: label || '',
                        label,
                        icon,
                        onClick: () => navigate(`${rootRoute}${path}`),
                    }))
                }
                />
    const getRouteContent = (currentRoute: string, newRoutes?: Record<string,IRoute>) : React.ReactElement[] => 
        !newRoutes
        ?   []
        :   Object.entries(newRoutes).reduce<React.ReactElement[]>((acc, [path, {element, routes}]) => {
                const newRoute = `${currentRoute}${path}`;
                return [
                    ...acc,
                    <Route path={newRoute} key={newRoute} element={element || makeRouteMenu(newRoute, routes)}/>,
                    ...getRouteContent(newRoute, routes), 
                ]
            },[])

    const rootElement =
        routeData['/home'].routes
        //@ts-ignore
        ?   routeData['/home'].routes['/dashboard'].element || ''
        : '';

    return (
        <Routes>
            <Route path='/' element={rootElement}/>
            <Route path='/home' element={rootElement}/>
            {getRouteContent('',routeData['/home'].routes)}
        </Routes>
    );
}