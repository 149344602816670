import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../state';
import { ICompletedItemModel } from '__core/models';
import { DateTime } from 'luxon';

interface IProps {
    startTime: number;
    endTime: number;
}

export const useCompletedItems = ({ startTime, endTime }: IProps) => {

    const { state } = useContext(AppContext);
    const [items, setItems] = useState<ICompletedItemModel[] | null>(null);

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';

    useEffect(() => {
        let cancel = false;
        setItems(null);
        (async () => {
            const newModel = await state.api.read.completedItem.list({
                customer_id,
                job_id,
                startTime,
                endTime,
                extended: false,
                refresh: true,
            })
            if (!cancel) setItems(newModel);
        })();
        return () => { cancel = true };
    }, [customer_id, job_id, setItems, startTime, endTime]);

    return {
        items
    };
}