import React, { useContext } from 'react';
import { IJobModel, jobModelData } from '__core/models';
import { supportedLanguages } from '__core/utils/languages';
import { timeZoneList } from '__core/__dep/time-zones';
import { AppContext } from '../../state';
import { useParams } from 'react-router-dom';
import { IFormData, StandardForm, SubHeader, WrappedLabel, WrappedInput, WrappedRadio, WrappedSelect, WrappedDatePicker } from 'components/forms';
import { DateTime } from 'luxon';

interface IProps {
        isNew?: boolean;
}

export const JobItemEdit = ({ isNew }: IProps) => {

        const { job_id = '' } = useParams();

        const { state } = useContext(AppContext);

        const { customer } = state;
        const customer_id = customer?.id || '';

        const formData: IFormData<IJobModel> = ({
                data: {
                        name,
                        alias,
                        city,
                        state,
                        country,
                        time_zone,
                        is_metric,
                        language,
                        subscription_start,
                        subscription_expiration
                },
                update,
        }) =>
                <>
                        <SubHeader label='General' help={[]}>
                                <WrappedInput
                                        label="Name"
                                        help={[]}
                                        value={name}
                                        onChange={name => update({ name })}
                                />
                                <WrappedInput
                                        label="Alias"
                                        help={[]}
                                        value={alias}
                                        onChange={alias => update({ alias })}
                                />
                        </SubHeader>
                        <SubHeader label="Location" help={[]}>
                                <WrappedInput
                                        label="City"
                                        help={[]}
                                        value={city}
                                        onChange={city => update({ city })}
                                />
                                <WrappedInput
                                        label="State"
                                        help={[]}
                                        value={state}
                                        onChange={state => update({ state })}
                                />
                                <WrappedInput
                                        label="Country"
                                        help={[]}
                                        value={country}
                                        onChange={country => update({ country })}
                                />
                                <WrappedSelect
                                        label="Time Zone"
                                        help={[]}
                                        value={time_zone}
                                        options={timeZoneList}
                                        onChange={time_zone => update({ time_zone })}
                                />
                        </SubHeader>
                        <SubHeader label="Locale" help={[]}>
                                <WrappedSelect
                                        label="Language"
                                        help={[]}
                                        value={language}
                                        options={supportedLanguages}
                                        onChange={language => update({ language })}
                                />
                                <WrappedRadio
                                        label="Units"
                                        help={[]}
                                        value={is_metric}
                                        options={{ 'Imperial': false, 'Metric': true }}
                                        onChange={is_metric => update({ is_metric })}
                                />
                        </SubHeader>
                        <SubHeader label="Subscription" help={[]}>
                                <WrappedDatePicker
                                        label="Start"
                                        help={[]}
                                        value={subscription_start}
                                        onChange={subscription_start => update({ subscription_start })}
                                />
                                <WrappedLabel
                                        label="Expiration"
                                        help={[]}
                                        value={DateTime.fromSeconds(subscription_expiration).toFormat('yyyy-MM-dd')}
                                />
                        </SubHeader>
                </>

        const { insert, update, delete: _delete } = state.api.write.job;

        return <StandardForm
                title='Job'
                loadItemPermission={async () => !!customer_id && !!job_id}
                onLoad={isNew ? undefined : async () => state.api.read.job.item({ customer_id, job_id, refresh: true })}
                getNew={() => jobModelData.getNewModel({ customer_id })}
                onCancelNavigate='/jobs'
                onInsert={async item => insert({ customer_id, item })}
                onUpdate={async item => update({ customer_id, job_id, item })}
                // onDelete={async () => _delete({customerId, jobId})}
                formData={formData}
        />
}
