import React from 'react';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label: React.ReactNode | string;
    help: string[];
    value : T;
}

export const WrappedLabel = <T extends string | number | boolean>({
    label,
    help,
    value,
}: IProps<T>) => {
    return (
        <StandardFormItem label={label} help={help}>
            <label>{value}</label>
        </StandardFormItem>
    );
}