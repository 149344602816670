import React from 'react';
import { Switch, Tooltip } from 'antd';
import { ICompletedItemChartItem } from '../../../state';

interface IProps {
    label?: React.ReactNode | string;
    toolTip?: string;
    value?: ICompletedItemChartItem;
    onChange: (value: ICompletedItemChartItem) => void;
    mobile?: boolean;
}

export const GraphSelectionItem = ({
    label,
    toolTip,
    value = { use: false, showSum: false, showItemAverage: false },
    onChange,
    mobile
}: IProps) => {
    const switchControl = <><Switch style={{ marginRight: 5 }} checked={value.use} onChange={() => onChange({ ...value, use: !value.use })} /><Tooltip title={toolTip}>{label}</Tooltip></>
    const input = !value.use ? null : (
        <>
            <Switch style={{ marginLeft: 5 }} checkedChildren='Sum' unCheckedChildren='Sum' checked={value.showSum} onChange={() => onChange({ ...value, showSum: !value.showSum })} />
            <Switch style={{ marginLeft: 5 }} checkedChildren='Item Avg' unCheckedChildren='Item Avg' checked={value.showItemAverage} onChange={() => onChange({ ...value, showItemAverage: !value.showItemAverage })} />
        </>
    );

    return (
        <div>
            {!mobile
                ? <>
                    <span style={{ display: 'inline-block', width: 300 }}>{switchControl}</span>
                    <span>{input}</span>
                </>
                : <h3>
                    {switchControl}
                    <div style={{ marginLeft: 40 }}>
                        {input}
                    </div>
                </h3>
            }
        </div>
    );
}