import React, {useMemo} from 'react';
import {DateTime} from 'luxon';
import {formatDurationSeconds} from 'utils';
import {Tooltip} from 'antd';

export interface IHistogramSeriesItem {
    name: string;
    data: number;
    color: string;
}

interface IProps {
    title: string;
    series: IHistogramSeriesItem[];
    startTime: DateTime;
    endTime: DateTime;
    divs: number,
    mobile: boolean;
}



export const HistogramChart = ({
    series, 
    startTime, 
    endTime, 
    title,
    divs = 4,
    mobile,
}: IProps) => {
    const chart = useMemo(() => {
        const SPAN = endTime.toSeconds() - startTime.toSeconds();
        const MARGIN = 10;
        const WIDTH = 1000;
        const HEIGHT = mobile ? 150 : 75;
        const X_SCALE = (WIDTH - MARGIN*2) / SPAN;
        let X = MARGIN;
        return (
            <>
                <h3><b>{title}</b></h3>
                <div>
                    <div style={{
                        display: 'grid', 
                        marginLeft: 10, 
                        marginRight: 10,
                        marginTop: -10,
                        gridTemplateRows: '1fr', 
                        gridTemplateColumns: new Array(divs +1).fill(0).map((_,i) => `${100 / divs / (i === 0 || i === divs ? 2 : 1)}fr`).join(' ')}}
                        >
                        {new Array(divs+1).fill(0).map((_,i) => 
                            <div style={{gridColumn:i+1, gridRow: 1, textAlign: i === 0 ? 'left' : i === divs ? 'right' : 'center'}}>
                                {startTime.plus({seconds: i *SPAN/divs}).toFormat(SPAN < 100000 ? 'HH:mm' : 'MM-dd')}
                            </div>
                        )}
                    </div>
                    <svg viewBox={`${0} ${0} ${WIDTH} ${HEIGHT}`}>
                        <rect x={0} y={0} height={HEIGHT} width={WIDTH} fill='#fff'/>
                        <rect x={MARGIN} y={MARGIN} height={HEIGHT - MARGIN * 2} width={WIDTH - MARGIN * 2} fill='#444'/>
                        {series.map(item => {
                            const thisX = X;
                            X += item.data*X_SCALE;
                            return (
                                <Tooltip title={`${item.name} - ${formatDurationSeconds(item.data)}`}>
                                    <rect x={thisX} y={MARGIN} width={item.data*X_SCALE} height={HEIGHT - MARGIN*2} fill={item.color} stroke={'#fff'} strokeWidth={1} strokeOpacity={0.1} />
                                </Tooltip>
                            );
                        })}
                    </svg>
                </div>
            </>
        )
    },
    [startTime, endTime]);
    return chart;    
}