import axios from 'axios';
import {
    IAuditLogModel,
    ICustomerModel,
    IJobModel,
    IUserAccountModel,
    ITokenModel,
    ISubscriptionModel,
    ICompletedItemModel,
    IStateTimeModel,
    IJobConfigModel,
    IStressStrainModel,
    IStressStrainListModel,
    IScheduleModel,
    IUserPreferences,
    IMessageModel,
    IUtilRbiReadFunctions,
    IUtilRbiWriteFunctions,
    IUtilCustomerReadFunctions,
    IUtilCustomerWriteFunctions,
} from '../models';
import {
    IJobOverviewViewModel, IJobSubscriptionViewModel,
} from '../view-models';
import { UnixTime, UUID } from '../utils/types';
import { IApiWriteRouteResponse } from './write-response';

//export const BASE_API_ENDPOINT = 'https://929j9dm0k2.execute-api.us-east-2.amazonaws.com/api';
export const BASE_API_ENDPOINT = 'https://time-tracker.redbudindustries.com/api';


interface IReadRequestAuth {
    Authorization: string;
    type: 'single' | 'array';
    cache: IRequestCache;
}

interface IWriteRequestAuth {
    Authorization: string;
    cache: IRequestCache;
}

type IRequestCache = Record<string, any>;

const readRequest = ({ Authorization, type, cache }: IReadRequestAuth) => async (request: string, refresh?: boolean) => {
    if (request in cache && !refresh) return cache[request];
    const { data, status } = await axios.get(request, { headers: { Authorization } });
    if (status !== 200 || !data)
        throw new Error(status.toString());
    else {
        if (type === 'array') {
            if (!Array.isArray(data)) throw new Error('Not an array');
            cache[request] = data;
        }
        else cache[request] = Array.isArray(data) ? data[0] || null : data;
        return cache[request];
    }
}

const deleteRequest = ({ Authorization, cache }: IWriteRequestAuth) => async (request: string): Promise<IApiWriteRouteResponse> => {
    const { data, status } = await axios.delete(request, { headers: { Authorization } });
    if (status !== 200 || !data)
        throw new Error(status.toString());
    else {
        const subRequest = request.substring(0, request.lastIndexOf('/'));
        for (let _request in cache) if (_request.startsWith(subRequest)) delete cache[_request];
        return data;
    }
}

const insertRequest = ({ Authorization, cache }: IWriteRequestAuth) => async (request: string, item: Object): Promise<IApiWriteRouteResponse> => {
    const { data, status } = await axios.post(request, item, { headers: { Authorization } });
    if (status !== 200 || !data)
        throw new Error(status.toString());
    else {
        for (let _request in cache) if (_request.startsWith(request)) delete cache[_request];
        return data;
    }
}

const updateRequest = ({ Authorization, cache }: IWriteRequestAuth) => async (request: string, item: Object): Promise<IApiWriteRouteResponse> => {
    const { data, status } = await axios.put(request, item, { headers: { Authorization } });
    if (status !== 200 || !data)
        throw new Error(status.toString());
    else {
        const subRequest = request.substring(0, request.lastIndexOf('/'));
        for (let _request in cache) if (_request.startsWith(subRequest)) delete cache[_request];
        return data;
    }
}

const writeRequest = ({ Authorization }: IWriteRequestAuth) => async (request: string, item: Object): Promise<IApiWriteRouteResponse> => {
    const { data, status } = await axios.post(request, item, { headers: { Authorization } });
    if (status !== 200 || !data)
        throw new Error(status.toString());
    else {
        return data;
    }
}

const getUtilGeneralArgs = (args: any) => getQueryParams({ util_args: JSON.stringify(args) });
const getUtilRbiReadArgs = (args: IUtilRbiReadFunctions) => getUtilGeneralArgs(args);
const getUtilRbiWriteArgs = (args: IUtilRbiWriteFunctions) => getUtilGeneralArgs(args);
const getUtilCustomerReadArgs = (args: IUtilCustomerReadFunctions) => getUtilGeneralArgs(args);
const getUtilCustomerWriteArgs = (args: IUtilCustomerWriteFunctions) => getUtilGeneralArgs(args);

interface IRefreshData { refresh?: boolean };

interface IAuditLogQuery { access_id?: UUID, item_id?: UUID };
interface IAuditLogId { audit_log_id: UUID };
interface ICustomerId { customer_id: UUID };
interface IUserAccountId { user_account_id: UUID, login?: boolean };
interface IJobId { job_id: UUID };
interface ITokenId { token_id: UUID };
interface IScheduleId { schedule_id: UUID };
interface IStressStrainId { stress_strain_id: UUID };
interface ISubscriptionId { subscription_id: UUID };
interface IMessageId { message_id: UUID };

interface IUserPreferencesProps { preferences: IUserPreferences }

interface ITimeBounds { startTime: UnixTime, endTime: UnixTime };
interface IIncludeExtended { extended: boolean };

interface ICustomerModelItem { item: ICustomerModel };
interface IJobModelItem { item: IJobModel };
interface IUserAccountModelItem { item: IUserAccountModel };
interface ITokenModelItem { item: ITokenModel };
interface IScheduleModelItem { item: IScheduleModel };
interface ISubscriptionModelItem { item: ISubscriptionModel };
interface IMessageModelItem { item: IMessageModel };

export interface IApi {
    clearCache: (prefix?: string) => void;
    read: {
        auditLog: {
            list: (arg: IRefreshData & IAuditLogQuery) => Promise<IAuditLogModel[]>,
            item: (arg: IRefreshData & IAuditLogId) => Promise<IAuditLogModel | null>,
        },
        customer: {
            list: (arg: IRefreshData) => Promise<ICustomerModel[]>,
            item: (arg: IRefreshData & ICustomerId) => Promise<ICustomerModel | null>,
        },
        job: {
            list: (arg: IRefreshData & ICustomerId) => Promise<IJobModel[]>,
            item: (arg: IRefreshData & ICustomerId & IJobId) => Promise<IJobModel | null>,
        },
        userAccount: {
            list: (arg: IRefreshData & ICustomerId) => Promise<IUserAccountModel[]>,
            item: (arg: IRefreshData & ICustomerId & IUserAccountId) => Promise<IUserAccountModel | null>,
        },
        token: {
            list: (arg: IRefreshData & ICustomerId & IJobId) => Promise<ITokenModel[]>,
            item: (arg: IRefreshData & ICustomerId & IJobId & ITokenId) => Promise<ITokenModel | null>,
        },
        stateTime: {
            list: (arg: IRefreshData & ICustomerId & IJobId & ITimeBounds) => Promise<IStateTimeModel[]>
        },
        completedItem: {
            list: (arg: IRefreshData & ICustomerId & IJobId & ITimeBounds & IIncludeExtended) => Promise<ICompletedItemModel[]>
        },
        jobConfig: {
            item: (arg: IRefreshData & ICustomerId & IJobId) => Promise<IJobConfigModel>
        },
        schedule: {
            list: (arg: IRefreshData & ICustomerId & IJobId) => Promise<IScheduleModel[]>,
            item: (arg: IRefreshData & ICustomerId & IJobId & IScheduleId) => Promise<IScheduleModel | null>,
        },
        stressStrain: {
            list: (arg: IRefreshData & ICustomerId & IJobId & ITimeBounds) => Promise<IStressStrainListModel[]>,
            item: (arg: IRefreshData & ICustomerId & IJobId & IStressStrainId) => Promise<IStressStrainModel | null>,
        },
        subscription: {
            list: (arg: IRefreshData & ICustomerId & IJobId) => Promise<ISubscriptionModel[]>,
            item: (arg: IRefreshData & ICustomerId & IJobId & ISubscriptionId) => Promise<ISubscriptionModel | null>,
        },
        message: {
            list: (arg: IRefreshData) => Promise<IMessageModel[]>,
            item: (arg: IRefreshData & IMessageId) => Promise<IMessageModel | null>,
        },
        jobMessages: {
            list: (arg: IJobId) => Promise<string[]>;
        },
        jobSubscriptionViewModels: {
            list: (arg: IRefreshData) => Promise<IJobSubscriptionViewModel[]>;
        },
        jobOverviewViewModel: {
            list: (arg: IRefreshData) => Promise<IJobOverviewViewModel[]>,
        },
    },
    write: {
        customer: {
            delete: (arg: ICustomerId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & ICustomerModelItem) => Promise<IApiWriteRouteResponse>;
        },
        job: {
            delete: (arg: ICustomerId & IJobId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerId & IJobModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & IJobId & IJobModelItem) => Promise<IApiWriteRouteResponse>;
        },
        userAccount: {
            delete: (arg: ICustomerId & IUserAccountId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerId & IUserAccountModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & IUserAccountId & IUserAccountModelItem) => Promise<IApiWriteRouteResponse>;
            updatePreferences: (arg: ICustomerId & IUserAccountId & IUserPreferencesProps) => Promise<IApiWriteRouteResponse>;
            agreeEula: (arg: ICustomerId & IUserAccountId) => Promise<IApiWriteRouteResponse>;
        },
        token: {
            delete: (arg: ICustomerId & IJobId & ITokenId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerId & IJobId & ITokenModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & IJobId & ITokenId & ITokenModelItem) => Promise<IApiWriteRouteResponse>;
        },
        schedule: {
            delete: (arg: ICustomerId & IJobId & IScheduleId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerId & IJobId & IScheduleModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & IJobId & IScheduleId & IScheduleModelItem) => Promise<IApiWriteRouteResponse>;
        },
        subscription: {
            delete: (arg: ICustomerId & IJobId & ISubscriptionId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: ICustomerId & IJobId & ISubscriptionModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: ICustomerId & IJobId & ISubscriptionId & ISubscriptionModelItem) => Promise<IApiWriteRouteResponse>;
        },
        message: {
            delete: (arg: IMessageId) => Promise<IApiWriteRouteResponse>;
            insert: (arg: IMessageModelItem) => Promise<IApiWriteRouteResponse>;
            update: (arg: IMessageId & IMessageModelItem) => Promise<IApiWriteRouteResponse>;
        },
        utils: {
            updateAllSubscriptions: () => Promise<IApiWriteRouteResponse>;
        }
    }
}

const getCustomerRoot = ({ customer_id }: ICustomerId) => `${BASE_API_ENDPOINT}/customers/${customer_id}`;
const getJobRoot = ({ customer_id, job_id }: ICustomerId & IJobId) => `${BASE_API_ENDPOINT}/customers/${customer_id}/jobs/${job_id}`;
const getQueryParams = (obj: object) => Object.entries(obj).filter(([_, v]) => v !== undefined).map(([k, v]) => `${k}=${v}`).join('&')

export const getApi = (Authorization: string = ''): IApi => {
    const cache: IRequestCache = {};
    const authorizedGetArrayRequest = readRequest({ Authorization, type: 'array', cache });
    const authorizedGetSingleRequest = readRequest({ Authorization, type: 'single', cache });
    const authorizedDeleteRequest = deleteRequest({ Authorization, cache });
    const authorizedInsertRequest = insertRequest({ Authorization, cache });
    const authorizedUpdateRequest = updateRequest({ Authorization, cache });
    const authorizedWriteRequest = writeRequest({ Authorization, cache });
    return {
        clearCache: (prefix = '') => {
            for (let request in cache) if (request.startsWith(prefix)) delete cache[request];
        },
        read: {
            auditLog: {
                list: async ({ refresh, access_id = '', item_id = '' }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/audit-log?${getQueryParams({ access_id, item_id })}`, refresh),
                item: async ({ refresh, audit_log_id }) =>
                    authorizedGetSingleRequest(`${BASE_API_ENDPOINT}/audit-log/${audit_log_id}`, refresh),
            },
            customer: {
                list: async ({ refresh }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/customers`, refresh),
                item: async ({ refresh, ...props }) =>
                    authorizedGetSingleRequest(getCustomerRoot(props), refresh),
            },
            job: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getCustomerRoot(props)}/jobs`, refresh),
                item: async ({ refresh, ...props }) =>
                    authorizedGetSingleRequest(getJobRoot(props), refresh),
            },
            userAccount: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getCustomerRoot(props)}/user-accounts`, refresh),
                item: async ({ refresh, user_account_id, login = false, ...props }) =>
                    authorizedGetSingleRequest(`${getCustomerRoot(props)}/user-accounts/${user_account_id}?login=${login}`, refresh),
            },
            token: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/tokens`, refresh),
                item: async ({ refresh, token_id, ...props }) =>
                    authorizedGetSingleRequest(`${getJobRoot(props)}/tokens/${token_id}`, refresh),
            },
            stateTime: {
                list: async ({ refresh, startTime, endTime, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/state-time?${getQueryParams({ startTime, endTime })}`, refresh),
            },
            completedItem: {
                list: async ({ refresh, startTime, endTime, extended, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/completed-items?${getQueryParams({ startTime, endTime, extended })}`, refresh),
            },
            jobConfig: {
                item: async ({ refresh, ...props }) =>
                    authorizedGetSingleRequest(`${getJobRoot(props)}/config`, refresh)
            },
            schedule: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/schedules`, refresh),
                item: async ({ refresh, schedule_id, ...props }) =>
                    authorizedGetSingleRequest(`${getJobRoot(props)}/schedules/${schedule_id}`, refresh),
            },
            stressStrain: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/stress-strain`, refresh),
                item: async ({ refresh, stress_strain_id, ...props }) =>
                    authorizedGetSingleRequest(`${getJobRoot(props)}/stress-strain/${stress_strain_id}`, refresh),
            },
            subscription: {
                list: async ({ refresh, ...props }) =>
                    authorizedGetArrayRequest(`${getJobRoot(props)}/subscriptions`, refresh),
                item: async ({ refresh, subscription_id, ...props }) =>
                    authorizedGetSingleRequest(`${getJobRoot(props)}/subscriptions/${subscription_id}`, refresh),
            },
            message: {
                list: async ({ refresh }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/messages`, refresh),
                item: async ({ refresh, message_id }) =>
                    authorizedGetSingleRequest(`${BASE_API_ENDPOINT}/messages/${message_id}`, refresh),
            },
            jobMessages: {
                list: async ({ job_id }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/utils-customer?${getUtilCustomerReadArgs({ type: 'get-all-messages', job_id })}`),
            },
            jobSubscriptionViewModels: {
                list: async ({ refresh, }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/utils-rbi?${getUtilRbiReadArgs({ type: 'get-all-job-subscriptions' })}`, refresh),
            },
            jobOverviewViewModel: {
                list: async ({ refresh }) =>
                    authorizedGetArrayRequest(`${BASE_API_ENDPOINT}/utils-rbi?${getUtilRbiReadArgs({ type: 'get-all-job-overviews' })}`, refresh),
            },
        },
        write: {
            customer: {
                delete: async props =>
                    authorizedDeleteRequest(getCustomerRoot(props)),
                insert: async ({ item }) =>
                    authorizedInsertRequest(`${BASE_API_ENDPOINT}/customers`, item),
                update: async ({ item, ...props }) =>
                    authorizedUpdateRequest(getCustomerRoot(props), item),
            },
            job: {
                delete: async props =>
                    authorizedDeleteRequest(`${getJobRoot(props)}`),
                insert: async ({ item, ...props }) =>
                    authorizedInsertRequest(`${getCustomerRoot(props)}/jobs`, item),
                update: async ({ item, ...props }) =>
                    authorizedUpdateRequest(`${getJobRoot(props)}`, item),
            },
            userAccount: {
                delete: async ({ user_account_id, ...props }) =>
                    authorizedDeleteRequest(`${getCustomerRoot(props)}/user-accounts/${user_account_id}`),
                insert: async ({ item, ...props }) =>
                    authorizedInsertRequest(`${getCustomerRoot(props)}/user-accounts`, item),
                update: async ({ user_account_id, item, ...props }) =>
                    authorizedUpdateRequest(`${getCustomerRoot(props)}/user-accounts/${user_account_id}`, item),
                updatePreferences: async ({ user_account_id, preferences, ...props }) =>
                    authorizedUpdateRequest(`${getCustomerRoot(props)}/user-accounts/${user_account_id}/preferences`, preferences),
                agreeEula: async ({ user_account_id, ...props }) =>
                    authorizedUpdateRequest(`${getCustomerRoot(props)}/user-accounts/${user_account_id}/eula-agree`, {}),
            },
            token: {
                delete: async ({ token_id, ...props }) =>
                    authorizedDeleteRequest(`${getJobRoot(props)}/tokens/${token_id}`),
                insert: async ({ item, ...props }) =>
                    authorizedInsertRequest(`${getJobRoot(props)}/tokens`, item),
                update: async ({ token_id, item, ...props }) =>
                    authorizedUpdateRequest(`${getJobRoot(props)}/tokens/${token_id}`, item),
            },
            schedule: {
                delete: async ({ schedule_id, ...props }) =>
                    authorizedDeleteRequest(`${getJobRoot(props)}/schedules/${schedule_id}`),
                insert: async ({ item, ...props }) =>
                    authorizedInsertRequest(`${getJobRoot(props)}/schedules`, item),
                update: async ({ schedule_id, item, ...props }) =>
                    authorizedUpdateRequest(`${getJobRoot(props)}/schedules/${schedule_id}`, item),
            },
            subscription: {
                delete: async ({ subscription_id, ...props }) =>
                    authorizedDeleteRequest(`${getJobRoot(props)}/subscriptions/${subscription_id}`),
                insert: async ({ item, ...props }) =>
                    authorizedInsertRequest(`${getJobRoot(props)}/subscriptions`, item),
                update: async ({ subscription_id, item, ...props }) =>
                    authorizedUpdateRequest(`${getJobRoot(props)}/subscriptions/${subscription_id}`, item),
            },
            message: {
                delete: async ({ message_id }) =>
                    authorizedDeleteRequest(`${BASE_API_ENDPOINT}/messages/${message_id}`),
                insert: async ({ item }) =>
                    authorizedInsertRequest(`${BASE_API_ENDPOINT}/messages`, item),
                update: async ({ message_id, item }) =>
                    authorizedUpdateRequest(`${BASE_API_ENDPOINT}/messages/${message_id}`, item),
            },
            utils: {
                updateAllSubscriptions: async () =>
                    authorizedWriteRequest(`${BASE_API_ENDPOINT}/utils-rbi?${getUtilRbiWriteArgs({ type: 'update-all-subscriptions' })}`, {}),
            }
        }
    }
}
