import React from 'react';
import { Input, Switch, Tooltip } from 'antd';
import { ICompletedItemFilterItem } from '../../../../state';

interface IProps {
    label?: React.ReactNode | string;
    toolTip?: string;
    prefix?: React.ReactNode;
    index?: number;
    group: 'standard' | 'data' | 'extended';
    value?: ICompletedItemFilterItem;
    onChange: (value: ICompletedItemFilterItem) => void;
    unit?: string;
    mobile?: boolean;
}

export const WrappedNumericFilterItem = ({
    label,
    toolTip,
    prefix,
    index = 0,
    group,
    value = { use: false, type: 'value', value: 0, max: 100, index, group },
    onChange,
    unit,
    mobile
}: IProps) => {
    const switchControl = <><Switch style={{ marginRight: 5 }} checked={value.use} onChange={() => onChange({ ...value, use: !value.use })} /><Tooltip title={toolTip}>{label}</Tooltip></>
    const input = !value.use ? null : (
        <>
            <Input
                style={{ maxWidth: 125 }}
                prefix={value.type === 'limits' ? '>=' : '='}
                placeholder='value'
                type='number'
                value={value.value}
                onChange={e => onChange({ ...value, value: e.target.value })}
                suffix={unit}
            />
            <Switch checkedChildren='Range' unCheckedChildren='Range' style={{ marginLeft: 5 }} checked={value.type === 'limits'} onChange={() => onChange({ ...value, type: value.type === 'value' ? 'limits' : 'value' })} />
            {value.type === 'limits'
                ? <Input
                    style={{ maxWidth: 125 }}
                    prefix={'<'}
                    placeholder='max'
                    type='number'
                    value={value.max}
                    onChange={e => onChange({ ...value, max: e.target.value })}
                    suffix={unit}
                />
                : null
            }
        </>
    );

    return (
        <div>
            {!mobile
                ? <>
                    <span style={{ display: 'inline-block', width: 300 }}>{switchControl}</span>
                    <span>{input}</span>
                </>
                : <h3>
                    {switchControl}
                    <div style={{ marginLeft: 40 }}>
                        {input}
                    </div>
                </h3>
            }
        </div>
    );
}