import React, {useContext} from 'react';
import {IUserAccountModel} from '__core/models';
import {TableColumnProps} from 'antd';
import {AppContext, useFilters} from '../../state';
import {ItemListView} from 'components/lists/item-list-view';
import {UserAccountViewData} from './item-view';
import {useSorter} from 'hooks';
import {formatTimeSince} from 'utils/time-format';

export const UserListView = () => {

    const {state} = useContext(AppContext);
    
    const {genericFilters} = useFilters('users');
    const {genericSorter} = useSorter();

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';
    
    const smallCols : TableColumnProps<IUserAccountModel>[] = [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (_,{name, email, is_admin, jobs, active, last_alive}) => (
                <>
                    <div>{name}</div>
                    <div><i>{email}</i></div>
                    <div>{active ? 'Active' : 'Inactive'}</div>
                    {   is_admin
                    ?   <div>
                            <b>ADMINISTRATOR</b>
                        </div>
                    :   job_id
                        ?   <div>
                                {jobs[job_id] || 'None'}
                            </div>
                        :   null
                    }
                    <div>Last login {formatTimeSince(last_alive)}</div>
                </>
            ),
            ...genericSorter({name: 'name'}),
            ...genericFilters({name: 'name'}),
        },
    ]

    const largeCols : TableColumnProps<IUserAccountModel>[] = [        
        {
            title: 'Name',
            dataIndex: 'name',
            ...genericSorter({name: 'name'}),
            ...genericFilters({name: 'name'}),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...genericSorter({name: 'email'}),
            ...genericFilters({name: 'email'}),
        },
        {
            title: 'Active',
            dataIndex: 'active',
            render: active => active ? 'Active' : 'Inactive',
            ...genericSorter({name: 'active'}),
            ...genericFilters({name: 'active'}),
        },
        {
            title: job_id ? 'Access Type' : 'Administrator',
            dataIndex: 'is_admin',
            render: (_,{is_admin, jobs}) => 
                is_admin
                ?   <b>ADMINISTRATOR</b>
                :   job_id
                    ?   <>{jobs[job_id] || 'None'}</>
                    :   '',
        },
        {
            title: 'Last Login',
            dataIndex: 'last_alive',
            render: last_alive => formatTimeSince(last_alive),
            ...genericSorter({name: 'last_alive'}),
        },
    ]

    // const getViewData = (data: IUserAccountModel) : IViewData<IUserAccountModel> => UserAccountViewData;

    return (
        <ItemListView
            title='User Accounts'
            allowEdit={state.userAccount?.is_admin}
            itemKey='id'
            loadItemPermission={async () => !!customer_id}
            loadItems={async ({refresh}) => state.api.read.userAccount.list({customer_id, refresh})}
            loadViewItem={async ({item: {id}, refresh}) => state.api.read.userAccount.item({customer_id, user_account_id: id, refresh})}
            ViewData={UserAccountViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `/admin/user-accounts/edit/${item.id}`}
            columnViewNav={item => `/admin/user-accounts/view/${item.id}`}
            itemNewNav='/admin/user-accounts/new'
            itemLogId={({id}) => id}
            accessLogId={({id}) => id}
        />
    )
}
