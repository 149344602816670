import React, { useContext, useState } from 'react';
import { UUID } from '__core/utils/types';
import { IJobOverviewViewModel } from '__core/view-models';
import { IJobConfigModel } from '__core/models';
import { TableColumnProps } from 'antd';
import { AppContext, useFilters } from '../../state';
import { ItemListView } from 'components/lists/item-list-view';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { JobOverviewViewData } from './item-view';
import { useShowMessages, useSorter } from 'hooks';

export const JobOverviewListView = () => {

    const navigate = useNavigate();

    const { state, dispatch, notify } = useContext(AppContext);
    const [itemLoading, setItemLoading] = useState('');
    const { errorNotification } = useShowMessages();
    const { genericFilters } = useFilters('job-overview');
    const { genericSorter } = useSorter();

    const customer_id = state.customer?.id || '';

    const smallCols: TableColumnProps<IJobOverviewViewModel>[] = [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (_, { customer_name, job_name, subscription_expiration }) => (
                <>
                    <div>{customer_name}</div>
                    <div>{job_name}</div>
                    <div>{DateTime.fromSeconds(subscription_expiration).toFormat('yyyy-MM-dd')}</div>
                </>
            ),
            ...genericFilters({ name: 'job_name' })
        },
    ]

    const getTimeUntilExpiration = (subscription_expiration: number) => subscription_expiration - DateTime.now().toUnixInteger();

    const largeCols: TableColumnProps<IJobOverviewViewModel>[] = [
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            ...genericSorter({ name: 'customer_name' }),
            ...genericFilters({ name: 'customer_name' }),
        },
        {
            title: 'Job',
            dataIndex: 'job_name',
            ...genericSorter({ name: 'job_name' }),
            ...genericFilters({ name: 'job_name' }),
        },
        {
            title: 'Subscription Start',
            dataIndex: 'subscription_start',
            render: sub => {
                const subDateTime = DateTime.fromSeconds(sub);
                return <>{subDateTime.toFormat('yyyy-MM-dd')}</>
            },
            ...genericSorter({ name: 'subscription_start' }),
        },
        {
            title: 'Subscription Expiration',
            dataIndex: 'subscription_expiration',
            render: sub => {
                const subDateTime = DateTime.fromSeconds(sub);
                const expireIn = getTimeUntilExpiration(sub);
                return <div style={{ color: expireIn <= 0 ? '#C00' : expireIn <= 0 ? '#770' : '#000' }}>{subDateTime.toFormat('yyyy-MM-dd')}</div>;
            },
            ...genericSorter({ name: 'subscription_expiration' }),
        },
    ]

    const setCustomerJob = async (customer_id: UUID, job_id: UUID) => {
        setItemLoading(job_id);
        try {
            const customerPromise = state.api.read.customer.item({ customer_id })
            const jobPromise = state.api.read.job.item({ customer_id, job_id });
            let config: IJobConfigModel | undefined = undefined;
            try {
                const configPromise = state.api.read.jobConfig.item({ customer_id, job_id });
                config = await configPromise;
            } catch { }
            const customer = await customerPromise;
            const job = await jobPromise;
            if (customer && job) {
                dispatch({ type: 'set-customer', customer });
                dispatch({ type: 'set-job', job, config });
                navigate('/dashboard');
            }
        } catch {
            errorNotification('Unable to load job.');
        }
        setItemLoading('');
    }

    return (
        <ItemListView
            title='Job Overview'
            allowEdit={false}
            itemKey='job_id'
            loadItemPermission={async () => !!state.userAccount?._is_rbi}
            loadItems={async ({ refresh }) => state.api.read.jobOverviewViewModel.list({ refresh })}
            loadViewItem={async ({ item }) => item}
            ViewData={JobOverviewViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            onSelect={item => setCustomerJob(item.customer_id, item.job_id)}
            columnViewNav={() => `/rbi/job-overview`}
            isItemLoading={item => item.job_id === itemLoading}
            itemIsLoading={!!itemLoading}
            itemLogId={({ job_id }) => job_id}
        />
    )
}
