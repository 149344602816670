import React, {useContext, useState, useEffect}  from 'react';
import {IUserAccountModel, IJobModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel, WrappedList} from 'components/forms';
import {AppContext} from '../../state';
import { DateTime } from 'luxon';
import {AuditLogListView} from '../audit-log';

export const UserAccountViewData : IViewData<IUserAccountModel> = ({
    data: {
        id,
        customer_id,
        email,
        name,
        is_admin,
        jobs,
        active,
        last_alive = 0,
    },
}) =>  {
    
    const {state: {api}} = useContext(AppContext);
    const [jobList, setJobList] = useState<IJobModel[]>([]);

    useEffect(() => {
        if (!is_admin)
            (async () => {
            setJobList(await api.read.job.list({customer_id}));
            })()
    },[customer_id, jobs, is_admin, setJobList])
    
    return (
        <>
            <SubHeader label='General' help={[]}>
                <WrappedLabel
                    label="Name"
                    help={[]}
                    value={name}
                    />
                <WrappedLabel
                    label="Email"
                    help={[]}
                    value={email}
                    />
                <WrappedLabel
                    label="Active"
                    help={[]}
                    value={active ? 'Active' : 'Inactive'}
                    />
                <WrappedLabel
                    label="Last Login"
                    help={[]}
                    value={DateTime.fromSeconds(last_alive).toFormat('DD HH:mm:ss')}
                    />
            </SubHeader>
            <SubHeader label='Access' help={[]}>
                <WrappedLabel
                    label="Administrator"
                    help={[]}
                    value={is_admin ? 'Yes' : 'No'}
                    />
                {   is_admin
                ?   null
                :
                    <WrappedList
                        label="Job Access"
                        help={[]}
                        value={jobList.map(job => `${job.name} - ${job.alias} - ${jobs[job.id] || 'None'}`)}
                        />
                }
                
            </SubHeader>
            <AuditLogListView item_id={id}/>
            <AuditLogListView access_id={id}/>
        </>
    )
}