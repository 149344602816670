import React, { useContext, useState } from 'react';
import { UUID } from '__core/utils/types';
import { ICustomerModel } from '__core/models';
import { TableColumnProps } from 'antd';
import { AppContext, useFilters } from '../../state';
import { ItemListView } from 'components/lists/item-list-view';
import { useNavigate } from 'react-router-dom';
import { CustomerViewData } from './item-view';
import { useSorter } from 'hooks';


export const CustomerListView = () => {

    const navigate = useNavigate();

    const { state, dispatch } = useContext(AppContext);
    const [itemLoading, setItemLoading] = useState('');
    const { genericFilters } = useFilters('customer');
    const { genericSorter } = useSorter();

    const smallCols: TableColumnProps<ICustomerModel>[] = [
        {
            title: 'Description',
            key: 'id',
            dataIndex: 'description',
            render: (_, { name, description }) => (
                <>
                    <div>{name}</div>
                    <div><i>{description}</i></div>
                </>
            ),
            ...genericFilters({ name: 'name' }),
        },
    ]

    const largeCols: TableColumnProps<ICustomerModel>[] = [
        {
            title: 'Name',
            key: 'id',
            dataIndex: 'name',
            ...genericSorter({ name: 'name' }),
            ...genericFilters({ name: 'name' }),
        },
        {
            title: 'Description',
            key: 'id',
            dataIndex: 'description',
            ...genericSorter({ name: 'description' }),
            ...genericFilters({ name: 'description' }),
        },
    ]

    const setCustomer = async (customer_id: UUID) => {
        setItemLoading(customer_id);
        try {
            const customer = await state.api.read.customer.item({ customer_id })
            if (customer) {
                dispatch({ type: 'set-customer', customer })
                setItemLoading('');
                navigate('/jobs');
            }
        } catch {
            setItemLoading('');
        }
    }

    return (
        <ItemListView
            title='Customers'
            allowEdit={state.userAccount?._is_rbi && state.userAccount.is_admin}
            itemKey='id'
            loadItemPermission={async () => true}
            loadItems={async ({ refresh }) => state.api.read.customer.list({ refresh })}
            loadViewItem={async ({ item: { id }, refresh }) => state.api.read.customer.item({ customer_id: id, refresh })}
            ViewData={CustomerViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `/rbi/customers/edit/${item.id}`}
            columnViewNav={item => `/rbi/customers/view/${item.id}`}
            itemNewNav='/rbi/customers/new'
            onSelect={item => setCustomer(item.id)}
            isItemLoading={item => item.id === itemLoading}
            itemIsLoading={!!itemLoading}
            itemLogId={({ id }) => id}
        />
    )
}
