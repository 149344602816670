import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { docs } from './docs';
import { version } from 'version';

export const DocsView = () =>
    <div style={{ margin: 20 }}>
        <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} >{docs.replace('/**VERSION**/', version)}</Markdown>
    </div>