import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export interface IJobOrderModel {
    id: UUID;
    job_id: UUID;
    name: string;
    description: string;
    time: UnixTime;
}

interface INewModelProps {
    job_id: UUID;
}

const insertKeys: Extract<keyof IJobOrderModel, string>[] = [
    'job_id',
    'name',
    'description',
    'time',
]

export const jobOrderModelData: IModelData<IJobOrderModel, INewModelProps> = {
    table: 'job_order',
    selectKeys: [
        'id',
        ...insertKeys,
    ],
    insertKeys,
    updateKeys: [],
    getNewModel: ({ job_id }: INewModelProps) => ({
        id: '',
        job_id,
        name: '',
        description: '',
        time: 0,

    }),
    validate: ({ partial }) => item => validator<IJobOrderModel>({
        item,
        partial,
        config: {
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            name: {
                string: {
                    minLength: 1,
                },
            },
            description: {
                string: {
                    minLength: 0,
                },
            },
            time: {
                number: {
                    minValue: 0,
                }
            },
        },

    })
}
