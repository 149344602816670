import React, { useState, useContext } from 'react';
import { Button, Drawer } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { AppContext, ICompletedItemCharts } from '../../../state';
import { BasicForm, SubHeader, IFormData } from 'components/forms';
import { GraphSelectionItem } from './graph-selection-item';
import { HEADER_TOOLTIPS } from '../header-tooltips';

export const GraphSelectionView = () => {

    const { state, dispatch } = useContext(AppContext);
    const [visible, setVisible] = useState(false);


    if (!state.config) return null;

    const config = state.config!.config.completedItems;

    const update = (charts: Partial<ICompletedItemCharts>): void => {
        //@ts-ignore
        dispatch({ type: 'set-completed-item-charts', charts: { ...state.completedItemCharts, ...charts } })
    }

    const formData: IFormData<ICompletedItemCharts> = ({
        data,
        update,
    }) =>
        <>
            <SubHeader label='General' help={[]}></SubHeader>
            <GraphSelectionItem
                label='Tons'
                toolTip='Tons of material ran.'
                value={data.tons}
                onChange={tons => update({ tons })}
                mobile={state.isMobile} />
            <GraphSelectionItem
                label='Duration'
                toolTip={HEADER_TOOLTIPS.duration}
                value={data.duration}
                onChange={duration => update({ duration })}
                mobile={state.isMobile} />
            <GraphSelectionItem
                label='Quantity'
                toolTip={HEADER_TOOLTIPS.duration}
                value={data.quantity}
                onChange={quantity => update({ quantity })}
                mobile={state.isMobile} />
            <SubHeader label='Data' help={[]}>
                {Object.entries(config.data)
                    .filter(([_, item]) => item.model)
                    .map(([key, item]) =>
                        <GraphSelectionItem
                            label={item.header}
                            toolTip={item.description}
                            value={data['data;' + key]}
                            onChange={value => update({ ['data;' + key]: value })}
                            mobile={state.isMobile} />
                    )
                }
            </SubHeader>
            {
                state.timeIntervalSelect.extended
                    ? <SubHeader label='Extended' help={[]}>
                        {Object.entries(config.extended)
                            .filter(([_, item]) => item.model)
                            .map(([key, item]) =>
                                <GraphSelectionItem
                                    label={item.header}
                                    toolTip={item.description}
                                    value={data['extended;' + key]}
                                    onChange={value => update({ ['extended;' + key]: value })}
                                    mobile={state.isMobile} />
                            )
                        }
                    </SubHeader>
                    : null
            }
        </>

    return (
        <>
            <div>
                <Button onClick={() => setVisible(true)}><BarChartOutlined /> Chart Selection</Button>
            </div>
            <Drawer
                visible={visible}
                onClose={() => setVisible(false)}
                width={state.isMobile ? '100%' : 600}
            >
                <h4>Completed Item Charts</h4>
                <BasicForm>
                    {formData({ data: state.completedItemCharts, update })}
                </BasicForm>
            </Drawer>
        </>
    )
}