
import { DateTime } from 'luxon';
import {ICompletedItemModel, IJobConfigModel} from '__core/models';

const DAY_SECONDS = 60*60*24;

type DivType = 'hour' | 'day' | 'week' | 'month';

interface IProps {
    startTime: number,
    endTime: number;
    data: ICompletedItemModel[];
    timeZone: string;
}

export interface ISummary {
    startTime: number;
    endTime: number;
    data: ICompletedItemModel[];
}

export const summarize = ({startTime, endTime, data, timeZone}: IProps) => {


    const duration = endTime - startTime;
    const days = duration / DAY_SECONDS;
    const divBy : DivType =
            days <= 2
        ?   'hour'
        :  days <= 32
        ?   'day'
        :   'month';

    const getNextDivStartTime = (time: number) : number => DateTime.fromSeconds(time).setZone(timeZone).startOf(divBy).plus({[divBy]: 1}).toSeconds();


    const summary : ISummary[] = [];

    let _startTime = startTime;
    let _endTime = getNextDivStartTime(_startTime);
    while(_startTime<endTime){
        const _endTime = getNextDivStartTime(_startTime);
        summary.push({
            startTime: _startTime,
            endTime: _endTime,
            data: data.filter(item => item.end_time >= _startTime && item.end_time < _endTime),
        })
        _startTime = _endTime;
    }

    return {
        summary,
        divBy,
    }
}