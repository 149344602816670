import React, { useState, useContext, useEffect } from 'react';
import 'antd/dist/antd.css';
import { AppContext } from '../state';
import { Layout, Dropdown } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { AppNav, AppBreadcrumbs, AppContent } from './nav-builder';
import useMediaQuery from 'use-media-antd-query';
import { Header, Login } from '../views';
import { useShowMessages } from 'hooks';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

export default () => {
    const { state, dispatch } = useContext(AppContext),
        [menuVisible, setMenuVisible] = useState(false),
        mediaQuery = useMediaQuery(),
        mobile = mediaQuery === 'sm' || mediaQuery === 'xs';

    const { successNotification } = useShowMessages();

    useEffect(() => {
        if (state.customer) successNotification(`Using customer ${state.customer.name}.`)
    }, [state.customer])

    useEffect(() => {
        if (state.job) successNotification(`Using job ${state.job.name}.`)
    }, [state.job?.name])

    useEffect(() => {
        if (state.schedule) successNotification(`Using schedule ${state.schedule.name}.`)
    }, [state.schedule])

    const navigate = useNavigate();

    useEffect(() => {
        if (state.userAccount && !window.location.href.endsWith(state.userAccount.id)) {
            if (state.userAccount._is_rbi) navigate('/rbi/job-overview');
            else navigate('/jobs');
        }
    }, [state.userAccount])

    if (state.isMobile != mobile) dispatch({ type: 'set-mobile', mobile });

    const mobileMenu =
        !state.isMobile
            ? null
            : <Dropdown
                overlay={<AppNav onClose={() => setMenuVisible(false)} />}
                placement='bottomLeft'
                trigger={['click']}
                onVisibleChange={setMenuVisible}
                visible={menuVisible}
            >
                <h2 style={{ float: 'right', marginRight: 10, color: '#fff' }}><MenuOutlined /></h2>
            </Dropdown>

    const standardMenu =
        state.isMobile
            ? null
            : <Sider
                width={200}
                className='site-layout-background'>
                <AppNav />
            </Sider>

    if (!state.userAccount) return <Login />

    return (
        <>
            <Layout style={{ height: '100vh' }}>
                <Header menu={mobileMenu} />
                <Layout style={{ height: '100%', background: '#fff' }}>
                    {standardMenu}
                    <Layout style={{ height: '100%', padding: '0 5px', background: '#fff' }}>
                        <AppBreadcrumbs />
                        <AppContent />
                    </Layout>
                </Layout>
            </Layout>
        </>
    );
}
