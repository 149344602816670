import React from 'react';
import { Switch } from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps {
    label: React.ReactNode | string;
    help: string[];
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

export const WrappedSwitch = ({
    label,
    help,
    value,
    disabled,
    onChange,
}: IProps) => {

    return (
        <StandardFormItem label={label} help={help}>
            <Switch disabled={disabled} checked={value} onChange={value => onChange(value)} />
        </StandardFormItem>
    );
}