import React, { useContext, useState } from 'react';
import { ITokenModel, tokenModelData, TokenAccessType } from '__core/models';
import { AppContext } from '../../state';
import { useParams } from 'react-router-dom';
import { IFormData, StandardForm, SubHeader, WrappedInput, WrappedSelect, WrappedLabel, WrappedSwitch } from 'components/forms';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface IProps {
    isNew?: boolean;
}

export const TokenItemEdit = ({ isNew }: IProps) => {

    const { token_id = '' } = useParams();
    const { state, notify } = useContext(AppContext);

    const { customer, job } = state;
    const customer_id = customer?.id || '';
    const job_id = job?.id || '';

    const accessSelectList: TokenAccessType[] = ['None', 'Read Only', 'Read Write'];
    if (state.userAccount?._is_rbi) accessSelectList.push('Time Tracker');

    const formData: IFormData<ITokenModel> = ({
        data: {
            source_last_digits,
            access_type,
            notes,
            active,
        },
        update,
    }) =>
        <>
            <SubHeader label='General' help={[]}>
                <WrappedLabel
                    label="Token"
                    help={[]}
                    value={source_last_digits ? `****${source_last_digits}` : 'Assigned after creation'}
                />
                <WrappedSwitch
                    label="Active"
                    help={[]}
                    value={active}
                    onChange={active => update({ active })}
                />
                <WrappedSelect
                    label="Access"
                    help={[]}
                    value={access_type}
                    options={accessSelectList}
                    onChange={access_type => update({ access_type })}
                />
                <WrappedInput
                    label="Notes"
                    help={[]}
                    value={notes}
                    onChange={notes => update({ notes })}
                />
            </SubHeader>
        </>

    const { insert, update, delete: _delete } = state.api.write.token;

    return <StandardForm
        title='Token'
        loadItemPermission={async () => !!customer_id && !!job_id && !!token_id}
        onLoad={isNew ? undefined : async () => state.api.read.token.item({ customer_id, job_id, token_id, refresh: true })}
        getNew={() => tokenModelData.getNewModel({ job_id })}
        onCancelNavigate='/admin/tokens'
        onInsert={async item => {
            const result = await insert({ customer_id, job_id, item });
            if (result.success) {
                const token = result.data?.token;
                if (token) {
                    notify(
                        'New Token',
                        <>
                            <p>Copy this token. It will not be shown again.</p>
                            <p>{token}</p>
                            <p>You will also need the below IDs</p>
                            <p>Customer<br />{customer_id}</p>
                            <p>Job<br />{job_id}</p>
                            <Button onClick={() => {
                                const data = `Customer ID: ${customer_id}
Job ID: ${job_id}
Token: ${token}
Type: ${item.access_type}`;
                                navigator.clipboard.writeText(data);
                            }}>
                                <CopyOutlined /> Copy All
                            </Button>
                        </>,
                    );
                }
            }
            delete result.data;
            return result;
        }}
        onUpdate={async item => update({ customer_id, job_id, token_id, item })}
        onDelete={async () => _delete({ customer_id, job_id, token_id })}
        formData={formData}
    />
}
