import React, { useState } from 'react';
import { Button, TimePicker, Modal } from 'antd';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import { UnixTime } from '../../../__core/utils/types';
import { formatUnixTime } from '../../../utils/time-format';

import './style.css';
import 'react-calendar/dist/Calendar.css';

export type TimeIntervalSpanType = 'day' | 'week' | 'month' | 'quarter' | 'year';
export type DurationDisplayType = 'time' | 'seconds' | 'minutes';

export interface ITimeIntervalSelect {
    startTime: UnixTime;
    endTime: UnixTime;
    span: TimeIntervalSpanType;
    shiftName?: string;
    hideStandby: boolean;
    hideOutliers: boolean;
    extended: boolean;
    durationDisplay: DurationDisplayType;
    custom: boolean;
}

export const getDefaultTimeIntervalSelect = (timeZone: string): ITimeIntervalSelect => {
    const startTimeLux = DateTime.now().setZone(timeZone);
    const newStartTime = startTimeLux.startOf('day');

    const startTime = newStartTime.toSeconds();
    const endTime = newStartTime.plus({ day: 1 }).toSeconds();
    return {
        startTime,
        endTime,
        span: 'day',
        hideStandby: false,
        hideOutliers: false,
        extended: false,
        durationDisplay: 'time',
        custom: false,
    }
}

interface IProps {
    time: UnixTime;
    timeZone: string;
    onSave: (time: UnixTime) => void
    onClose: () => void
}

export const TimeSelectModal = ({ time, timeZone, onSave, onClose }: IProps) => {

    const [timeLux, setTimeLux] = useState(DateTime.fromSeconds(time).setZone(timeZone))
    return (
        <Modal
            visible
        >
            <div>
                <Calendar
                    className="caltest"
                    showWeekNumbers
                    value={timeLux.toJSDate()}
                    onChange={(date: Date) => {
                        setTimeLux(timeLux.set({ year: date.getFullYear(), month: date.getMonth(), day: date.getDay() }));
                    }} />
            </div>
            <div>
                <TimePicker onChange={value => value ? setTimeLux(timeLux.set({ hour: value.hour(), minute: value.minute(), second: value.second() })) : null} />
            </div>
            <div>
                {formatUnixTime(timeLux.toSeconds())}
            </div>
            <div>
                <Button onClick={() => onSave(timeLux.toSeconds())}>Save</Button>
                <Button onClick={() => onClose()}>Cancel</Button>
            </div>
        </Modal>
    )
}