import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { timeZoneList } from '../__dep/time-zones';
import { supportedLanguages } from '../utils/languages';
import { IModelData } from './_generic-model-data-interface';

export interface IJobModel {
    id: UUID;
    customer_id: UUID;
    name: string;
    alias: string;
    is_metric: boolean;
    language: string;
    time_zone: string;
    city: string;
    state: string;
    country: string;
    subscription_start: UnixTime;
    subscription_expiration: UnixTime;
    last_alive: UnixTime;
}

interface INewModelProps {
    customer_id: UUID;
}

const updateKeys: Extract<keyof IJobModel, string>[] = [
    'name',
    'alias',
    'city',
    'state',
    'country',
    'language',
    'time_zone',
    'is_metric',
    'subscription_start',
    'subscription_expiration',
    'last_alive',
]

export const jobModelData: IModelData<IJobModel, INewModelProps> = {
    table: 'job',
    selectKeys: [
        'id',
        'customer_id',
        ...updateKeys,
    ],
    insertKeys: [
        'customer_id',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ customer_id }: INewModelProps) => ({
        id: '',
        customer_id,
        name: '',
        alias: '',
        is_metric: false,
        language: 'en-US',
        time_zone: 'America/Chicago',
        city: '',
        state: '',
        country: '',
        subscription_start: 0,
        subscription_expiration: 0,
        last_alive: 0,
    }),
    validate: ({ partial }) => item => validator<IJobModel>({
        item,
        partial,
        config: {
            customer_id: {
                name: 'Customer Reference',
                type: 'UUID',
            },
            name: {
                string: {
                    matches: /M\d{4}/,
                },
            },
            alias: {
                type: 'string',
            },
            city: {
                string: {
                    minLength: 1,
                },
            },
            state: {
                string: {
                    minLength: 1,
                },
            },
            country: {
                string: {
                    minLength: 1,
                },
            },
            language: {
                string: {
                    inList: supportedLanguages,
                },
            },
            time_zone: {
                string: {
                    inList: timeZoneList,
                },
            },
            is_metric: {
                name: 'Units',
                type: 'boolean',
            },
            subscription_start: {
                type: 'number',
            },
            subscription_expiration: {
                type: 'number',
            },
        },

    })
}
