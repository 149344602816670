import React, {useState, useEffect} from 'react';
import {Button, Table, TableColumnsType} from 'antd';
import {formatDurationSeconds} from 'utils';
import './table-chart-style.css';
import CsvDownloader from 'react-csv-downloader';
import {DownloadOutlined} from '@ant-design/icons';

export interface ITableSeriesItem {
    value: number;
    label: string;
    color: string;
    min: number;
    max: number;
    count: number;
    type: number;
    typeString: string;
    summary?: boolean;
    children?: ITableSeriesItem[];
}

interface IProps {
    series: ITableSeriesItem[];
}

const capitalize = (word: string) => word[0].toUpperCase() + word.substring(1);

export const TableChart = ({series}: IProps) => {

    const [innerDim,setInnerDim] = useState({x: window.innerWidth, y: window.innerHeight});
    useEffect(() => {
        window.onresize = () => setInnerDim({x: window.innerWidth, y: window.innerHeight});
        return () => {window.onresize = null};
    },[setInnerDim]);

    let totalDuration = 0;
    const mappedGroups = series.reduce<Record<number, ITableSeriesItem>>((acc, item) => {
        totalDuration += item.value;
        if(!acc[item.type]) 
            acc[item.type] = {
                ...item,
                summary: true,
                label: capitalize(item.typeString),
                children: [item],
            }
        else {        
            acc[item.type].value += item.value;
            acc[item.type].min = Math.min(acc[item.type].min, item.min);
            acc[item.type].max = Math.max(acc[item.type].max, item.max);
            acc[item.type].count += item.count;
            acc[item.type].children!.push(item);
        }
        
        return acc;
    },{})

    const columns : TableColumnsType<ITableSeriesItem> = [
        {
            title: 'Expand',
            dataIndex: 'color',
            render: x => <div/>
        },
        {
            title: 'Type',
            dataIndex: 'color',
            render: (_,item, index) => index === -1 ? item.typeString : <div style={{
                width: 20, 
                height: 20, 
                borderRadius: 10, 
                background: item.color,
                marginLeft: item.summary ? 0 : 20
            }}/>
        },
        {
            title: 'State',
            dataIndex: 'label',
            render: (_,item) => item.label,
        },
        {
            title: 'Percent All',
            dataIndex: 'value',
            render: (_,item) => `${(item.value/totalDuration * 100).toFixed(1)}%`
        },
        {
            title: 'Percent Group',
            dataIndex: 'value',
            render: (_,item) => `${(item.value/mappedGroups[item.type].value * 100).toFixed(1)}%`
        },
        {
            title: 'Duration',
            dataIndex: 'value',
            render: (_,item) => formatDurationSeconds(item.value),
        },
        {
            title: 'Min',
            dataIndex: 'min',
            render: (_,item) => formatDurationSeconds(item.min),
        },
        {
            title: 'Max',
            dataIndex: 'max',
            render: (_,item) => formatDurationSeconds(item.max),
        },
        {
            title: 'Count',
            dataIndex: 'count',
            render: (_,item) => item.count,
        }

    ] 

    const getCsv = () : Record<string,any>[] => {
        return Object.values(mappedGroups).reduce<Record<string,any>[]>((acc,item) => [
            ...acc,
            columns.filter(c => c.title! !== 'Expand')
                .reduce<Record<string,any>>((a,c) => ({
                    ...a,
                    [c.title!.toString()]: c.render!(null, item,-1)!.toString(),
                }),{}),
            ...(item.children || []).map<Record<string,any>>(child => 
                columns.filter(c => c.title! !== 'Expand')
                    .reduce<Record<string,any>>((a,c) => ({
                    ...a,
                    [c.title!.toString()]: c.render!(null, child,-1)!.toString(),
            }),{})),
    ],[]);
    }

    const tableWidth = 100 * columns.length;
    
    const footer =  <CsvDownloader datas={() => getCsv()} filename={`time-tracker-state-table`} extension='.csv'>
                        <Button type='primary' size='small'><DownloadOutlined/>Download CSV</Button>
                    </CsvDownloader>

    return (
        <>
            <Table
                dataSource={Object.values(mappedGroups)}
                pagination={{
                    pageSize: 10000,
                    hideOnSinglePage: true,
                }}
                rowClassName={item => item.summary ? 'summary' : ''}
                size='small'
                style={{width: 'auto'}}
                scroll={{y: 999999, x: tableWidth}}
                footer={() => footer}
                columns={columns}
            />
        </>
    )
}
