import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Modal, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { getApi } from '__core/api';
import {
    WrappedInput,
} from 'components/forms';
import { Button } from 'antd';
import { AppContext } from '../state';
import { useLogin, useShowMessages } from 'hooks';
import { version } from 'version';

interface IProps {

};

export const Login = ({ }: IProps) => {
    const { dispatch } = useContext(AppContext);

    const { errorNotification } = useShowMessages();

    const {
        username,
        setUsername,
        password,
        setPassword,
        login,
        changePassword,
        passwordResetRequired,
        newPassword1,
        setNewPassword1,
        newPassword2,
        setNewPassword2,
        setNewPassword,
        userLoginInfo,
        verificationCode,
        setVerificationCode,
        passwordVerificationCodeActive,
        setPasswordVerificationCodeActive,
        busy,
        setBusy,
    } = useLogin();
    const [newUserModalVisible, setNewUserModalVisible] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        navigate('/');
    }, [])

    useEffect(() => {
        getUserAndCustomerData();
    }, [userLoginInfo]);

    const getUserAndCustomerData = async () => {
        if (!userLoginInfo) return;
        const { customer_id, user_account_id, userAuth } = userLoginInfo;
        try {
            if (customer_id && user_account_id) {
                const tempApi = getApi(userAuth);
                const userAccountPromise = tempApi.read.userAccount.item({ customer_id, user_account_id, login: true });
                const customerPromise = tempApi.read.customer.item({ customer_id });
                const userAccount = await userAccountPromise;
                const customer = await customerPromise;
                if (userAccount && customer) {
                    dispatch({ type: 'set-user', userAccount, userAuth });
                    dispatch({ type: 'set-customer', customer });
                }

            }
        } catch (e: any) {
            errorNotification('Login Failed - Oops, something went wrong!')
        }
        setBusy(false);
    }

    const style = {
        height: '100vh',
        backgroundImage: 'url(/assets/img/background.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }

    const loginRequest = () => {
        if (busy) return;
        setBusy(true);
        login();
    }

    const standardLogin = () =>
        <>
            <img src={'/assets/img/logo.png'} alt="Red Bud Industries" width='100%' />
            <h2 style={{ textAlign: 'center', marginBottom: 10 }}>Time Tracker</h2>

            <Form onKeyDown={e => e.code === 'Enter' ? login() : null}>
                <WrappedInput
                    prefix={<UserOutlined />}
                    name='username'
                    placeholder="Username"
                    help={[]}
                    value={username}
                    onChange={value => setUsername(value)}
                />
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    name='password'
                    placeholder="Password"
                    help={[]}
                    value={password}
                    onChange={value => setPassword(value)}
                />
            </Form>
            <Button
                block
                type='primary'
                onClick={() => loginRequest()}
            >
                Login <LoginOutlined />
            </Button>
            <div style={{ textAlign: 'center', margin: 10 }}>
                <a onClick={() => setNewUserModalVisible(true)}>New User?</a>
            </div>
            <div style={{ textAlign: 'center', margin: 10 }}><a onClick={() => setPasswordVerificationCodeActive(true)}>Change Password</a></div>
        </>

    const resetPasswordWithCode = () =>
        <>
            <div style={{ textAlign: 'center', margin: 10 }}>
                Ask your administrator to reset your password. A verification code will be emailed to your login email to use for resetting your password below.
            </div>
            <div style={{ textAlign: 'center', margin: 10 }}>
                <b>Passwords must be 16 characters long.</b>
            </div>
            <Form onKeyDown={e => e.code === 'Enter' ? changePassword() : null}>
                <WrappedInput
                    prefix={<UserOutlined />}
                    name='username'
                    placeholder="Username"
                    help={[]}
                    value={username}
                    onChange={value => setUsername(value)}
                />
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    name='pw1'
                    placeholder="New Password"
                    help={[]}
                    value={newPassword1}
                    onChange={value => setNewPassword1(value)}
                />
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    name='pw2'
                    placeholder="Confirm Password"
                    help={[]}
                    value={newPassword2}
                    onChange={value => setNewPassword2(value)}
                />
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    placeholder="Verification code"
                    help={[]}
                    value={verificationCode}
                    onChange={value => setVerificationCode(value)}
                />
            </Form>
            <Button
                block
                type='primary'
                onClick={() => changePassword()}
                disabled={newPassword1 !== newPassword2 || newPassword1.length < 16 || busy}
            >
                Change Password
            </Button>
            <div style={{ textAlign: 'center', margin: 10 }}><a onClick={() => setPasswordVerificationCodeActive(false)}>Normal Login</a></div>
        </>

    const passwordResetLogin = () =>
        <>
            <div style={{ textAlign: 'center', margin: 10 }}>
                <b>Passwords must be 16 characters long.</b>
            </div>
            <Form onKeyDown={e => e.code === 'Enter' ? setNewPassword() : null}>
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    name='pw1'
                    placeholder="New Password"
                    help={[]}
                    value={newPassword1}
                    onChange={value => setNewPassword1(value)}
                />
                <WrappedInput
                    prefix={<LockOutlined />}
                    confidential
                    name='pw2'
                    placeholder="Confirm Password"
                    help={[]}
                    value={newPassword2}
                    onChange={value => setNewPassword2(value)}
                />
            </Form>
            <h4 style={{ textAlign: 'center', color: '#f00', marginBottom: 25 }}>Password Reset Required</h4>
            {
                busy
                    ? null
                    : <Button
                        block
                        type='primary'
                        onClick={() => setNewPassword()}
                        disabled={newPassword1 !== newPassword2 || newPassword1.length < 16 || busy}
                    >
                        Change Password <LoginOutlined />
                    </Button>
            }
        </>

    const loggingIn = () =>
        <>
            <h4>Welcome!</h4>
            <div><Spin /> Logging In...</div>
        </>

    return (
        <>
            <Row justify="center" align="middle" style={style}>
                <div style={{ padding: 20, background: 'rgba(210,210,210,0.9)', border: '5px solid white', width: 300 }}>
                    {
                        busy
                            ? loggingIn()
                            : passwordResetRequired
                                ? passwordResetLogin()
                                : passwordVerificationCodeActive
                                    ? resetPasswordWithCode()
                                    : standardLogin()
                    }
                    <Row justify="center" align="middle" style={{ margin: -5, fontSize: '80%' }}>
                        Client {version}
                    </Row>
                </div>
            </Row>
            <Modal
                title='New user request'
                visible={newUserModalVisible} onCancel={() => setNewUserModalVisible(false)}
                footer={null}
            >
                <p>Please contact your administrator to setup a new account.</p>
            </Modal>
        </>
    )
}
