import { UUID } from '../utils/types';
import { IModelData } from './_generic-model-data-interface';
import { validator } from '../utils/validator';

export interface ICustomerModel {
    id: UUID;
    name: string;
    description: string;
    email_domains: string;
    logo: string;
};

const updateKeys: Extract<keyof ICustomerModel, string>[] = [
    'name',
    'description',
    'logo',
    'email_domains',
]

export const customerModelData: IModelData<ICustomerModel> = {
    table: 'customer',
    selectKeys: ['id', ...updateKeys],
    insertKeys: [
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: () => ({
        id: '',
        name: '',
        description: '',
        logo: '',
        email_domains: ''
    }),
    validate: ({ partial }) => item => validator<ICustomerModel>({
        item,
        partial,
        config: {
            name: {
                string: {
                    minLength: 1,
                }
            },
            description: {
                type: 'string',
            },
            email_domains: {
                string: {
                    minLength: 1,
                }
            },
        }
    })
}
