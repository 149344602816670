import React, { useContext, useState } from 'react';
import { AppContext } from '../../state';
import { Row, Col, Spin, Typography, Select } from 'antd';
import { useCompletedItems } from './use-completed-items';
import { DateTime } from 'luxon';
import { CompletedItemGraph } from './completed-item-graph';
import { useStateTime } from './use-state-time';
import { StateTimeSummary } from '../state-time/summary';
import { TopLossesChart } from '../top-losses/chart';
import { useNavigate } from 'react-router-dom';
import { relative } from 'path';

const Loading = () => <Spin tip='Loading...' />

export const Dashboard = () => {
    const { state } = useContext(AppContext);
    const [timeSelect, setTimeSelect] = useState(30);
    const nav = useNavigate();

    const timeZone = state.job?.time_zone || 'America/Chicago';

    const endDateTime = DateTime.now().setZone(timeZone).startOf('minute').startOf('second');
    const startDateTime = DateTime.now().setZone(timeZone).minus({ days: timeSelect - 1 }).startOf('day');

    const startTime = startDateTime.toSeconds();
    const endTime = endDateTime.toSeconds();


    const { items } = useCompletedItems({ startTime, endTime });
    const { summary } = useStateTime({ startTime, endTime });

    const options = new Array(29).fill(0).map((_, i) => i + 3);

    const timeSelector =
        <Select style={{ position: 'relative', top: -7 }} value={timeSelect} onChange={setTimeSelect}>
            {options.map(option => <Select.Option key={option} value={option}><b>{option}</b></Select.Option>)}
        </Select>

    return (
        <div style={{ margin: 20 }}>
            <Typography.Title>Dashboard - Last {timeSelector} Days</Typography.Title>
            {state.job
                ? state.config
                    ? <Row>
                        <Col span={24}>
                            {
                                items
                                    ? <>
                                        <Typography.Title level={3}>Tons Ran</Typography.Title>
                                        <CompletedItemGraph startTime={startTime} endTime={endTime} items={items} timeZone={timeZone} />
                                    </>
                                    : <Loading />
                            }
                        </Col>
                        <Col span={state.isMobile ? 24 : 12}>
                            {
                                summary && state.config
                                    ? <>

                                        <Typography.Title level={3}>Time Tracker</Typography.Title>
                                        <StateTimeSummary summary={summary.total} jobConfig={state.config} hideTable mobile={state.isMobile} />
                                    </>
                                    : <Loading />
                            }
                        </Col>
                        <Col span={state.isMobile ? 24 : 12}>
                            {
                                summary && state.config
                                    ? <>
                                        <Typography.Title level={3}>Top Losses</Typography.Title>
                                        <TopLossesChart summary={summary} jobConfig={state.config} timeZone={timeZone} mobile={state.isMobile} />
                                    </>
                                    : <Loading />
                            }
                        </Col>
                    </Row>
                    : <a onClick={() => nav('/lines')}>No line config file found</a>
                : <a onClick={() => nav('/lines')}>Select a line to view data</a>}
        </div>
    );
}
