import React, {useState} from 'react';
import {IJobConfigModel} from '__core/models';
import {Slider} from 'antd';
import {DateTime} from 'luxon';
import {ITimeIntervalSelect} from 'components/views';
import {HistogramChart, IHistogramSeriesItem} from './histogram-chart';
import {formatDurationSeconds} from 'utils';
import {IStateTimeHistogramModel} from './utils/get-histogram';

interface IProps {
    histogram?: IStateTimeHistogramModel | null;
    jobConfig?: IJobConfigModel;
    timeSelect: ITimeIntervalSelect;
    timeZone: string;
    mobile: boolean;
}

const DEFAULT_ITEM_STATES : Record<number,string> = {
    [-1]: 'No Shift',
    [-2]: 'Outlier',
    [-5]: 'Future',
}

export const StateTimeHistogram = ({histogram, jobConfig, timeSelect, timeZone, mobile}: IProps) => {

    const [sliderValue, setSliderValue] = useState(0);

    if (!histogram || !jobConfig) return null;

    if (timeSelect.span !== 'day' && timeSelect.span !== 'week') return null;

    const {config} = jobConfig;

    const sliderScale = 3600;

    const sliderStartTime = (sliderValue - 0.5) * sliderScale;
    const sliderEndTime = (sliderValue + 0.5) * sliderScale;
    const sliderSpan = timeSelect.span === 'day' ? 24 : 24 * 7;
    const sliderMin = 0.5;
    const sliderMax = sliderSpan - 0.5;

 
    const mainSeries : IHistogramSeriesItem[] = [];
    const subSeries : IHistogramSeriesItem[] = [];

    const startTime = DateTime.fromSeconds(histogram.startTime);
    const endTime = DateTime.fromSeconds(histogram.endTime);
    startTime.setZone(timeZone);
    endTime.setZone(timeZone);

    let relativeStartTime = 0;
    let relativeEndTime = 0;

    histogram.data.forEach(item => {
        relativeStartTime = relativeEndTime;
        relativeEndTime += item.duration;
        const state =   config.stateTime.states[item.state] 
                    ||  {   
                            text: `${DEFAULT_ITEM_STATES[item.state] || item.state}`, 
                            type: 'blank'
                        };
        const name = `${state.text} - ${startTime.plus({seconds: relativeStartTime}).toFormat(timeSelect.span === 'day' ? 'HH:mm:ss' : 'MM-dd HH:mm:ss')}`;
        const color = config.stateTime.types[state.type].background;

        mainSeries.push({
            name,
            data: item.duration,
            color: item.state >= 0 ? color : '#444',
        });

        if (relativeStartTime < sliderEndTime && relativeEndTime > sliderStartTime){
            const duration = 
            relativeStartTime < sliderStartTime 
                ?   item.duration - (sliderStartTime - relativeStartTime)
                :   relativeEndTime > sliderEndTime
                ?   item.duration - (relativeEndTime - sliderEndTime)
                :   item.duration;
            subSeries.push({
                name,
                data: duration,
                color: item.state >= 0 ? color : '#444',
            })
        }
    })

    return (
        <div>
            <HistogramChart
                title={`Series - ${startTime.toFormat('DDDD')}`}
                series={mainSeries}
                startTime={startTime}
                endTime={endTime}
                divs={timeSelect.span === 'week' ? 7 : 4}
                mobile={mobile}
                />
            <Slider 
                style={{
                    marginLeft: 25,
                    marginRight: 25,
                }}
                value={sliderValue} 
                onChange={setSliderValue} 
                included={false}
                min={sliderMin} 
                max={sliderMax} 
                step={0.1}
                tipFormatter={x => formatDurationSeconds(((x || 0)) * sliderScale)}
                />
            <HistogramChart
                title={`Zoomed Series - ${startTime.plus({seconds: sliderStartTime}).toFormat('DDDD hh:mm')}`}
                series={subSeries}
                startTime={startTime.plus({seconds: sliderStartTime})}
                endTime={startTime.plus({seconds: sliderEndTime})}
                divs={4}
                mobile={mobile}
                />
        </div>
    )
}
