import React from 'react';
import {ITokenModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel} from 'components/forms';
import {DateTime} from 'luxon';

export const TokenViewData : IViewData<ITokenModel> = ({
    data: {
            source_last_digits,
            access_type,
            notes,
            active,
            last_alive = 0,
        },
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedLabel
                label="Token"
                help={[]}
                value={`****${source_last_digits}`}
                />
            <WrappedLabel
                label="Active"
                help={[]}
                value={active ? 'Active' : 'Inactive'}
                />
            <WrappedLabel
                label="Last Access"
                help={[]}
                value={DateTime.fromSeconds(last_alive).toFormat('DD HH:mm:ss')}
                />
            <WrappedLabel
                label="Access_Type"
                help={[]}
                value={access_type}
                />
            <WrappedLabel
                label="Notes"
                help={[]}
                value={notes}
                />
        </SubHeader>
    </>
