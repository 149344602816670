import React, { useContext } from 'react';
import { AppContext } from './';
import { Input } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

interface IFilter {
    name: string;
    value: string;
}

export const useFilters = <T extends { [key: string]: any }>(group: string) => {
    const { state, dispatch } = useContext(AppContext);
    const filters = state.filters[group] || {};

    const filteredValue = ({ name }: Pick<IFilter, 'name'>) => filters[name] ? [filters[name]] : undefined;
    const onFilter = ({ name, value }: IFilter) => {
        return value.toLowerCase().indexOf((filters[name] || '').toLowerCase()) >= 0
    };
    const filterDropdown = ({ name }: Pick<IFilter, 'name'>) =>
        <>
            <Input value={filters[name]} onChange={e => dispatch({ type: 'set-filter', group, item: name, filter: e.target.value })} />
            <CloseCircleOutlined style={{ color: 'gray' }} onClick={() => dispatch({ type: 'set-filter', group, item: name, filter: '' })} />
        </>;

    return {
        filteredValue,
        onFilter,
        filterDropdown,
        genericFilters: ({ name }: Pick<IFilter, 'name'>) => ({
            filteredValue: filteredValue({ name }),
            onFilter: (_: any, item: T) => onFilter({ name, value: item[name] }),
            filterDropdown: filterDropdown({ name }),
        })
    }
}