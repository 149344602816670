import React from 'react';
import {Form} from 'antd';

interface IProps {
    children?: React.ReactNode;
}

export const BasicForm = ({
    children,
}: IProps) => 
    <Form 
        labelCol={{span: 2}}
        wrapperCol={{span: 6}}
        labelWrap
    >
        {children}
    </Form>
