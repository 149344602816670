import React, { useReducer } from 'react';
import 'antd/dist/antd.css';
import { appStateReducer, getNewAppState, AppContext, IAppContext } from '../state';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './layout';
import { notification } from 'antd'
import { useMaintenance } from './use-maintenance';
//import { Docs } from '../views/_docs';

export default () => {
    const [state, dispatch] = useReducer(appStateReducer, getNewAppState());
    const [notify, notificationPlaceholder] = notification.useNotification();
    const { maintenanceDisplay } = useMaintenance();

    const appContext: IAppContext = {
        state,
        dispatch,
        notify: (message, description) => {
            notify.open({
                message,
                key: `${Date.now()}`,
                description,
                placement: 'top',
                type: 'info',
                duration: 0,
            });
        }
    };

    //return <Docs />

    return (
        <>
            {notificationPlaceholder}
            {
                maintenanceDisplay
                    ? maintenanceDisplay
                    : (
                        <AppContext.Provider value={appContext}>
                            <BrowserRouter>
                                <AppLayout />
                            </BrowserRouter>
                        </AppContext.Provider>
                    )
            }
        </>
    );
}
