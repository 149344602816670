import React, { useContext } from 'react';
import { IMessageModel, messageModelData } from '__core/models';
import { AppContext } from '../../state';
import { useParams } from 'react-router-dom';
import { IFormData, StandardForm, SubHeader, WrappedTextBox, WrappedInput } from 'components/forms';
import { Button } from 'antd';
import ReactMarkdown from 'react-markdown';

interface IProps {
    isNew?: boolean;
}

export const MessageItemEdit = ({ isNew }: IProps) => {

    const { message_id = '' } = useParams();
    const { state, notify } = useContext(AppContext);

    const { userAccount } = state;

    const formData: IFormData<IMessageModel> = ({
        data: {
            message,
            filters,
        },
        update,
    }) =>
        <>
            <SubHeader label='General' help={[]}>
                <WrappedTextBox
                    label="Message"
                    help={[]}
                    value={message}
                    onChange={(message: string) => update({ message })}
                />
                <WrappedInput
                    label="Filters"
                    help={[]}
                    value={filters}
                    onChange={filters => update({ filters })}
                />
                <Button type='primary' style={{ width: 200 }} onClick={() => notify('NOTICE', <ReactMarkdown>{message}</ReactMarkdown>)}>Test Message</Button>
            </SubHeader>
        </>

    const { insert, update, delete: _delete } = state.api.write.message;

    return <StandardForm
        title='Messages'
        loadItemPermission={async () => !!userAccount?._is_rbi && userAccount.is_admin}
        onLoad={isNew ? undefined : async () => state.api.read.message.item({ message_id, refresh: true })}
        getNew={() => messageModelData.getNewModel({})}
        onCancelNavigate='/rbi/messages'
        onInsert={async item => insert({ item })}
        onUpdate={async item => update({ message_id, item })}
        onDelete={async () => _delete({ message_id })}
        formData={formData}
    />
}
