import React from 'react';
import {IJobSubscriptionViewModel} from '__core/view-models';
import {IViewData, SubHeader, WrappedLabel} from 'components/forms';
import {subscriptionDurationFormat} from '../subscription/utils';

export const JobSubscriptionViewData : IViewData<IJobSubscriptionViewModel> = ({
    data: {
        customer_name,
        job_name,
        order_number,
        part_number,
        part_quantity,
        length_days,
        length_years,
    },
}) => 
<>
<SubHeader label='General' help={[]}>
    <WrappedLabel
        label="Customer"
        help={[]}
        value={customer_name}
        />
    <WrappedLabel
        label="Job"
        help={[]}
        value={job_name}
        />
    <WrappedLabel
        label="Order Number"
        help={[]}
        value={order_number}
        />
    <WrappedLabel
        label="Part Number"
        help={[]}
        value={part_number}
        />
    <WrappedLabel
        label="Part Quantity"
        help={[]}
        value={part_quantity}
        />
    <WrappedLabel
        label="Duration"
        help={[]}
        value={subscriptionDurationFormat({length_years,length_days})}
        />
    </SubHeader>    
</>