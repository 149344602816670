import React, { useContext } from 'react';
import 'antd/dist/antd.css';
import { AppContext } from '../state';
import { Layout } from 'antd';

interface IProps {
    menu: JSX.Element | null;
}

export const Header = ({ menu }: IProps) => {
    const { state } = useContext(AppContext);

    const { customer, job, config } = state;

    const customerName = customer ? customer.name : 'No Customer Selected';
    const version = config ? ` v${config.version}` : 'No Version Loaded';

    const infoLayout =
        !job
            ? <div>{customerName} - No Job Selected</div>
            : state.isMobile
                ? <>
                    {customerName} - {job.name} {version}
                </>
                : <>
                    {customerName} - {job.name} {job.alias} {version}
                </>

    const marginLeft = state.isMobile ? 0 : 40;

    return (
        <Layout.Header style={{
            height: 75,
            backgroundImage: 'url(/assets/img/logo_only.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            backgroundSize: '75px 75px',
            backgroundPositionX: 5,
            backgroundBlendMode: 'luminosity'
        }}>
            {menu}
            <div style={{ color: '#fff', marginLeft, marginBottom: -30, marginTop: -10, fontSize: state.isMobile ? '150%' : '200%' }}><b>RBI Time Tracker</b></div>
            {
                !state.userAccount
                    ? null
                    : <div style={{ color: '#fff', marginLeft }}><i>{infoLayout}</i></div>
            }
        </Layout.Header>
    );
}
