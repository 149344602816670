import React from 'react';

interface IProps {
    colors: string[],
}

export const BlockDisplay = ({
    colors,
}: IProps) => {
    
    const height = `${100 / (colors.length || 1)}%`;

    return (
        <div style={{height: '100%', width: '100%'}}>
            {colors.map((color,index) =>
                <div
                key={index} 
                style={{
                    width: '100%',
                    height: height,
                    backgroundColor: color 
                }}/>
            )}
        </div>
    )
}