import React from 'react';
import {IJobModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel, WrappedDateDisplay} from 'components/forms';
import {DateTime} from 'luxon';

export const JobViewData : IViewData<IJobModel> = ({
    data: {
        name,  
        alias,
        city,
        state,
        country,
        time_zone,
        is_metric,
        language,
        subscription_expiration,
        last_alive = 0,
    },
}) => 
<>
<SubHeader label='General' help={[]}>
    <WrappedLabel
        label="Name"
        help={[]}
        value={name}
        />
    <WrappedLabel
        label="Alias"
        help={[]}
        value={alias}
        />
    </SubHeader>
    <SubHeader label="Location" help={[]}>
    <WrappedLabel
        label="City"
        help={[]}
        value={city}
        />
    <WrappedLabel
        label="State"
        help={[]}
        value={state}
        />
    <WrappedLabel
        label="Country"
        help={[]}
        value={country}
        />
    <WrappedLabel
        label="Time Zone"
        help={[]}
        value={time_zone}
        />
    </SubHeader>
    <SubHeader label="Locale" help={[]}>
    <WrappedLabel
        label="Language"
        help={[]}
        value={language}
        />
    <WrappedLabel
        label="Units"
        help={[]}
        value={is_metric ? 'Metric': 'Imperial'}
        />
    </SubHeader>
    <SubHeader label="Subscription" help={[]}>
    <WrappedDateDisplay
        label="Expiration"
        help={[]}
        value={subscription_expiration}
        />
    <WrappedLabel
        label="Last Server Ping"
        help={[]}
        value={DateTime.fromSeconds(last_alive).toFormat('DD HH:mm:ss')}
        />
    </SubHeader>
</>