import React from 'react';
import {notification} from 'antd';

export const useShowMessages = () => {
    
    const successNotification = (message: string) => notification.success({message})
    const errorNotification = (message: string) => notification.error({message})

    return {
        successNotification,
        errorNotification,
    }
}