import React, { useContext, useEffect, useState } from 'react';
import { IUserAccountModel } from '__core/models';
import { AppContext } from '../../state';
import { useParams } from 'react-router-dom';
import { IFormData, StandardForm, SubHeader, WrappedInput, WrappedSwitch, WrappedLabel, WrappedSelect } from 'components/forms';
import { userAccountModelData, IJobModel, userJobAccessTypes } from '__core/models';

interface IProps {
    isNew?: boolean;
}

export const UserItemEdit = ({ isNew }: IProps) => {

    const { user_account_id = '' } = useParams();
    const { state } = useContext(AppContext);

    const [jobList, setJobList] = useState<IJobModel[]>([]);


    const { customer, api } = state;
    const customer_id = customer?.id || '';

    useEffect(() => {
        if (customer_id)
            (async () => {
                setJobList(await api.read.job.list({ customer_id }));
            })()
    }, [customer_id, setJobList])

    const formData: IFormData<IUserAccountModel & { password?: string }> = ({
        data: {
            email,
            name,
            active,
            is_admin,
            password,
            jobs,
            last_alive,
        },
        update,
    }) => {
        return (
            <>
                <SubHeader label='General' help={[]}>
                    {
                        isNew
                            ? <WrappedInput
                                label="Email"
                                help={[]}
                                value={email}
                                onChange={email => update({ email })}
                            />
                            : <WrappedLabel
                                label="Email"
                                help={[]}
                                value={email}
                            />
                    }
                    <WrappedInput
                        label="Name"
                        help={[]}
                        value={name}
                        onChange={name => update({ name })}
                    />
                    <WrappedSwitch
                        label="Active"
                        help={[]}
                        value={active}
                        onChange={active => update({ active })}
                    />
                    {
                        isNew
                            ? null
                            : <>
                                <WrappedSwitch
                                    label="Reset Password"
                                    help={[]}
                                    value={password === 'reset'}
                                    disabled={!(last_alive > 0)}
                                    onChange={value => update({ password: value ? 'reset' : undefined })}
                                />
                                {
                                    !(last_alive > 0)
                                        ? <div style={{ margin: 10, color: 'red' }}>User must log in at least one time before password can be reset.</div>
                                        : null
                                }
                            </>
                    }
                </SubHeader>
                <SubHeader label='Access' help={[]}>
                    <WrappedSwitch
                        label="Administrator"
                        help={[]}
                        value={is_admin}
                        onChange={is_admin => update({ is_admin })}
                    />
                    {is_admin
                        ? null
                        : jobList.map(job =>
                            <WrappedSelect
                                label={job.name}
                                help={[]}
                                value={jobs[job.id] || 'None'}
                                options={[...userJobAccessTypes]}
                                onChange={value => update({ jobs: { ...jobs, [job.id]: value } })}
                            />)
                    }
                </SubHeader>
            </>
        )
    }

    const { insert, update, delete: _delete } = state.api.write.userAccount;

    return <>
        <StandardForm
            title='User Account'
            loadItemPermission={async () => !!customer_id && !!user_account_id}
            onLoad={isNew ? undefined : async () => state.api.read.userAccount.item({ customer_id, user_account_id, refresh: true })}
            getNew={() => userAccountModelData.getNewModel({ customer_id, email: '' })}
            onCancelNavigate='/admin/user-accounts'
            onInsert={async item => insert({ customer_id, item })}
            onUpdate={async item => update({ customer_id, user_account_id, item })}
            onDelete={async () => _delete({ customer_id, user_account_id })}
            formData={formData}
        />
    </>
}
