import React, {useState, useEffect} from 'react';

export const useWindowDims = () => {

    const [innerDim,setInnerDim] = useState({x: window.innerWidth, y: window.innerHeight});
    useEffect(() => {
        window.onresize = () => setInnerDim({x: window.innerWidth, y: window.innerHeight});
        return () => {window.onresize = null};
    },[setInnerDim]);
    return innerDim;
}
