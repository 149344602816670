import React, {useContext} from 'react';
import {IScheduleModel, scheduleModelData, IShiftData} from '__core/models';
import {AppContext} from '../../state';
import {useParams} from 'react-router-dom';
import {IFormData, StandardForm, SubHeader, WrappedInput, WrappedMultiInput, ColoredWeekSchedule} from 'components/forms';

interface IProps {
    isNew?: boolean;
}

const getNewShiftTimes = () : boolean[][] => new Array(7).fill(new Array(24*4).fill(false));

export const ScheduleItemEdit = ({isNew}: IProps) => {

    const {schedule_id = ''} = useParams();
    const {state} = useContext(AppContext);

    const {customer, job} = state;
    const customer_id = customer?.id || '';
    const job_id = job?.id || '';

    const formData : IFormData<IScheduleModel> = ({
        data: {
            name, 
            shifts
        },
        update,
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedInput
                label="Name"
                help={[]}
                value={name}
                onChange={name => update({name})}
                />
            <WrappedMultiInput
                label='Shifts'
                help={[]}
                value={shifts.map(({name}) => name)}
                onChange={newShifts => {
                    if (newShifts.length > 9) return;
                    if (newShifts.length < shifts.length) update({shifts: shifts.filter(({name}) => newShifts.includes(name))});
                    else {
                        const currentShiftNames = shifts.map(({name}) => name);
                        const newShiftName = newShifts.filter(name => !currentShiftNames.includes(name))
                        if (newShiftName.length)
                            update({shifts: [...shifts, {name: newShiftName[0], times: getNewShiftTimes()}]})
                    }
                }}
                />
            <ColoredWeekSchedule
                shifts={shifts}
                onChange={(shifts: IShiftData[]) => update({shifts})}/>
        </SubHeader>
    </>

    const {insert, update, delete: _delete} = state.api.write.schedule;
    
    return <StandardForm 
                title='Schedule'
                loadItemPermission={async () => !!customer_id && !!job_id && !!schedule_id}
                onLoad={ isNew ? undefined : async () => state.api.read.schedule.item({customer_id, job_id, schedule_id, refresh: true})}
                getNew={() => scheduleModelData.getNewModel({job_id})}
                onCancelNavigate='/schedules'
                onInsert={async item => insert({customer_id, job_id, item}) }
                onUpdate={async item => update({customer_id, job_id, schedule_id, item})}
                onDelete={async () => _delete({customer_id, job_id, schedule_id})}                                                                                                                                                                                                                                                                                                                                                                                                                                        
                
                formData={formData}
                />
}
