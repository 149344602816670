import React from 'react';
import {ISubscriptionModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel} from 'components/forms';
import {subscriptionDurationFormat} from './utils';

export const SubscriptionViewData : IViewData<ISubscriptionModel> = ({
    data: {
        id,
        length_years,
        length_days,
        order_number,
        part_number,
        part_quantity,
        },
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedLabel
                label="Order Number"
                help={[]}
                value={order_number}
                />
            <WrappedLabel
                label="Part Number"
                help={[]}
                value={part_number}
                />
            <WrappedLabel
                label="Part Quantity"
                help={[]}
                value={part_quantity}
                />
            <WrappedLabel
                label="Duration"
                help={[]}
                value={subscriptionDurationFormat({length_years, length_days})}
                />
        </SubHeader>
    </>
