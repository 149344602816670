import React from 'react';
import {IJobConfigModel} from '__core/models';
import {DateTime} from 'luxon';
import {IStateTimeSummaryModel} from '../state-time/utils/get-summary'; 
import {ResponsiveContainer, LineChart, Line, Legend, Tooltip, XAxis, YAxis, CartesianGrid} from 'recharts';
import {formatDurationSeconds} from 'utils';
import {Button} from 'antd';
import CsvDownloader from 'react-csv-downloader';
import {DownloadOutlined} from '@ant-design/icons';
import { start } from 'repl';
import { stringify } from 'querystring';

const COLORS = ['#f00','#0c0','#00f','#770','#f0f','#f70'];

interface IProps {
    summary?: IStateTimeSummaryModel;
    jobConfig?: IJobConfigModel;
    timeZone: string;
    mobile: boolean;
}

export const TopLossesChart = ({summary, jobConfig, timeZone, mobile}: IProps) => {

    if (!summary || !jobConfig) return null;

    const {config} = jobConfig;


    const startTime = DateTime.fromSeconds(summary.total.startTime);
    const endTime = DateTime.fromSeconds(summary.total.endTime);
    startTime.setZone(timeZone);
    endTime.setZone(timeZone);

    const downStates = Object.values(summary.total.states).filter(s => s.state in config.stateTime.states && config.stateTime.states[s.state].type === 'down')

    if (downStates.length<0) return <div>No down states for selected interval.</div>

    downStates.sort((a,b)=> b.duration - a.duration);

    const topDownStates = downStates.slice(0,6);

    const series = summary.divs.map(d => topDownStates.reduce((a, s) => ({...a, [config.stateTime.states[s.state].text]: d.states[s.state]?.duration || 0}),{startTime: d.startTime}));

    const xFormat = summary.divBy === 'hour'
        ?   'HH'
        :   summary.divBy === 'day' || summary.divBy === 'week'
        ?   'MM/dd'
        :   'MM';
        
    const getCsv = () : Record<string,any>[] => {
        return series.map(data => {
            const {startTime, ...states} = data;
            return {
                'Start Time': DateTime.fromSeconds(startTime).toFormat('yyyy-MM-dd HH:mm:ss'),
                ...Object.entries(states).reduce<Record<string,any>>((a,[k,v]) => ({
                    ...a,
                    [k]: formatDurationSeconds(Number(v)),
                }),{}),
            };
        });
    }

    const footer =  <CsvDownloader datas={() => getCsv()} filename={`time-tracker-top-losses`} extension='.csv'>
                        <Button type='primary' size='small'><DownloadOutlined/>Download CSV</Button>
                    </CsvDownloader>

    return (
        <>
        <ResponsiveContainer width='100%' height={mobile ? 200 : 400}>
            <LineChart data={series}>
                <YAxis width={100} tickFormatter={value => formatDurationSeconds(value)}/>
                <XAxis dataKey='startTime' tickFormatter={value => DateTime.fromSeconds(value).toFormat(xFormat)}/>
                <Legend 
                    layout={mobile ? 'horizontal' : 'vertical'} 
                    align={mobile ? 'center': 'right'} 
                    verticalAlign={mobile ? 'bottom' : 'middle'}/>
                <Tooltip formatter={(value, name) =>[formatDurationSeconds(Number(value)), name] } labelFormatter={value => DateTime.fromSeconds(value).toFormat(xFormat)}/>
                <CartesianGrid stroke="#f5f5f5" fill='#ccf'/>
                {Object.keys(series[0] || {}).map((k,i) => i === 0 ? null : <Line type='monotone' dataKey={k} stroke={COLORS[i-1]} strokeWidth={2} isAnimationActive={false}/>)}
            </LineChart>
        </ResponsiveContainer>
        {footer}
        </>
    )
}