import React from 'react';
import {Radio, Space} from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label: React.ReactNode | string;
    help: string[];
    value : T;
    options: Record<string,T>,
    onChange: (value: T) => void;
}

export const WrappedRadio = <T extends string | number | boolean>({
    label,
    help,
    value,
    options,
    onChange,
}: IProps<T>) => {

    return (
        <StandardFormItem label={label} help={help}>
            <Radio.Group onChange={e => onChange(e.target.value)} value={value}>
                <Space direction='vertical'>
                    {Object.entries(options).map(([name, value]) => <Radio value={value}>{name}</Radio>)}
                </Space>
            </Radio.Group>
        </StandardFormItem>
    );
}