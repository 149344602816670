import { UUID } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

type TimeSlotStatus = boolean;
type DayStatus = TimeSlotStatus[];

export interface IShiftData {
    name: string;
    times: DayStatus[];
}
export interface IScheduleModel {
    id: UUID;
    job_id: string;
    name: string;
    shifts: IShiftData[];
}

interface INewModelProps {
    job_id: UUID;
}

const updateKeys: Extract<keyof IScheduleModel, string>[] = [
    'name',
    'shifts',
]

export const scheduleModelData: IModelData<IScheduleModel, INewModelProps> = {
    table: 'schedule',
    selectKeys: ['id', 'job_id', ...updateKeys],
    insertKeys: [
        'job_id',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ job_id }: INewModelProps) => ({
        id: '',
        job_id,
        name: '',
        shifts: [],
    }),
    validate: ({ partial }) => item => validator<IScheduleModel>({
        item,
        partial,
        config: {
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            name: {
                type: 'string',
            },
            shifts: {
                array: {
                    maxLength: 8,
                }
            },
        },
    })
}
