import React, { useContext, useState } from 'react';
import { UUID } from '__core/utils/types';
import { IAuditLogModel } from '__core/models';
import { TableColumnProps, List } from 'antd';
import { AppContext, useFilters } from '../../state';
import { ItemListView } from 'components/lists/item-list-view';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { AuditLogViewData } from './item-view';
import { useShowMessages, useSorter } from 'hooks';
import { formatTimeSince } from 'utils/time-format';

interface IProps {
    access_id?: UUID;
    item_id?: UUID;
}

export const AuditLogListView = ({ access_id, item_id }: IProps) => {

    const navigate = useNavigate();

    const { state, dispatch, notify } = useContext(AppContext);
    const [itemLoading, setItemLoading] = useState('');
    const { errorNotification } = useShowMessages();
    const { genericFilters } = useFilters('audit-log');
    const { genericSorter } = useSorter();

    if (!state.userAccount?._is_rbi) return <></>;

    const customer_id = state.customer?.id || '';

    const smallCols: TableColumnProps<IAuditLogModel>[] = [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (_, { }) => (
                <>
                    <div />
                </>
            ),
            ...genericFilters({ name: 'name' })
        },
    ]

    const largeCols: TableColumnProps<IAuditLogModel>[] = [
        /*   
        {
            title: 'ID',
            dataIndex: 'id',
            ...genericSorter({name: 'id'}),
            ...genericFilters({name: 'id'}),
        },
        */
        {
            title: 'Timestamp',
            dataIndex: 'action_datetime',
            render: action_datetime => DateTime.fromSeconds(action_datetime).toFormat('DD HH:mm:ss'),
            ...genericSorter({ name: 'action_datetime' }),
        },
        {
            title: 'Access ID',
            dataIndex: 'access_id',
            render: id => id.substring(0, 8) + '...',
            ...genericSorter({ name: 'access_id' }),
            ...genericFilters({ name: 'access_id' }),
        },
        {
            title: 'Access Table',
            dataIndex: 'access_table',
            ...genericSorter({ name: 'access_table' }),
            ...genericFilters({ name: 'access_table' }),
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            ...genericSorter({ name: 'user_name' }),
            ...genericFilters({ name: 'user_name' }),
        },
        {
            title: 'Item ID',
            dataIndex: 'item_id',
            render: id => id.substring(0, 8) + '...',
            ...genericSorter({ name: 'item_id' }),
            ...genericFilters({ name: 'item_id' }),
        },
        {
            title: 'Item Table',
            dataIndex: 'item_table',
            ...genericSorter({ name: 'item_table' }),
            ...genericFilters({ name: 'item_table' }),
        },
        {
            title: 'Action',
            dataIndex: 'action_taken',
            ...genericSorter({ name: 'action_taken' }),
            ...genericFilters({ name: 'action_taken' }),
        },
        {
            title: 'Explanation',
            dataIndex: 'explanation',
            ...genericSorter({ name: 'explanation' }),
            ...genericFilters({ name: 'explanation' }),
        },
        {
            title: 'Payload',
            dataIndex: 'payload',
            ...genericSorter({ name: 'payload' }),
            ...genericFilters({ name: 'payload' }),
        },
    ]

    return (
        <ItemListView
            title={access_id ? 'Access Log' : item_id ? 'Item Log' : 'Audit Log'}
            allowEdit={false}
            itemKey='id'
            loadItemPermission={async () => !!state.userAccount?._is_rbi}
            loadItems={async ({ refresh }) => state.api.read.auditLog.list({ access_id, item_id, refresh })}
            loadViewItem={async ({ item: { id }, refresh }) => state.api.read.auditLog.item({ audit_log_id: id, refresh })}
            ViewData={AuditLogViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnViewNav={item => `/jobs/view/${item.id}`}
            isItemLoading={item => item.id === itemLoading}
            itemIsLoading={!!itemLoading}
        />
    )
}
