import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../state';
import { TimeIntervalSelect, DataError } from 'components/views';
import { Typography, Spin } from 'antd';
import { StateTimeHistogram } from './histogram';
import { StateTimeSummary } from './summary';
import { getHistogram, IStateTimeHistogramModel } from './utils/get-histogram';
import { getSummary, IStateTimeSummaryModel } from './utils/get-summary';
import { applyShiftSchedule } from './utils/apply-shift-schedule';
import { IStateTimeModel } from '__core/models';


export const StateTimeView = () => {
    const { state, dispatch } = useContext(AppContext);
    const [histogram, setHistogram] = useState<IStateTimeHistogramModel | null>(null);
    const [summary, setSummary] = useState<IStateTimeSummaryModel | null>(null);
    const [error, setError] = useState(false);

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';
    const timeZone = state.job?.time_zone || 'America/Chicago';
    const schedule = state.schedule;

    useEffect(() => {
        let cancel = false;
        setHistogram(null);
        setSummary(null);
        setError(false);
        (async () => {
            try {
                const newDataBuffer: IStateTimeModel[][] = [];
                let count = 100000;
                let startTime = state.timeIntervalSelect.startTime;
                let tryCount = 0;
                while (count >= 100000 && tryCount <= 20) {
                    const readData = await state.api.read.stateTime.list({
                        customer_id,
                        job_id,
                        startTime,
                        endTime: state.timeIntervalSelect.endTime,
                        refresh: state.timeIntervalSelect.endTime > Date.now() / 1000,
                    });
                    count = readData.length;
                    tryCount++;
                    newDataBuffer.push(readData);
                    if (readData.length) startTime = readData[readData.length - 1].time + 1;
                }
                const newDataBufferFiltered = newDataBuffer.filter(data => data.length);
                newDataBufferFiltered.sort((a, b) => a[0].time - b[0].time);
                const newData = newDataBufferFiltered.reduce<IStateTimeModel[]>((a, c) => {
                    if (!a.length) return c;
                    if (!c.length) return a;
                    if (a[a.length - 1].state === c[0].state) return [...a, ...c.slice(1)]
                    return [...a, ...c];
                }, []);
                if (!cancel) {
                    const scheduleFilteredData = schedule?.id && state.timeIntervalSelect.shiftName
                        ? applyShiftSchedule({
                            startTime: state.timeIntervalSelect.startTime,
                            endTime: state.timeIntervalSelect.endTime,
                            data: newData,
                            schedule,
                            shiftName: state.timeIntervalSelect.shiftName,
                            timeZone
                        })
                        : newData;
                    const newHistogram = getHistogram({ data: scheduleFilteredData, startTime: state.timeIntervalSelect.startTime, endTime: state.timeIntervalSelect.endTime, hideOutliers: state.timeIntervalSelect.hideOutliers });
                    const newSummary = getSummary({ histogram: newHistogram, timeZone })

                    if (state.timeIntervalSelect.span === 'day' || state.timeIntervalSelect.span === 'week')
                        setHistogram(newHistogram)
                    setSummary(newSummary);
                }
            } catch (e: any) {
                console.log(e.message);
                setError(true);
            }
        })();
        return () => { cancel = true };
    }, [customer_id, job_id, state.timeIntervalSelect, setHistogram, setSummary]);

    return (
        <div>
            <div>
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        zIndex: 99,
                        backgroundColor: "#f7f7f7",
                        borderRadius: 5,
                    }}
                >
                    <TimeIntervalSelect
                        data={state.timeIntervalSelect}
                        onChange={timeIntervalSelect => dispatch({ type: 'set-time-interval', timeIntervalSelect })}
                        timeZone={timeZone}
                        shiftList={(schedule?.shifts || []).map(({ name }) => name)}
                        showExtraFilters
                        wrap={state.isMobile} />
                </div>
                <Typography.Title>Time Tracker</Typography.Title>
            </div>
            {error
                ? <DataError />
                : !summary
                    ? <Spin tip='Loading...' size='large' />
                    : <>
                        <StateTimeHistogram
                            histogram={histogram}
                            jobConfig={state.config}
                            timeSelect={state.timeIntervalSelect}
                            timeZone={timeZone}
                            mobile={state.isMobile} />

                        <StateTimeSummary
                            summary={summary?.total || undefined}
                            jobConfig={state.config}
                            hideStandby={state.timeIntervalSelect.hideStandby}
                            hideOutliers={state.timeIntervalSelect.hideOutliers}
                            mobile={state.isMobile}
                        />
                    </>
            }

        </div>
    )
}
