import React, {useContext} from 'react';
import {ITokenModel} from '__core/models';
import {TableColumnProps} from 'antd';
import {AppContext, useFilters} from '../../state';
import {ItemListView} from 'components/lists/item-list-view';
import { TokenViewData } from './item-view';
import {useSorter} from 'hooks';
import {formatTimeSince} from 'utils/time-format';

export const TokenListView = () => {

    const {state} = useContext(AppContext);

    const {genericFilters} = useFilters('token');
    const {genericSorter} = useSorter();

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';

    const smallCols : TableColumnProps<ITokenModel>[] = [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (_,{id, access_type, notes}) => (
                <>
                <div>{id}</div>
                <div><i>{access_type}</i></div>
                <div>{notes}</div>
                </>
            ),
            ...genericSorter({name: 'access_type'}),
            ...genericFilters({name: 'access_type'}),
        },
    ]

    const largeCols : TableColumnProps<ITokenModel>[] = [        
        {
            title: 'Token',
            dataIndex: 'source_last_digits',
            render: source_last_digits => `****${source_last_digits}`,
            ...genericSorter({name: 'source_last_digits'}),
            ...genericFilters({name: 'source_last_digits'}),
        },        
        {
            title: 'Active',
            dataIndex: 'active',
            render: active => active ? 'Active' : 'Inactive',
            ...genericSorter({name: 'active'}),
            ...genericFilters({name: 'active'}),
        },
        {
            title: 'Access Type',
            dataIndex: 'access_type',
            ...genericSorter({name: 'access_type'}),
            ...genericFilters({name: 'access_type'}),
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            ...genericSorter({name: 'notes'}),
            ...genericFilters({name: 'notes'})
        },
        {
            title: 'Last Use',
            dataIndex: 'last_alive',
            render: last_alive => formatTimeSince(last_alive),
            ...genericSorter({name: 'last_alive'}),
        },
    ]

    return (
        <ItemListView
            title='Tokens'
            allowEdit={state.userAccount?.is_admin}
            itemKey='id'
            loadItemPermission={async () => !!customer_id && !!job_id}
            loadItems={async ({refresh}) => state.api.read.token.list({customer_id, job_id, refresh})}
            loadViewItem={async ({item: {id}, refresh}) => state.api.read.token.item({customer_id, job_id, token_id: id, refresh})}
            ViewData={TokenViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `/admin/tokens/edit/${item.id}`}
            columnViewNav={item => `/admin/tokens/view/${item.id}`}
            itemNewNav='/admin/tokens/new'
            itemLogId={({id}) => id}
            accessLogId={({id}) => id}
        />
    )
}
