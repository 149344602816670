import { IApi, getApi } from '__core/api';
import { ICustomerModel, IUserAccountModel, IJobModel, IJobConfigModel, IScheduleModel } from '__core/models';
import { ITimeIntervalSelect, getDefaultTimeIntervalSelect } from 'components/views/time-interval-select';
import { ICompletedItemFilter } from './completed-item-filters';
import { ICompletedItemCharts } from './completed-item-charts';

export interface IAppState {
    api: IApi;
    customer?: ICustomerModel;
    userAccount?: IUserAccountModel;
    job?: IJobModel;
    schedule?: IScheduleModel;
    config?: IJobConfigModel;
    calculateLinearFootage?: number;
    userAuth?: string;
    isMobile: boolean;
    timeIntervalSelect: ITimeIntervalSelect;
    filters: Record<string, Record<string, string>>;
    completedItemFilter: ICompletedItemFilter;
    completedItemCharts: ICompletedItemCharts;
    timeAsMinutesDecimals: boolean;
};

export const getNewAppState = (): IAppState => ({
    api: getApi(''),
    isMobile: false,
    timeIntervalSelect: getDefaultTimeIntervalSelect('America/Chicago'),
    filters: {},
    completedItemFilter: {},
    completedItemCharts: {},
    timeAsMinutesDecimals: false,
})