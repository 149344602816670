import React from 'react';
import { StandardFormItem} from './standard-form-item';
import {List} from 'antd';

interface IProps<T> {
    label: React.ReactNode | string;
    help: string[];
    value : T[];
}

export const WrappedList = <T extends string | number | boolean>({
    label,
    help,
    value,
}: IProps<T>) => {
    return (
        <StandardFormItem label={label} help={help}>
            <List
                dataSource={value}
                renderItem={item => <List.Item>{item}</List.Item>}
                />
        </StandardFormItem>
    );
}