import {UnixTime} from '__core/utils/types';
import {IStateTimeModel} from '__core/models';

const NO_SHIFT_STATE = -1
const SHIFT_START_STATE = -10;
const OUTLIER_STATE = -2;

export interface IStateTimeHistogramModel {
    data: {
        state: number;
        time: number;
        duration: number;
    }[];
    startTime: UnixTime;
    endTime: UnixTime; 
}

interface IProps {
    data: IStateTimeModel[];
    startTime: UnixTime;
    endTime: UnixTime;
    hideOutliers: boolean;
}

export const getHistogram = ({data,startTime,endTime, hideOutliers}:IProps) : IStateTimeHistogramModel => {

    const now = Math.trunc(Date.now()/1000)

    if (endTime > now && (!data.length || data[data.length -1].state >= 0))
        data.push({
            state: -5,
            time: now,
        })

    const res = {        
        startTime,
        endTime,
        data: data.map(({state,time},index,source)=> ({state, time, duration: (index < source.length - 1 ? source[index+1].time : endTime) - time})),
    }

    return hideOutliers
    ?   {
            ...res, 
            data: res.data.map(item => 
            item.duration < 4 * 60 * 60 || item.state === NO_SHIFT_STATE 
            ?   item 
            :   {   ...item, 
                    state: OUTLIER_STATE
                }
            )
        }
    :   res;

}
