import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../state';
import { getHistogram } from '../state-time/utils/get-histogram';
import { getSummary, IStateTimeSummaryModel } from '../state-time/utils/get-summary';

interface IProps {
    startTime: number;
    endTime: number;
}

export const useStateTime = ({ startTime, endTime }: IProps) => {
    const { state, dispatch } = useContext(AppContext);
    const [summary, setSummary] = useState<IStateTimeSummaryModel | null>(null)

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';
    const timeZone = state.job?.time_zone || 'America/Chicago';

    useEffect(() => {
        let cancel = false;
        setSummary(null);
        (async () => {
            const newData = await state.api.read.stateTime.list({
                customer_id,
                job_id,
                startTime,
                endTime,
                refresh: true,
            })
            if (!cancel) {
                const newHistogram = getHistogram({ data: newData, startTime, endTime, hideOutliers: false });
                const newSummary = getSummary({ histogram: newHistogram, timeZone })
                setSummary(newSummary);
            }
        })();
        return () => { cancel = true };
    }, [customer_id, job_id, setSummary, startTime, endTime]);

    return {
        summary,
    }
}
