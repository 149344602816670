import React from 'react';
import DatePicker from 'react-datepicker';
import { StandardFormItem } from './standard-form-item';
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
    label: React.ReactNode | string;
    help: string[];
    value : number;
    onChange: (value: number) => void;
    unit?: string;
}

export const WrappedDatePicker = ({
    label,
    help,
    value,
    onChange,
}: IProps) => {
    return (
        <StandardFormItem label={label} help={help}>
            <DatePicker selected={new Date(value * 1000)} onChange={(date: Date) => onChange(date.getTime() / 1000) }/>
        </StandardFormItem>
    );
}