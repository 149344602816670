import React from 'react';
import { formatDurationSeconds } from 'utils';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

export interface IDataSeriesItem {
    value: number;
    label: string;
    color: string;
}

interface IProps {
    series: IDataSeriesItem[];
    mobile: boolean;
}

export const WrappedPieChart = ({ series, mobile }: IProps) =>
    <ResponsiveContainer width="99%" height={400}>
        <PieChart>
            <Legend
                verticalAlign={mobile ? 'bottom' : 'middle'}
                align={mobile ? 'center' : 'right'}
                layout={mobile ? 'horizontal' : 'vertical'}
                wrapperStyle={{ maxHeight: mobile ? 100 : 400, overflowY: 'scroll' }}
            />
            <Pie
                data={series}
                dataKey='value'
                nameKey='label'
                cx='50%'
                cy='50%'
                isAnimationActive={false}
            >
                {series.map(entry => <Cell fill={entry.color} />)}
            </Pie>
            <Tooltip
                formatter={(value, name) => [formatDurationSeconds(Number(value)), name]}
            />
        </PieChart>
    </ResponsiveContainer>
