import {IStateTimeModel} from '__core/models';
import {UnixTime} from '__core/utils/types';
import {IStateTimeHistogramModel} from './get-histogram';
import {DateTime} from 'luxon';

export interface IStateTimeIntervalSummaryModel {
    state: UnixTime;
    min: number;
    max: number;
    duration: number;
    count: number;
}

export interface IStateTimeDivSummaryModel {
    states: Record<number,IStateTimeIntervalSummaryModel>;
    duration: number;
    startTime: UnixTime;
    endTime: UnixTime;
}

export type StateTimeSummaryDivType = 'hour' | 'day' | 'week' | 'month';

export interface IStateTimeSummaryModel {
    divBy: StateTimeSummaryDivType,
    total: IStateTimeDivSummaryModel;
    divs: IStateTimeDivSummaryModel[];
    timeZone: string;
}

interface IProps {
    histogram: IStateTimeHistogramModel;
    timeZone: string;
}

const DAY_SECONDS = 86400; // 24 * 60 * 60;

export const getSummary = ({histogram: {data, startTime, endTime}, timeZone}: IProps) : IStateTimeSummaryModel => {
    const duration = endTime - startTime;
    const days = duration / DAY_SECONDS;
    const divBy : StateTimeSummaryDivType =
            days <= 2
        ?   'hour'
        :  days <= 32
        ?   'day'
        :   'month';

    const res : IStateTimeSummaryModel = {
        total: {
            states: {},
            duration,
            startTime,
            endTime,
        },
        divs: [],
        divBy,
        timeZone,
    }

    interface IUpdateIntervalProps {
        interval: IStateTimeIntervalSummaryModel;
        duration: number;
    }

    let _divStart = DateTime.fromSeconds(0);
    let _nextDivStart = DateTime.fromSeconds(startTime);
    let _divStartSeconds = 0;
    let _nextDivStartSeconds = 0;

    const incrementDivTimes = () => {
        _divStart = _nextDivStart.plus({second: 0});
        _nextDivStart = _divStart.plus({[divBy]: 1}).startOf(divBy);
        _nextDivStart.startOf(divBy);

        _divStartSeconds = _divStart.toSeconds();
        _nextDivStartSeconds = Math.min(_nextDivStart.toSeconds(), endTime);
    }

    incrementDivTimes();

    const updateInterval = ({interval,duration}:IUpdateIntervalProps) => 
        duration <= 0 
        ?   interval 
        :   ({
                state: interval.state,
                min: Math.min(interval.min, duration),
                max: Math.max(interval.max, duration),
                count: interval.count + 1,
                duration : interval.duration + duration,
            })

    const getNewInterval = (state: number) : IStateTimeIntervalSummaryModel => ({
        state, 
        count: 0, 
        min: 99999999, 
        max: 0, 
        duration: 0 
    })

    const getNewDivSummary = (startTime: number, endTime: number) : IStateTimeDivSummaryModel => ({
        startTime,
        endTime,
        duration: endTime - startTime,
        states: {},
    })

    res.divs = [getNewDivSummary(_divStartSeconds, _nextDivStartSeconds)];

    data.forEach(({state, time, duration}) => {
        const getTime = () => Math.max(time, _divStartSeconds);
        const getDuration = () => duration -(getTime() - time);
        // update total
        res.total.states[state] = updateInterval({
            interval: res.total.states[state] || getNewInterval(state), 
            duration
        });
        //console.log(time, _divStartSeconds, _nextDivStartSeconds);
        // update div
        if (getTime() >= _nextDivStartSeconds) {
            incrementDivTimes();
            res.divs.push(getNewDivSummary(_divStartSeconds, _nextDivStartSeconds));
        }
        while (getTime() + getDuration() > _nextDivStartSeconds){
                const _duration = _nextDivStartSeconds - getTime();
                res.divs[res.divs.length-1].states[state] = updateInterval({
                    interval: res.divs[res.divs.length-1].states[state] || getNewInterval(state), 
                    duration: _duration,
                });
                if (_nextDivStartSeconds < endTime){
                    incrementDivTimes();
                    res.divs.push(getNewDivSummary(_divStartSeconds, _nextDivStartSeconds));
                    res.divs[res.divs.length-1].states[state] = updateInterval({
                        interval: res.divs[res.divs.length-1].states[state] || getNewInterval(state), 
                        duration: _duration - _duration,
                    });
                }
        } 

        res.divs[res.divs.length-1].states[state] = updateInterval({
            interval: res.divs[res.divs.length-1].states[state] || getNewInterval(state), 
            duration: getDuration(),
        });
    });

    return res;
}
