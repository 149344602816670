import React, {useState, useEffect} from 'react';
import {Typography, Button, Spin, Popconfirm} from 'antd';
import {SaveTwoTone, DeleteTwoTone , LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {useLoadData, useShowMessages} from '../../hooks';
import {useNavigate} from 'react-router-dom';
import {IApiWriteRouteResponse} from '__core/api/write-response';
import {BasicForm} from './basic-form';

interface IFormDataProps<T> {
    data: T,
    update: (p: Partial<T>) => void,
}

export type IFormData<T> = (props: IFormDataProps<T>) => React.ReactNode;

interface IProps<T> {
    title: string;
    loadItemPermission: () => Promise<boolean>;
    onLoad?: () => Promise<T | null>;
    getNew: () => T;
    onUpdate: (item: T) => Promise<IApiWriteRouteResponse>;
    onInsert: (item: T) => Promise<IApiWriteRouteResponse>;
    onDelete?: (item: T) => Promise<IApiWriteRouteResponse>;
    onCancelNavigate?: string;
    formData: IFormData<T>;
    autoSave?: boolean;
}

export const StandardForm = <T extends Object>({
    title,
    loadItemPermission,
    onLoad,
    getNew,
    onUpdate,
    onInsert,
    onDelete,
    onCancelNavigate,
    formData,
    autoSave,
}: IProps<T>) => {

    const navigate = useNavigate();

    const [item, setItem] = useState<T | null>(null);
    const [busy, setBusy] = useState(false);
    const [saveWait, setSaveWait] = useState(false);
    const [deleteWait, setDeleteWait] = useState(false);

    const {
        loading,
        setLoading,
        message,
        setMessage,
    } = useLoadData({});

    const {
        successNotification,
        errorNotification,
    } = useShowMessages();
    
    useEffect(() => {
        if (onLoad) load();
        else {
            setItem(getNew())
            setMessage('');
        }
    },[])

    const load = async () => {
        setLoading(true);
        try {
            if (await loadItemPermission()) {
                const loadedItem = await onLoad!();
                if (loadedItem === null) throw new Error();
                setItem( loadedItem);
                setMessage('');
            } else setMessage('Function not allowed.');
        } catch(e){
            setMessage('Error getting data.');
        }
        setLoading(false);
    }

    if (message) return <>{message}</>
    if (loading) return <Spin tip='Loading...' size='large' />;
    if (!item) return <>Oops! Something went wrong!</>


    const updateItem = (partialItem : Partial<T>) => {
        setItem({...item, ...partialItem})
        if(autoSave) save();
    };

    const save = async () => {
        setBusy(true)
        setSaveWait(true);
        try{
            const result = await (onLoad ? onUpdate(item) : onInsert(item));
            showMessages(result.messages);
            if (result.success && onCancelNavigate) navigate(onCancelNavigate);
        } catch(e){
            errorNotification('Error performing action.');
        }
        setSaveWait(false);
        setBusy(false);
    }

    const remove = async () => {
        setBusy(true);
        setDeleteWait(true);
        try{
            if(!onDelete) throw new Error('Delete not supported');
            const result = await onDelete(item);
            showMessages(result.messages);
            if (result.success && onCancelNavigate) navigate(onCancelNavigate);
        } catch(e){
                errorNotification('Error performing action.');
        }
        setDeleteWait(false);
        setBusy(false);
    }

    const showMessages = (messages: Record<string, string[]>) => {
        if (messages._success) successNotification(messages._success[0] || 'Success');
        if (messages._error) errorNotification(messages._error[0] || 'Error');
        Object.entries(messages).forEach(([title, msgArray]) => {
            if (!title.startsWith('_')) {
                console.log(msgArray);
                msgArray.forEach(msg => errorNotification(msg));
            }
        })
    }

    return (
        <>
            <Typography.Title>{title}</Typography.Title>
            {
                !onLoad
                ?   <h3>New</h3>
                : null
            }
            {   
                onCancelNavigate 
                ?   <div style={{margin: 5}}>
                        <Button disabled={busy} style={{margin: 5}} onClick={() => navigate(onCancelNavigate)}><LeftOutlined/> Back</Button>
                    </div>
                : null
            }
            <BasicForm>
                {formData({data: item, update: updateItem})}
            </BasicForm>
            <div style={{margin: 5}}>
                {   onLoad && onDelete
                    ?   <Popconfirm
                            title='Delete item?' 
                            icon={<QuestionCircleOutlined style={{color: '#f00'}}/>}
                            onConfirm={remove}
                            >
                            <Button disabled={busy} style={{margin: 5, float:'right'}} danger >
                                {   deleteWait
                                ?   <Spin size="small"/>
                                :   <><DeleteTwoTone twoToneColor='#f00'/> Delete</>
                                }
                            </Button>
                        </Popconfirm>
                    :   null
                }
                {
                    autoSave
                ?   null
                :   <Button disabled={busy} style={{margin: 5, color:'#0a0'}} onClick={save}>
                        {saveWait
                        ?   <Spin size="small"/>
                        :   <><SaveTwoTone twoToneColor='#0A0'/> Save</>
                        }
                    </Button>
                }
            </div>
        </>
    )
}
