import React from 'react';
import {Select} from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label?: React.ReactNode | string;
    name?: string;
    placeholder?: string;
    numeric?: boolean;
    help: string[];
    value : T;
    onChange: (value: T) => void;
}

export const WrappedMultiInput = <T extends string[] | number[]>({
    label,
    name,
    placeholder,
    numeric,
    help,
    value,
    onChange,
}: IProps<T>) => {
    return (
        <StandardFormItem label={label} name={name} help={help}>
            <Select
                placeholder={placeholder}
                mode="tags"
                value={value}
                onChange={e => {
                    if (numeric && e.some((value) => isNaN(Number(value)))) return;
                    e.sort();
                    onChange((numeric ? e.map(Number) : e) as T)}
                }
                />
        </StandardFormItem>
    );
}