import React from 'react';
import { IJobConfigModel} from '__core/models';
import {TableChart} from './table-chart';
import {WrappedPieChart} from './pie-chart';
import {IStateTimeDivSummaryModel} from './utils/get-summary';

interface IProps {
    summary?: IStateTimeDivSummaryModel;
    jobConfig?: IJobConfigModel;
    hideStandby?: boolean;
    hideOutliers?: boolean;
    hideTable?: boolean;
    mobile: boolean;
}

export const StateTimeSummary = ({summary, jobConfig, hideStandby, hideOutliers, hideTable, mobile}: IProps) => {

    if (!summary || !jobConfig) return null;
    const {config} = jobConfig;

    const filteredSummary : IStateTimeDivSummaryModel = {
        ...summary,
    }
    
    filteredSummary.duration -= (summary.states[-1]?.duration || 0);
    delete filteredSummary.states[-1];

    if (hideOutliers){
        filteredSummary.duration -= (summary.states[-2]?.duration || 0);
        delete filteredSummary.states[-2];
    }

    const totalStates = Object.values(summary.states);
    const typeKeys = Object.keys(config.stateTime.types);

    const series = totalStates.map(s => ({
        value: s.duration,
        min: s.min,
        max: s.max,
        count: s.count,
        label: config.stateTime.states[s.state]?.text || `${s.state === -1 ? 'No Shift' :s.state}`,
        color: config.stateTime.types[config.stateTime.states[s.state]?.type || 'blank'].background,
        type: typeKeys.indexOf(config.stateTime.states[s.state]?.type || `blank`),
        typeString: config.stateTime.states[s.state]?.type || `blank`,
        
    }))

    const filteredSeries = hideStandby 
        ?   series.filter(s => s.typeString !== 'standby' && s.typeString !== 'blank')
        :   series.filter(s => s.typeString !== 'blank')

    filteredSeries.sort((a,b) => a.type === b.type ? b.value - a.value : a.type - b.type );

    return (
        <div>
            <WrappedPieChart 
                series={filteredSeries}
                mobile={mobile}/>
            {hideTable ? null : <TableChart series={filteredSeries}/>}
        </div>
    )
}
