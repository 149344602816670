import React from 'react';

interface IProps {
    name: string;
}
export const useSorter = <T extends {[key: string]: any}>() => {
    return {
        genericSorter: ({name}:IProps) => ({
            sorter: (a:T,b:T) =>  a[name] < b[name] ? -1 : 1
        })
    }
}