import { IStateTimeModel, IScheduleModel, IShiftData } from "__core/models"
import {DateTime} from 'luxon';

const NO_SHIFT_STATE = -1
const SHIFT_START_STATE = -10;

interface IProps {
    startTime: number,
    endTime: number,
    data: IStateTimeModel[],
    schedule: IScheduleModel,
    shiftName: string;
    timeZone: string;
}

interface IShiftTimes {
    startTime: number;
    endTime: number;
}

const shiftDataToShiftTimes = (data: IShiftData) : IShiftTimes[] => {
    const res : IShiftTimes[] = [];

    let startTime : number | null = null;
    data.times.forEach((day,dayIndex) => day.forEach((time, timeIndex) => {
        if (time && startTime === null){
            startTime = dayIndex * 24*4 + timeIndex;
        } else if (!time && startTime !== null){
            res.push({startTime, endTime: dayIndex * 24*4 + timeIndex})
            startTime = null;
        }
    }))
    if (startTime !== null){
        if (res.length && res[0].startTime === 0){
            res[0].startTime = startTime;
        }else {
            res.push({startTime, endTime: 7*24*4});
        }
    }
    return res
}

export const applyShiftSchedule = ({startTime, endTime, data, schedule, shiftName, timeZone}: IProps) : IStateTimeModel[] => {
    const shift = schedule.shifts.find(({name}) => name === shiftName);
    if (!shift) return data;

    const shiftTimes = shiftDataToShiftTimes(shift);

    const shiftIntervalData : IStateTimeModel[] = [];

    let shiftCheckTime = DateTime.fromSeconds(startTime).setZone(timeZone);
    const shiftStartTimes = shiftTimes.map(({startTime}) => startTime);
    const shiftEndTimes = shiftTimes.map(({endTime}) => endTime);
    
    while (shiftCheckTime.toSeconds() < endTime){
        const {weekday, hour, minute} = shiftCheckTime;
        const potentialShiftTime = ( weekday - 1) *24 * 4 + hour * 4 + Math.trunc(minute/15);
        if(shiftStartTimes.includes(potentialShiftTime)){
            if (shiftIntervalData.length === 0){
                shiftIntervalData.push({
                    time: startTime,
                    state: NO_SHIFT_STATE,
                });
            }
            shiftIntervalData.push({
                time: shiftCheckTime.toSeconds(),
                state: SHIFT_START_STATE,
            });
        }
        else if(shiftEndTimes.includes(potentialShiftTime)){
            if (shiftIntervalData.length === 0){
                shiftIntervalData.push({
                    time: startTime,
                    state: SHIFT_START_STATE,
                });
            }
            shiftIntervalData.push({
                time: shiftCheckTime.toSeconds(),
                state: NO_SHIFT_STATE,
            });
        }
            
        shiftCheckTime = shiftCheckTime.plus({minutes: 15});
    }
    const filteredIntervalData = data.concat(shiftIntervalData);
    filteredIntervalData.sort((a,b) => a.time !== b.time ? a.time - b.time : a.state - b.state);

    let shiftInclude = false;
    let lastState = SHIFT_START_STATE;
    const finalData = filteredIntervalData.reduce<IStateTimeModel[]>((acc,model, index, allModels) => {
        if (model.state === SHIFT_START_STATE){
            shiftInclude = true;
            if ((allModels.length > index + 1 && allModels[index+1].time !== model.time ) || allModels.length === index + 1)
                acc.push({time: model.time, state: lastState});
        } else if (model.state === NO_SHIFT_STATE){
            shiftInclude = false;
            acc.push(model);
        } else if (shiftInclude) {
            acc.push(model);
        }
        if (model.state>=0) lastState = model.state;
        return acc;
    },[])

    return finalData;
}