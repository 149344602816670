import React from 'react';
import {IScheduleModel} from '__core/models';
import {IViewData, SubHeader, WrappedLabel, WrappedList, ColoredWeekSchedule} from 'components/forms';

export const ScheduleViewData : IViewData<IScheduleModel> = ({
    data: {
            name,
            shifts
        },
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedLabel
                label="Name"
                help={[]}
                value={name}
                />
            <ColoredWeekSchedule shifts={shifts} />
        </SubHeader>
    </>
