import { UnixTime, UUID } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export const tokenAccessTypes = [
    'None',
    'Read Only',
    'Read Write',
    'Time Tracker',
    'Vendor',
    'Scheduler',
] as const;

export type TokenAccessType = typeof tokenAccessTypes[number];

export interface ITokenModel {
    id: UUID;
    job_id: string;
    hash?: string;
    source_last_digits: string;
    active: boolean;
    access_type: TokenAccessType;
    notes: string;
    last_alive: UnixTime;
}

interface INewModelProps {
    job_id: UUID;
}

const updateKeys: Extract<keyof ITokenModel, string>[] = [
    'active',
    'access_type',
    'notes',
    'last_alive',
]

export const tokenModelData: IModelData<ITokenModel, INewModelProps> = {
    table: 'token',
    selectKeys: ['id', 'job_id', 'source_last_digits', ...updateKeys],
    insertKeys: [
        'job_id',
        'hash',
        'source_last_digits',
        ...updateKeys,
    ],
    updateKeys,
    getNewModel: ({ job_id }: INewModelProps) => ({
        id: '',
        job_id,
        source_last_digits: '',
        active: true,
        access_type: "None",
        notes: '',
        last_alive: 0,
    }),
    validate: ({ partial }) => item => validator<ITokenModel>({
        item,
        partial,
        config: {
            active: {
                type: 'boolean',
            },
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            access_type: {
                string: {
                    inList: [...tokenAccessTypes],
                },
            },
            notes: {
                type: 'string',
            },

        },
    })
}
