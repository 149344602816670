export const docs = `## Time Tracker Docs

### v1.4.0

<a href="#general-information">1. General Information</a><br/><a href="#logging-in">2. Logging In</a><br/><a href="#selecting-a-job">3. Selecting A Job</a><br/><a href="#dashboard">4. Dashboard</a><br/><a href="#time-interval-selection">5. Time Interval Selection</a><br/><a href="#time-tracker">6. Time Tracker</a><br/><a href="#completed-items">7. Completed Items</a><br/><a href="#top-losses">8. Top Losses</a><br/><a href="#creating-and-managing-schedules">9. Creating and Managing Schedules</a><br/><a href="#creating-and-managing-users">10. Creating and Managing Users</a><br/><a href="#creating-and-managing-tokens">11. Creating and Managing Tokens</a><br/><a href="#api">12. API</a><br/><a href="#state-codes">13. State Codes</a>

---

<a id="general-information"> </a>
## 1. General Information
<ul>Time Tracker Cloud tracks state and completed item information from your Red Bud Industries processing line. <br>

State information is used to create reports about the line operation and identify areas where operating efficiency can be improved.<br>
State information is also used to show the top down time reasons.<br>
Completed items are shown in tables for specific data or in graphs as summaries.<br>

Schedules can be created to provide additional report filtering at the shift level.

Administrators can create and manage users and access levels as well as tokens for 3rd party applications to access the Time Tracker API.</ul>
---

<a id="logging-in"> </a>
## 2. Logging In
<ul>The system will prompt you for a username and password to login.<br><br>

**New User**

<ul>If you are a new user without an account, please contact your administrator to setup an account for you.</ul><br>

**First Time Logging In**
<ul>
If this is your first time logging in, your username and temporary password should have come to you in an email.
You will be asked to changed your password after logging in with the temporary password.
</ul><br>

**Password Requirements**
<ul>All passwords must be 16 characters long.</ul><br>

**Resetting Your Password**

1. Request your administrator to reset your password.
1. Wait for an email with a verification code.
1. At the login screen, select "Change Password".
1. Enter the required information, including the verification code sent to your email, and press the "Change Password" button.

<br>

<ul><b>NOTE:</b> A user must have logged in at least one time before you can reset their password. If a user has not logged in at least one time and is unable to log in, then delete and recreate their account.</ul>

<br>


**Logging Out**<br>

1. Click on your user name on the left side navigation.
1. Click on the "Logout" button.

<br>

**Help with Login**

<ul>
Please see your administrator for any questions.
Administrators should contact RBI Tech Support for questions.
</ul></ul>
---

<a id="selecting-a-job"> </a>
## 3. Selecting A Job
<ul>After logging in, a list of processing lines (jobs) will be available to select.

General information about the each line will be shown such as line number, location, timezone, language, display units, subscription expiration, and the last time the local server pinged the cloud server.

Press the "Select" button next to the job to select the job.

The currently selected job will be shown in the header.</ul>
---

<a id="dashboard"> </a>
## 4. Dashboard
<ul>Immediately after selecting a job, you will be taken to the dashboard for the line.

The dashboard displays a completed items graph, time tracker chart, and top losses for a recent time period. The time period is user selectable.

See the sections on those individual displays to learn more about them.</ul>
---

<a id="time-interval-selection"> </a>
## 5. Time Interval Selection
<ul>The time interval selection is shown at the top of the Time Tracker, Completed Items, and Top Losses. <br><br>

**Controls**

- Use the drop down to select the type of time interval (e.g. Day, Week, Month, Quarter, Year).
- Press the left and right arrows to move backward and forward by one time interval unit.
- Press the calendar button to select a start for the selected time interval.
- Press the "Today" button to set the current time interval to include the current day.
- Use the shift drop down to filter the data by a shift from the loaded schedule.
    - A schedule select button is shown if no schedule is selected.
</ul>
---

<a id="time-tracker"> </a>
## 6. Time Tracker
<ul>Displays line state information for the selected time interval. <br><br>

**Options**

- Select "Hide Standby" to remove standby data from the aggregated summaries.
- Select "Hide Outliers" to remove any excessively long time periods where data recording may not be correct.

<br>

**Time Chart**

<ul>

When viewing "Day" or "Week" time intervals, a sequential graph for all of the states for the selected time interval is shown. <br>
A second zoomed 1-hour graph is shown beneath the full graph.<br>
Use the slider to move the 1-hour window within the full graph. <br>
</ul><br>

**Pie Chart**

<ul>

For all time intervals, a state-type-grouped pie chart is shown. <br>

</ul><br>

**Table**

<ul>

For all time intervals, a state-type-grouped table is shown <br>

The summary table includes the following data:
- State Type
- State Name
- Percent All - time in state relative to full time span
- Percent Group - time in state relative all states withing group
- Duration - total time in state
- Min - smallest time interval in state
- Max - largest time interval in state
- Count - number of times state occurred

<br>
Use the Expand button to expand the groups to show the individual states.<br>

Use the "Download CSV" button to download the table as a CSV.

</ul><br>
</ul>
---

<a id="completed-items"> </a>
## 7. Completed Items
<ul>Displays tables and charts for completed items for selected time interval.<br><br>

**Options**

- Select "Extended Data" to include the full setup data recorded for the item.

<br>

**Filter** <br>

<ul>

Press the filter button to open the filter selection.<br>

Turn an field on to filter by that field.<br>
Use the options to select filter type.<br>
By default, all field filter based on matching an exact value.<br>
Numeric field have an option for "Range". When selected, there are two entries, a min and a max, for the field.
Multiple fields can be used to filter at the same time.<br>


</ul><br>

**Table**

<ul>

Displays table data for completed items based on the selected time interval and any applied filters.<br>

Click the column head to sort by column.<br>

Press the "Download CSV" button to download the table data as a CSV file.<br>

</ul><br>


**Charts**

<ul>

Displays different fields as charts based on the selected time interval and any applied filters.<br>

Press the "Chart Selection" to edit what charts are shown. Select a field and whether to show a sum (total) or average of the items over each sub-interval.<br>

</ul><br></ul>
---

<a id="top-losses"> </a>
## 8. Top Losses
<ul>Displays top down time reasons for selected time interval. <br><br>

Press the "Download CSV" button to download the source data as a CSV.</ul>
---

<a id="creating-and-managing-schedules"> </a>
## 9. Creating and Managing Schedules
<ul>Create schedules to use as a filter for time intervals.<br><br>

**Creating a Schedule**

- Select "Schedules".
- Click the "New" button".
- Enter the following information:
    - Name - Name of the schedule
    - Shifts - Enter Shift Names for the schedules
    - Schedule Data
        - Select a shift.
        - Drag an area in the schedule to apply the shift.
        - Shifts can be scheduled in 15 minute increments.
        - Use the "Overlap" button to other shifts with the new shift.
        - Use the "Erase" button to delete shifts in the same manner that shifts are scheduled, by clicking and dragging over the desired area to clear.
- Click "Save".

<br>

**Editing a Schedule**

- Select "Schedules".
- Click the "Edit" icon for the schedule.
- Change schedule settings.
    - Note that the user email cannot be changed.
- Click "Save".

<br>

**Deleting a Schedule**

- Select "Schedules".
- Click the "Edit" icon for the schedule.
- Click the "Delete" button on the schedule edit page.
- Click "Ok" to confirm deleting the schedule.

<br>

**Selecting a Schedule**

- Select "Schedules".
- Click the "Select" button to select a schedule.
- The shifts for the selected schedule will be available as a filter in the time interval selection.
</ul>
---

<a id="creating-and-managing-users"> </a>
## 10. Creating and Managing Users
<ul>Users can only be changed by administrators. <br><br>

**Creating a User**

- Select "User Accounts" under the "Admin" menu.
- Click the "New" button".
- Enter the following information:
    - Email - user email for account. email has to be within allowed domain for customer account. Contact RBI Tech support for changes to your customer email domain.
    - Name - Name of user
    - Active - Leave on to allow user to login. Inactive users cannot login
    - Administrator - Set to make the user an Administrator. Administrators can edit Users and Tokens.
    - Job Access - If the user is not an Administrator, then a list of jobs will be provided to set the access level for the user.
- Click "Save".

<br>

**Editing a User**

- Select "User Accounts" under the "Admin" menu.
- Click the "Edit" icon for the user.
- Change user settings.
    - Note that the user email cannot be changed.
- Click "Save".

<br>

**Deleting a User**

- Select "User Accounts" under the "Admin" menu.
- Click the "Edit" icon for the user.
- Click the "Delete" button on the user edit page.
- Click "Ok" to confirm deleting the user.

<br>

**Resetting a User Password**

- Start an edit on the user.
- Turn on "Reset Password".
- Click "Save".

<br>
<ul>
The user will be sent an email with a verification code to use for resetting their password and logging in.<br>
See the login section of this document for how to use the verification code.
</ul><br>

<ul><b>NOTE:</b> A user must have logged in at least one time before you can reset their password. If a user has not logged in at least one time and is unable to log in, then delete and recreate their account.</ul>
</ul>
---

<a id="creating-and-managing-tokens"> </a>
## 11. Creating and Managing Tokens
<ul>Tokens can only be managed by administrators. <br><br>

**Creating a Token**

- Select "Tokens" under the "Admin" menu.
- Click the "New" button".
- Enter the following information:
    - Active - Leave on to allow token to be authorized. Inactive tokens will be denied access.
    - Access - Set access level of the token. Tokens are at the Job level. The access will only apply to the job the token is created under.
    - Notes - General note about the token.
- Click "Save".

<br> 

A window will appear with the token information. **Copy this information because the full token cannot be retrieved again**.<br>
The customer and job id will also be provided. These are also required to use the token. <br>

The last few characters of the token will be visible in the token information to allow a user to match their tokens with the list.

<br>

**Editing a Token**

- Select "Tokens" under the "Admin" menu.
- Click the "Edit" icon for the token.
- Change user settings.
    - Note that token itself can not be edited.
- Click "Save".

<br>

**Deleting a Token**

- Select "Tokens" under the "Admin" menu.
- Click the "Edit" icon for the token .
- Click the "Delete" button on the user edit page.
- Click "Ok" to confirm deleting the user.

<br></ul>
---

<a id="api"> </a>
## 12. API
<ul>An API is available to access data via 3rd party applications.<br><br>

A token must be used for accessing the data.</ul>
---

<a id="state-codes"> </a>
## 13. State Codes
<ul><br>

### States <br>

| Type | Color | Description|
| --- | --- | --- |
| <a href="#run-states">Run</a> |<font color=green>Green</font> | Active when the machine is in Auto Cycle. The run state will also be active for a predetermined amount of time during certain required actions.|
| <a href="#down-states">Down</a> | <font color=red> Red </font> | Active when the machine is not processing material, no machines are setting up.|
| <a href="#setup-states">Setup</a>	| <font color=blue>Blue</font> | Active anytime a machine is setting up, keeping the line from going into Auto Cycle or threading is delayed. It is also active during any tracking state of a coil, before or after processing.|
| <a href="#standby-states">Standby</a>	| <font color="#aa0">Yellow</font>| Selected by the operator during times when they will be away from the line or not in production for reasons not related to the machine.|

<a id="run-states"> </a>
### Run States
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="green">Auto Cycle</font>|1000|Yes|No|<ul><li><b>Auto </b>- Active when the line is in auto cycle and the coil has not tailed out.</li></ul>|
|<font color="green">Coil Load</font>|1001|Yes|No|<ul><li><b>Auto </b>- Active for a predetermined amount of time when the coil is being loaded. Excess coil loading time will be tracked as Setup time.</li></ul>|
|<font color="green">Threading</font>|1002|Yes|No|<ul><li><b>Auto </b>- Active for a predetermined amount of time while the coil is being threaded. Excess threading time will be tracked as Setup time.</li></ul>|
|<font color="green">Product Removal</font>|1003|Yes|No|<ul><li><b>Auto </b>- Active for a predetermined amount of time after a bundle is complete and action is observed on the exit equipment. The amount of time allowed is dependent on the material length.</li></ul>|


<a id="down-states"> </a>
### Down States
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="red">Default Down Time</font>|2000|Yes|No|<ul><li><b>Auto </b>- Active when no other down time reasons can be determined.</li></ul>|
|<font color="red">Cleaning</font>|2001|No|Yes|<ul><li><b>Manual </b>- Selected when cleaning is being performed on the line.</li></ul>|
|<font color="red">Crane</font>|2002|No|Yes|<ul><li><b>Manual </b>- Selected when production is delayed due to unloading material or loading coils with a crane.</li></ul>|
|<font color="red">Bundle Complete</font>|2003|Yes|No|<ul><li><b>Auto </b>- Active when Auto Cycle has stopped due to the part count being reached.</li></ul>|
|<font color="red">Bundle Stopped</font>|2004|Yes|No|<ul><li><b>Auto </b>- Active when Auto Cycle is stopped in the middle of a bundle and it the stop was not initiated by the operator. This typically indicates an alarm condition on the line.</li></ul>|
|<font color="red">Preventative Maintenance</font>|2006|No|Yes|<ul><li><b>Manual </b>- Selected when preventative maintenance is being performed on the line.</li></ul>|
|<font color="red">Jam</font>|2007|No|Yes|<ul><li><b>Manual </b>- Selected when the line is stopped because material is jammed.</li></ul>|
|<font color="red">Order Issue</font>|2008|No|Yes|<ul><li><b>Manual </b>- Selected if the line is stopped because of an issue with an order.</li></ul>|
|<font color="red">Packaging</font>|4000|No|Yes|<ul><li><b>Manual </b>- Selected when production can not continue due to packaging of processed material.</li></ul>|
|<font color="red">Pallet</font>|4001|No|Yes|<ul><li><b>Manual </b>- Selected when production is stopped due to building or loading pallets or other wood.</li></ul>|
|<font color="red">Quality</font>|4002|No|Yes|<ul><li><b>Manual </b>- Selected when the quality of the material is being inspected.</li></ul>|
|<font color="red">Auto Quality</font>|4003|Yes|No|<ul><li><b>Auto </b>- Active when auto cycle has been stopped by the operator before the bundle is complete.</li></ul>|
|<font color="red">Recoil</font>|4004|Yes|Yes|<ul><li><b>Auto </b>- Active when it is detected that the material is being recoiled.</li></ul><ul><li><b>Manual </b>- Selected when a coil is being recoiled and the reason has not automatically updated.</li></ul>|
|<font color="red">Product Removal</font>|4005|No|Yes|<ul><li><b>Manual </b>- Selected when production can no longer take place due to rollouts being full.</li></ul>|
|<font color="red">Unloading Coil</font>|4006|Yes|Yes|<ul><li><b>Auto </b>- Active after the coil has been recoiled and the unloading has started.</li></ul><ul><li><b>Manual </b>- Selected when production is stopped due to the unloading of a coil.</li></ul>|
|<font color="red">Sample</font>|4008|No|Yes|<ul><li><b>Manual </b>- Selected when the line is stopped for taking a sample.</li></ul>|
### Machine Maintenance
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="red">Pad Change - Grip Feed</font>|6000|No|Yes|<ul><li><b>Manual </b>- Selected when the pads are being changed on the listed machine.</li></ul>|
|<font color="red">Pad Change - Stretcher Leveler</font>|6001|No|Yes|<ul><li><b>Manual </b>- Selected when the pads are being changed on the listed machine.</li></ul>|
|<font color="red">Blade Change - Cropping Station</font>|6050|Yes|Yes|<ul><li><b>Auto </b>- Active when the blade check mode is on.</li></ul><ul><li><b>Manual </b>- Selected when the blades are being changed or clearances are being checked on the listed machine.</li></ul>|
|<font color="red">Blade Change - Entry Crop Shear</font>|6051|No|Yes|<ul><li><b>Auto </b>- Active when the blade check mode is on.</li></ul><ul><li><b>Manual </b>- Selected when the blades are being changed or clearances are being checked on the listed machine.</li></ul>|
|<font color="red">Blade Change - Exit Crop Shear</font>|6052|No|Yes|<ul><li><b>Auto </b>- Active when the blade check mode is on.</li></ul><ul><li><b>Manual </b>- Selected when the blades are being changed or clearances are being checked on the listed machine.</li></ul>|
|<font color="red">Blade Change - Prime Shear</font>|6053|No|Yes|<ul><li><b>Auto </b>- Active when the blade check mode is on.</li></ul><ul><li><b>Manual </b>- Selected when the blades are being changed or clearances are being checked on the listed machine.</li></ul>|
|<font color="red">Blade Change - Scrap Chopper</font>|6054|No|Yes|<ul><li><b>Manual </b>- Selected when the blades are being changed or clearances are being checked on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Cropping Station</font>|6100|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Entry Crop Shear</font>|6101|No|Yes|<ul><li><b>Auto </b>- Optional Active when the scrap cart is up and the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Exit Crop Shear</font>|6102|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Scrap Winder</font>|6103|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Scrap Baller</font>|6104|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Empty Scrap - Scrap Chopper</font>|6105|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Peeler Pinch Roll</font>|6150|No|Yes|<ul><li><b>Manual </b>- Selected when the scrap is being emptied on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Flattener</font>|6151|No|Yes|<ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Straightener</font>|6152|Yes|Yes|<ul><li><b>Auto </b>- Active when the Straightener frame is open or if a roll access door is open.</li></ul><ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Leveler</font>|6153|No|Yes|<ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Roll Feed</font>|6154|No|Yes|<ul><li><b>Auto </b>- Active when the exit shelf is down and the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Start/Stop Pinch Roll</font>|6155|No|Yes|<ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Slitter Entry Pinch Roll</font>|6156|No|Yes|<ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
|<font color="red">Roll Cleaning - Scale Breaker</font>|6157|No|Yes|<ul><li><b>Manual </b>- Selected when the rolls are being cleaned on the listed machine.</li></ul>|
### Machine Scheduled Maintenance
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="red">Scheduled Maintenance - General</font>|8000|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Other</font>|8001|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Main Power Unit</font>|8002|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Packaging</font>|8003|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Turnstile</font>|8100|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Cropping State</font>|8101|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Coil Stage</font>|8102|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Coil Lift</font>|8103|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Uncoiler</font>|8104|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Peeler</font>|8105|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Crop Shear</font>|8106|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Flattener</font>|8107|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Straightener</font>|8108|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Straightener Power Unit</font>|8109|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Leveler</font>|8110|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Applicator</font>|8111|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Edge Trim</font>|8112|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Thread</font>|8113|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Dust Collector 01</font>|8114|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Dust Collector 02</font>|8115|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Dust Collector 03</font>|8116|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Roll In Conveyor</font>|8200|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Destacker</font>|8201|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Entry Stack Table</font>|8202|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Drag Pad</font>|8300|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Drag Roll 01</font>|8301|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Drag Roll 02</font>|8302|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Crop Shear</font>|8303|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Alignment System</font>|8304|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Recoiler</font>|8305|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Coil Stage</font>|8306|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Coil Lift</font>|8307|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Stack Table</font>|8403|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Turnstile</font>|8308|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Conveyor</font>|8400|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Diverter</font>|8401|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Drop Stacker</font>|8402|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Rollouts</font>|8404|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Stretcher Leveler</font>|8500|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Stretcher Leveler Power Unit</font>|8501|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Grip Feed</font>|8502|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Roll Feed</font>|8503|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Start/Stop Pinch Rolls</font>|8504|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Exit Applicator</font>|8505|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Prime Shear</font>|8506|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Entry Pinch Roll</font>|8600|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Offline Base</font>|8601|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Inline Base</font>|8602|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Arbor 01</font>|8603|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Arbor 02</font>|8604|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter Arbor 03</font>|8605|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Scrap Winder</font>|8606|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Scrap Baller</font>|8607|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Scrap Chopper</font>|8608|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slitter</font>|8609|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Strip Stacker</font>|8610|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Slit Stack Separator</font>|8611|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Stitcher</font>|8700|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Scale Breaker</font>|8701|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Scale Breaker Filtration</font>|8702|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - EPS Cell 01</font>|8703|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - EPS Cell 02</font>|8704|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - EPS Cell 03</font>|8705|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - EPS Cell 04</font>|8706|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Drying System</font>|8707|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Scheduled Maintenance - Oiler</font>|8708|No|Yes|<ul><li><b>Manual </b>- Selected when scheduled maintenance is taking place on the listed machine.</li></ul>|
### Machine Unscheduled Maintenance
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="red">Unscheduled Maintenance - General</font>|9000|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Other</font>|9001|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Main Power Unit</font>|9002|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Packaging</font>|9003|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Turnstile</font>|9100|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Cropping State</font>|9101|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Coil Stage</font>|9102|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Coil Lift</font>|9103|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Uncoiler</font>|9104|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Peeler</font>|9105|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Crop Shear</font>|9106|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Flattener</font>|9107|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Straightener</font>|9108|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Straightener Power Unit</font>|9109|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Leveler</font>|9110|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Applicator</font>|9111|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Edge Trim</font>|9112|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Thread</font>|9113|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Dust Collector 01</font>|9114|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Dust Collector 02</font>|9115|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Dust Collector 03</font>|9116|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Roll In Conveyor</font>|9200|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Destacker</font>|9201|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Entry Stack Table</font>|9202|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Drag Pad</font>|9300|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Drag Roll 01</font>|9301|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Drag Roll 02</font>|9302|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Crop Shear</font>|9303|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Alignment System</font>|9304|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Recoiler</font>|9305|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Coil Stage</font>|9306|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Coil Lift</font>|9307|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Stack Table</font>|9403|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Turnstile</font>|9308|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Conveyor</font>|9400|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Diverter</font>|9401|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Drop Stacker</font>|9402|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Rollouts</font>|9404|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Stretcher Leveler</font>|9500|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Stretcher Leveler Power Unit</font>|9501|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Grip Feed</font>|9502|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Roll Feed</font>|9503|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Start/Stop Pinch Rolls</font>|9504|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Exit Applicator</font>|9505|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Prime Shear</font>|9506|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Entry Pinch Roll</font>|9600|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Offline Base</font>|9601|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Inline Base</font>|9602|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Arbor 01</font>|9603|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Arbor 02</font>|9604|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter Arbor 03</font>|9605|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Scrap Winder</font>|9606|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Scrap Baller</font>|9607|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Scrap Chopper</font>|9608|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slitter</font>|9609|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Strip Stacker</font>|9610|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Slit Stack Separator</font>|9611|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Stitcher</font>|9700|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Scale Breaker</font>|9701|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Scale Breaker Filtration</font>|9702|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - EPS Cell 01</font>|9703|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - EPS Cell 02</font>|9704|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - EPS Cell 03</font>|9705|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - EPS Cell 04</font>|9706|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Drying System</font>|9707|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|
|<font color="red">Unscheduled Maintenance - Oiler</font>|9708|No|Yes|<ul><li><b>Manual </b>- Selected when unscheduled maintenance is taking place on the listed machine.</li></ul>|


<a id="setup-states"> </a>
### Setup States
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="blue">Coil Prep</font>|3000|Yes|Yes|<ul><li><b>Auto </b>- Active after a coil tails out and the next coil load has not started.</li></ul><ul><li><b>Manual </b>- Selected when the line is stopped because the next coil is not ready and needs to be prepared.</li></ul>|
|<font color="blue">Coil Load</font>|3001|Yes|Yes|<ul><li><b>Auto </b>- Active during the loading of the coil.</li></ul><ul><li><b>Manual </b>- Selected when the tracking state has not automatically changed to coil load.</li></ul>|
|<font color="blue">Tailout</font>|3002|Yes|Yes|<ul><li><b>Auto </b>- Active when the head of the threading coil has reached the tail of the previous coil and can no longer continue threading.</li></ul><ul><li><b>Manual </b>- Selected when threading can not continue due to the previous coil not yet being tailed out.</li></ul>|
|<font color="blue">Threading</font>|3003|Yes|Yes|<ul><li><b>Auto </b>- Active while the coil has been threading longer than the predetermined time allowed.</li></ul><ul><li><b>Manual </b>- Selected when the tracking state has not automatically changed to threading.</li></ul>|
|<font color="blue">Threading Complete</font>|3004|Yes|No|<ul><li><b>Auto </b>- Active when the coil has been threaded all of the way through the line but the initial shear cut to zero the length has not taken place yet.</li></ul>|
|<font color="blue">Run Prep</font>|3005|Yes|No|<ul><li><b>Auto </b>- Active when the line is ready to run automatic cycle for a new bundle or coil but automatic cycle has not started.</li></ul>|
|<font color="blue">Stacker Setup Prep</font>|3006|Yes|No|<ul><li><b>Auto </b>- Active in place of run prep when machine movement is detected in the Stacker area.</li></ul>|
|<font color="blue">Recoiler Setup Prep</font>|3007|Yes|No|<ul><li><b>Auto </b>- Active in place of run prep when machine movement is detected in the Recoiler area.</li></ul>|
|<font color="blue">Leveling - Roller Leveler</font>|30008|Yes|Yes|<ul><li><b>Auto </b>- Active during threading when the material has spend an excessive amount of time in the Roller Leveler area.</li></ul><ul><li><b>Manual </b>- Selected if the line is stopped because of adjustments to the Roller Leveler.</li></ul>|
|<font color="blue">Leveling - Stretcher Leveler</font>|3009|Yes|Yes|<ul><li><b>Auto </b>- Active during threading when the material has spend an excessive amount of time in the Stretcher Leveler area.</li></ul><ul><li><b>Manual </b>- Selected if the line is stopped because of adjustments to the Stretcher Leveler.</li></ul>|
|<font color="blue">Diverter</font>|4007|Yes|No|<ul><li><b>Auto </b>- Active when the diverter is on and threading is complete.</li></ul>|
### Machine Setup States
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="blue">Setup - General</font>|7000|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Other</font>|7001|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Main Power Unit</font>|7002|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Packaging</font>|7003|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Turnstile</font>|7100|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Cropping State</font>|7101|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Coil Stage</font>|7102|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Coil Lift</font>|7103|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Uncoiler</font>|7104|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Peeler</font>|7105|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Crop Shear</font>|7106|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when a clearance is being adjusted.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Flattener</font>|7107|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the rolls are being adjusted.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Straightener</font>|7108|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the rolls are being adjusted.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Straightener Power Unit</font>|7109|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Leveler</font>|7110|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Applicator</font>|7111|No|Yes|<ul><li><b>Auto </b>- Optional Active when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Edge Trim</font>|7112|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when any setup adjustments are being made.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Thread</font>|7113|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Dust Collector 01</font>|7114|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Dust Collector 02</font>|7115|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Dust Collector 03</font>|7116|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Roll In Conveyor</font>|7200|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Destacker</font>|7201|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Entry Stack Table</font>|7202|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Drag Pad</font>|7300|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Drag Roll 01</font>|7301|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Drag Roll 02</font>|7302|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Crop Shear</font>|7303|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when a clearance is being adjusted.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Alignment System</font>|7304|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Recoiler</font>|7305|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Coil Stage</font>|7306|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Coil Lift</font>|7307|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Stack Table</font>|7403|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Turnstile</font>|7308|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Conveyor</font>|7400|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Diverter</font>|7401|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Drop Stacker</font>|7402|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Rollouts</font>|7404|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Stretcher Leveler</font>|7500|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the Stretcher is traversing for a setup.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Stretcher Leveler Power Unit</font>|7501|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Grip Feed</font>|7502|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the length is changing.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Roll Feed</font>|7503|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Start/Stop Pinch Rolls</font>|7504|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Exit Applicator</font>|7505|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Prime Shear</font>|7506|Yes|Yes|<ul><li><b>Auto </b>- Optional Active when any setup functions such as clearance, rake, or angle are active.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Entry Pinch Roll</font>|7600|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Offline Base</font>|7601|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Inline Base</font>|7602|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Arbor 01</font>|7603|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Arbor 02</font>|7604|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter Arbor 03</font>|7605|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Scrap Winder</font>|7606|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Scrap Baller</font>|7607|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Scrap Chopper</font>|7608|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slitter</font>|7609|Yes|Yes|<ul><li><b>Auto </b>- Optional Active during the knife setup or when the access gate is open.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Strip Stacker</font>|7610|Yes|Yes|<ul><li><b>Auto </b>- Optional Active during the automatic strip divider setup or teardown.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Slit Stack Separator</font>|7611|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Stitcher</font>|7700|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Scale Breaker</font>|7701|Yes|Yes|<ul><li><b>Auto </b>- Active during roll adjustments.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Scale Breaker Filtration</font>|7702|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - EPS Cell 01</font>|7703|Yes|Yes|<ul><li><b>Auto </b>- Active during turbine angle adjustments.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - EPS Cell 02</font>|7704|Yes|Yes|<ul><li><b>Auto </b>- Active during turbine angle adjustments.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - EPS Cell 03</font>|7705|Yes|Yes|<ul><li><b>Auto </b>- Active during turbine angle adjustments.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - EPS Cell 04</font>|7706|Yes|Yes|<ul><li><b>Auto </b>- Active during turbine angle adjustments.</li></ul><ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Drying System</font>|7707|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|
|<font color="blue">Setup - Oiler</font>|7708|No|Yes|<ul><li><b>Manual </b>- Selected when a setup is taking place on the listed machine.</li></ul>|

<a id="standby-states"> </a>
### Standby States
| State | Number | Auto | Manual | Information |
|---|---|---|---|---|
|<font color="#aa0">Break</font>|5000|No|Yes|<ul><li><b>Manual </b>- The line is stopped for break.</li></ul>|
|<font color="#aa0">Lunch</font>|5001|No|Yes|<ul><li><b>Manual </b>- The line is stopped for lunch.</li></ul>|
|<font color="#aa0">Shutdown</font>|5002|No|Yes|<ul><li><b>Manual </b>- The line is stopped for a shutdown period. This is typically used to indicate that no shift is running.</li></ul>|
|<font color="#aa0">Meeting</font>|5003|No|Yes|<ul><li><b>Manual </b>- The line is stopped for a meeting.</li></ul>|
|<font color="#aa0">Training</font>|5004|No|Yes|<ul><li><b>Manual </b>- The line is stopped for training.</li></ul>|</ul>
`