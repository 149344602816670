import React, { useContext } from 'react';
import { IMessageModel } from '__core/models';
import { TableColumnProps } from 'antd';
import { AppContext, useFilters } from '../../state';
import { ItemListView } from 'components/lists/item-list-view';
import { MessageViewData } from './item-view';
import { useSorter } from 'hooks';

export const MessageListView = () => {

    const { state } = useContext(AppContext);

    const { genericFilters } = useFilters('subscriptions');
    const { genericSorter } = useSorter();

    const smallCols: TableColumnProps<IMessageModel>[] = [
        {
            title: 'Message',
            dataIndex: 'id',
            render: (_, { message }) => (
                <>
                    <div>{message}</div>
                </>
            ),
            ...genericSorter({ name: 'Message' }),
            ...genericFilters({ name: 'Message' }),
        },
    ]

    const largeCols: TableColumnProps<IMessageModel>[] = [
        {
            title: 'Message',
            dataIndex: 'message',
            ...genericSorter({ name: 'message' }),
            ...genericFilters({ name: 'message' }),
        },
        {
            title: 'Filters',
            dataIndex: 'filters',
            ...genericSorter({ name: 'filters' }),
            ...genericFilters({ name: 'filters' }),
        },
    ]

    const prefix = '/rbi/messages';
    return (
        <ItemListView
            title='Messages'
            allowEdit={state.userAccount?._is_rbi && state.userAccount?.is_admin}
            itemKey='id'
            loadItemPermission={async () => true}
            loadItems={async ({ refresh }) => state.api.read.message.list({ refresh })}
            loadViewItem={async ({ item: { id }, refresh }) => state.api.read.message.item({ message_id: id, refresh })}
            ViewData={MessageViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `${prefix}/edit/${item.id}`}
            columnViewNav={item => `${prefix}/view/${item.id}`}
            itemNewNav={`${prefix}/new`}
            itemLogId={({ id }) => id}
        />
    )
}
