import React, { useContext, useState } from 'react';
import { UUID } from '__core/utils/types';
import { IJobModel, IJobConfigModel } from '__core/models';
import { TableColumnProps, List } from 'antd';
import { AppContext, useFilters } from '../../state';
import { ItemListView } from 'components/lists/item-list-view';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { JobViewData } from './item-view';
import { useShowMessages, useSorter } from 'hooks';
import { formatTimeSince } from 'utils/time-format';
import ReactMarkdown from 'react-markdown';

const SUBSCRIPTION_PART_NUMBERS = <List size='small'>
    <List.Item><b><span style={{ width: 100, display: 'inline-block' }}>Period</span> Part Number</b></List.Item>
    <List.Item><span style={{ width: 100, display: 'inline-block' }}>1 Year</span>46-02501</List.Item>
    <List.Item><span style={{ width: 100, display: 'inline-block' }}>3 Years</span>46-02503</List.Item>
    <List.Item><span style={{ width: 100, display: 'inline-block' }}>5 Years</span>46-02505</List.Item>
</List>

const EXPIRATION_WARNING_DAYS_LIMIT = 60 * 60 * 24 * 90;
const EXPIRATION_WARNING_TITLE = 'Subscription Expiring Soon!';
const EXPIRATION_WARNING_BODY = (days: number) => <>Your Subscription is expiring in {days} days. Please contact Red Bud Industries to renew your subscription to continue uninterrupted use.{SUBSCRIPTION_PART_NUMBERS}</>
const EXPIRATION_EXPIRED_TITLE = 'Subscription Expired!';
const EXPIRATION_EXPIRED_MESSAGE = <>Please contact Red Bud Industries to renew your subscription.{SUBSCRIPTION_PART_NUMBERS}</>
const NO_JOB_ACCESS_TEXT = 'Job access not allowed for user';

export const JobListView = () => {

    const navigate = useNavigate();

    const { state, dispatch, notify } = useContext(AppContext);
    const [itemLoading, setItemLoading] = useState('');
    const { errorNotification } = useShowMessages();
    const { genericFilters } = useFilters('job');
    const { genericSorter } = useSorter();

    const customer_id = state.customer?.id || '';

    const smallCols: TableColumnProps<IJobModel>[] = [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (_, { name, alias, city, state, country }) => (
                <>
                    <div>{name}</div>
                    <div><i>{alias}</i></div>
                    <div>{city}, {state} {country}</div>
                </>
            ),
            ...genericFilters({ name: 'name' })
        },
    ]

    const getTimeUntilExpiration = (subscription_expiration: number) => subscription_expiration - DateTime.now().toUnixInteger();

    const largeCols: TableColumnProps<IJobModel>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            ...genericSorter({ name: 'name' }),
            ...genericFilters({ name: 'name' }),
        },
        {
            title: 'Alias',
            dataIndex: 'alias',
            ...genericSorter({ name: 'alias' }),
            ...genericFilters({ name: 'alias' }),
        },
        {
            title: 'City',
            dataIndex: 'city',
            ...genericSorter({ name: 'city' }),
            ...genericFilters({ name: 'city' }),
        },
        {
            title: 'State',
            dataIndex: 'state',
            ...genericSorter({ name: 'state' }),
            ...genericFilters({ name: 'state' }),
        },
        {
            title: 'Country',
            dataIndex: 'country',
            ...genericSorter({ name: 'country' }),
            ...genericFilters({ name: 'country' }),
        },
        {
            title: 'Time Zone',
            dataIndex: 'time_zone',
            ...genericSorter({ name: 'time_zone' }),
            ...genericFilters({ name: 'time_zone' }),
        },
        {
            title: 'Language',
            dataIndex: 'language',
            ...genericSorter({ name: 'language' }),
            ...genericFilters({ name: 'language' }),
        },
        {
            title: 'Units',
            dataIndex: 'is_metric',
            render: isMetric => isMetric ? 'Metric' : 'Imperial',
            ...genericSorter({ name: 'is_metric' }),
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription_expiration',
            render: (sub, { time_zone }) => {
                const subDateTime = DateTime.fromSeconds(sub);
                const expireIn = getTimeUntilExpiration(sub);
                subDateTime.setZone(time_zone);
                return <div style={{ color: expireIn <= 0 ? '#C00' : expireIn <= 0 ? '#770' : '#000' }}>{subDateTime.toFormat('yyyy-MM-dd')}</div>;
            },
            ...genericSorter({ name: 'subscription_expiration' }),
        },
        {
            title: 'Last Server Ping',
            dataIndex: 'last_alive',
            render: last_alive => formatTimeSince(last_alive),
            ...genericSorter({ name: 'last_alive' }),
        },
    ]

    const setJob = async (job_id: UUID) => {
        setItemLoading(job_id);
        try {
            const jobPromise = state.api.read.job.item({ customer_id, job_id });
            let config: IJobConfigModel | undefined = undefined;
            try {
                const configPromise = state.api.read.jobConfig.item({ customer_id, job_id });
                config = await configPromise;
            } catch { }
            const job = await jobPromise;
            if (job) {
                const expireIn = getTimeUntilExpiration(job.subscription_expiration);
                if (expireIn < EXPIRATION_WARNING_DAYS_LIMIT && !state.userAccount?._is_rbi)
                    notify(EXPIRATION_WARNING_TITLE, EXPIRATION_WARNING_BODY(Math.trunc(expireIn / 60 / 60 / 24)))
                dispatch({ type: 'set-job', job, config });
                navigate('/dashboard');
            }
        } catch {
            errorNotification('Unable to load job.');
        }
        setItemLoading('');
    }

    const getJobMessages = async (job_id: UUID) => {
        const messages = await state.api.read.jobMessages.list({ job_id });
        messages.forEach(message => notify('NOTICE', <ReactMarkdown>{message}</ReactMarkdown>));
    }

    return (
        <ItemListView
            title='Jobs'
            allowEdit={state.userAccount?._is_rbi && state.userAccount.is_admin}
            itemKey='id'
            loadItemPermission={async () => !!customer_id}
            loadItems={async ({ refresh }) => {
                const jobList = await state.api.read.job.list({ customer_id, refresh });
                return jobList.filter(job => {
                    const access = state.userAccount?.jobs[job.id];
                    return state.userAccount?._is_rbi || state.userAccount?.is_admin || access === 'Read Only' || access === 'Read Write';
                });
            }
            }
            loadViewItem={async ({ item: { id }, refresh }) => state.api.read.job.item({ customer_id, job_id: id, refresh })}
            ViewData={JobViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `/jobs/edit/${item.id}`}
            columnViewNav={item => `/jobs/view/${item.id}`}
            itemNewNav='/jobs/new'
            onSelect={item => {

                if (state.userAccount?._is_rbi) {
                    setJob(item.id);
                    getJobMessages(item.id);
                } else {
                    const jobAccess = state.userAccount?.jobs[item.id];
                    if (jobAccess === 'Read Only' || jobAccess === 'Read Write' || state.userAccount?.is_admin) {
                        if (getTimeUntilExpiration(item.subscription_expiration) > 0) {
                            setJob(item.id);
                            getJobMessages(item.id);
                        } else {
                            notify(EXPIRATION_EXPIRED_TITLE, EXPIRATION_EXPIRED_MESSAGE);
                        }
                    } else {
                        errorNotification(NO_JOB_ACCESS_TEXT)
                    }
                }
            }}
            isItemLoading={item => item.id === itemLoading}
            itemIsLoading={!!itemLoading}
            itemLogId={({ id }) => id}
        />
    )
}
