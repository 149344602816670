import React from 'react';
import { Select, Switch, Tooltip } from 'antd';
import { ICompletedItemFilterItem } from '../../../../state';

interface IProps {
    label?: React.ReactNode | string;
    toolTip?: string;
    index?: number,
    group: 'standard' | 'data' | 'extended';
    value?: ICompletedItemFilterItem;
    onChange: (value: ICompletedItemFilterItem) => void;
    options: Record<string, string>,
    mobile?: boolean;
}

export const WrappedSelectFilterItem = ({
    label,
    toolTip,
    index = 0,
    group,
    value = { use: false, type: 'value', value: 0, max: 100, index, group },
    options,
    onChange,
    mobile,
}: IProps) => {
    const switchControl = <><Switch style={{ marginRight: 5 }} checked={value.use} onChange={() => onChange({ ...value, use: !value.use })} /><Tooltip title={toolTip}>{label}</Tooltip></>
    const input = value.use
        ? <Select style={{ minWidth: 200 }} onChange={e => onChange({ ...value, value: e })} value={value} showSearch>
            {Object.entries(options).map(([name, value]) => <Select.Option value={name}>{value}</Select.Option>)}
        </Select>
        : null

    return (
        <div>
            {!mobile
                ? <>
                    <span style={{ display: 'inline-block', width: 300 }}>{switchControl}</span>
                    <span>{input}</span>
                </>
                : <h3>
                    {switchControl}
                    <div style={{ marginLeft: 40 }}>
                        {input}
                    </div>
                </h3>
            }
        </div>
    );
}