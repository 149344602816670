import React, {useContext, useState} from 'react';
import {ISubscriptionModel, subscriptionModelData} from '__core/models';
import {AppContext} from '../../state';
import {useParams} from 'react-router-dom';
import {IFormData, StandardForm, SubHeader, WrappedInput, WrappedSwitch} from 'components/forms';

interface IProps {
    isNew?: boolean;
}

export const SubscriptionItemEdit = ({isNew}: IProps) => {

    const [enterAsDays, setEnterAsDays] = useState(false);

    const {subscription_id = ''} = useParams();
    const {state, notify} = useContext(AppContext);

    const {customer, job} = state;
    const customer_id = customer?.id || '';
    const job_id = job?.id || '';
    
    const formData : IFormData<ISubscriptionModel> = ({
        data: {
            order_number,
            part_number,
            part_quantity,
            length_years,
            length_days
        },
        update,
    }) =>  
    <>
        <SubHeader label='General' help={[]}>
            <WrappedInput
                label="Order Number"
                help={[]}
                value={order_number}
                onChange={order_number => update({order_number})}
                />
            <WrappedInput
                label="Part Number"
                help={[]}
                value={part_number}
                onChange={part_number => update({part_number})}
                />
            <WrappedInput
                label="Part Quantity"
                help={[]}
                value={part_quantity}
                onChange={part_quantity => update({part_quantity})}
                />
            <WrappedSwitch
                label="Enter Duration As Days?"
                help={[]}
                value={enterAsDays}
                onChange={() => {
                    setEnterAsDays(!enterAsDays);
                    update({length_days: 0, length_years: 0});
                }}
             />
            <WrappedInput
                label={`Duration (${enterAsDays ? 'days' : 'years'})`}
                help={[]}
                value={enterAsDays ? length_days : length_years}
                onChange={length => enterAsDays ? update({length_days: length}) : update({length_years: length})}
                />
        </SubHeader>
    </>

    const {insert, update, delete: _delete} = state.api.write.subscription;
    
    return <StandardForm 
            title='Subscription'
            loadItemPermission={async () => !!customer_id && !!job_id && !!subscription_id}
            onLoad={ isNew ? undefined : async () => state.api.read.subscription.item({customer_id, job_id, subscription_id, refresh: true})}
            getNew={() => subscriptionModelData.getNewModel({job_id})}
            onCancelNavigate='/admin/subscriptions'
            onInsert={async item => insert({customer_id, job_id, item})}
            onUpdate={async item => update({customer_id, job_id, subscription_id, item})}
            onDelete={async () => _delete({customer_id, job_id, subscription_id})}
            formData={formData}
            />
}
