import React, {useContext, useState} from 'react';
import {UUID} from '__core/utils/types';
import {IScheduleModel, IShiftData} from '__core/models';
import {TableColumnProps} from 'antd';
import {AppContext, useFilters} from '../../state';
import {ItemListView} from 'components/lists/item-list-view';
import { ScheduleViewData } from './item-view';
import {useNavigate} from 'react-router-dom';
import {useSorter} from 'hooks';

export const ScheduleListView = () => {

    const navigate = useNavigate();

    const {state, dispatch} = useContext(AppContext);
    const [itemLoading, setItemLoading] = useState('');

    const {genericFilters} = useFilters('schedule');
    const {genericSorter} = useSorter();

    const customer_id = state.customer?.id || '';
    const job_id = state.job?.id || '';

    const smallCols : TableColumnProps<IScheduleModel>[] = [
        {
            title: 'Schedule',
            dataIndex: 'name',
            render: (_,{name, shifts}) => (
                <>
                <div><i>{name}</i></div>
                <div>Shifts: {shifts.map(({name}:IShiftData) => name).join(', ')}</div>
                </>
            ),
            ...genericSorter({name: 'name'}),
            ...genericFilters({name: 'name'}),

        },
    ]

    const largeCols : TableColumnProps<IScheduleModel>[] = [        
        {
            title: 'Name',
            dataIndex: 'name',
            ...genericSorter({name: 'name'}),
            ...genericFilters({name: 'name'}),
        },       
        {
            title: 'Shifts',
            dataIndex: 'shifts',
            render: shifts => shifts.map(({name}:IShiftData) => name).join(', '),
        },
    ]

    
    const setSchedule = async (schedule_id: UUID) => {
        setItemLoading(schedule_id);
        try{
            const schedule = await state.api.read.schedule.item({customer_id, job_id, schedule_id})
            if (schedule){
                dispatch({type: 'set-schedule', schedule})
                navigate('/dashboard');
            }
        }catch{
            setItemLoading('');
        }
    }


    return (
        <ItemListView
            title='Schedules'
            allowEdit={state.userAccount?.is_admin || state.userAccount?.jobs[job_id] === 'Read Write'}
            itemKey='id'
            loadItemPermission={async () => !!customer_id && !!job_id}
            loadItems={async ({refresh}) => state.api.read.schedule.list({customer_id, job_id, refresh})}
            loadViewItem={async ({item: {id}, refresh}) => state.api.read.schedule.item({customer_id, job_id, schedule_id: id, refresh})}
            ViewData={ScheduleViewData}
            smallColumns={smallCols}
            largeColumns={largeCols}
            columnEditNav={item => `/schedules/edit/${item.id}`}
            columnViewNav={item => `/schedules/view/${item.id}`}
            itemNewNav='/schedules/new'
            onSelect={schedule => setSchedule(schedule.id)}
            isItemLoading={item => item.id === itemLoading}
            itemIsLoading={!!itemLoading}
            itemLogId={({id}) => id}
        />
    )
}
