import React from 'react';

interface IProps {
    label: string;
    help: string[];
    children?: React.ReactNode;
}

export const SubHeader = ({
    label,
    children,
}: IProps) => {
    return (
        <>
            <h4 style={{background: '#444', color: '#fff', padding: 5, margin: 2}}>{label}</h4>
            {children}
        </>
    )
}