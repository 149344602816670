import React, { useContext } from 'react';
import {
    LogoutOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { Typography, Button, Switch } from 'antd';
import { AppContext } from '../../state';
import { useShowMessages } from 'hooks';
import { UserPreferences } from './preferences';

interface IProps {

};

export const CurrentUserView = ({ }: IProps) => {
    const { state, dispatch } = useContext(AppContext);
    const name = state.userAccount?.name || '';
    const email = state.userAccount?.email || '';
    const admin = state.userAccount?.is_admin || false;

    const { successNotification } = useShowMessages();
    return (
        <div style={{ padding: 20 }}>
            <Typography.Title>{name}</Typography.Title>
            <p>{email}</p>
            {admin ? <p><b>ADMINISTRATOR</b></p> : null}
            {state.job
                ? <div style={{ margin: 10 }}>
                    <Switch checked={state.job.is_metric} onChange={() => dispatch({ type: 'change-job-metric', metric: !state.job?.is_metric })} /> Show line data in metric
                </div>
                : null
            }
            <div>
                <Button style={{ width: 200, margin: 5 }} type='primary' onClick={() => {
                    state.api.clearCache();
                    successNotification('User cache cleared.')
                }}>
                    Clear Cache <DeleteOutlined />
                </Button>
            </div>
            {
                state.userAccount?._is_rbi
                    ? <>
                        <div>
                            <Button style={{ width: 200, margin: 5, background: 'red', borderColor: 'red', color: 'white' }} onClick={() => {
                                dispatch({ type: 'set-user', userAuth: state.userAuth!, userAccount: { ...state.userAccount!, _is_rbi: false } });
                            }}>
                                Turn Off RBI For Session
                            </Button>
                        </div>
                        {
                            state.userAccount.is_admin
                                ? <div>
                                    <Button style={{ width: 200, margin: 5, background: 'red', borderColor: 'red', color: 'white' }} type='primary' onClick={() => {
                                        dispatch({ type: 'set-user', userAuth: state.userAuth!, userAccount: { ...state.userAccount!, is_admin: false } });
                                    }}>
                                        Turn Off Admin For Session
                                    </Button>
                                </div>
                                : null
                        }
                    </>
                    : null
            }
            <div>
                <Button style={{ width: 200, margin: 5 }} type='primary' onClick={() => dispatch({ type: 'logout' })}>
                    Logout <LogoutOutlined />
                </Button>
            </div>
            {/*<UserPreferences />*/}
        </div>
    )
}