import React from 'react';
import {IJobOverviewViewModel} from '__core/view-models';
import {IViewData, SubHeader, WrappedLabel, WrappedDateDisplay} from 'components/forms';

export const JobOverviewViewData : IViewData<IJobOverviewViewModel> = ({
    data: {
        customer_name,
        job_name,
        subscription_expiration,
    },
}) => 
<>
<SubHeader label='General' help={[]}>
    <WrappedLabel
        label="Customer"
        help={[]}
        value={customer_name}
        />
    <WrappedLabel
        label="Job"
        help={[]}
        value={job_name}
        />
    <WrappedDateDisplay
        label="Expiration"
        help={[]}
        value={subscription_expiration}
        />
    </SubHeader>    
</>