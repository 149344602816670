import React, { useContext, useState } from 'react';
import { AppContext, ICompletedItemFilter } from '../../../state';
import { BasicForm, SubHeader, IFormData } from 'components/forms';
import { WrappedNumericFilterItem, WrappedSelectFilterItem, WrappedStringFilterItem } from './filter-items';
import { FilterOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';
import { HEADER_TOOLTIPS } from '../header-tooltips';

interface IProps {
    itemLength: number;
}

export const CompletedItemFilterView = ({ itemLength }: IProps) => {

    const { state, dispatch } = useContext(AppContext);
    const [visible, setVisible] = useState(false);

    if (!state.config) return null;

    const config = state.config!.config.completedItems;

    const update = (filter: Partial<ICompletedItemFilter>): void => {
        //@ts-ignore
        dispatch({ type: 'set-completed-item-filter', filter: { ...state.completedItemFilter, ...filter } })
    }

    const dataEntries = Object.entries(config.data);
    dataEntries.sort((a, b) => Number(a[0]) - Number(b[0]));


    const extendedEntries = Object.entries(config.extended);
    extendedEntries.sort((a, b) => Number(a[0]) - Number(b[0]));

    const formData: IFormData<ICompletedItemFilter> = ({
        data,
        update,
    }) =>
        <>
            <SubHeader label='General' help={[]}>
                <WrappedNumericFilterItem
                    label='Duration'
                    toolTip={HEADER_TOOLTIPS.duration}
                    index={1}
                    group='standard'
                    value={data.duration}
                    onChange={duration => update({ duration })}
                    mobile={state.isMobile} />
                <WrappedNumericFilterItem
                    label='Quantity'
                    toolTip={HEADER_TOOLTIPS.quantity}
                    index={0}
                    group='standard'
                    value={data.quantity}
                    onChange={quantity => update({ quantity })}
                    mobile={state.isMobile} />
            </SubHeader>
            <SubHeader label='Data' help={[]}>
                {dataEntries.map(([index, item]) =>
                    item.asString
                        ? <WrappedStringFilterItem
                            label={item.header}
                            toolTip={item.description}
                            index={Number(index)}
                            group='data'
                            value={data[item.header]}
                            onChange={itemData => update({ [item.header]: itemData })}
                            mobile={state.isMobile} />
                        : item.states
                            ? <WrappedSelectFilterItem
                                label={item.header}
                                toolTip={item.description}
                                index={Number(index)}
                                group='data'
                                value={data[item.header]}
                                options={item.states}
                                onChange={itemData => update({ [item.header]: itemData })}
                                mobile={state.isMobile} />
                            : <WrappedNumericFilterItem
                                label={item.header}
                                toolTip={item.description}
                                index={Number(index)}
                                group='data'
                                value={data[item.header]}
                                onChange={itemData => update({ [item.header]: itemData })}
                                mobile={state.isMobile} />
                )}
            </SubHeader>
            {state.timeIntervalSelect.extended
                ? <SubHeader label='Extended' help={[]}>
                    {extendedEntries.map(([index, item]) =>
                        item.asString
                            ? <WrappedStringFilterItem
                                label={item.header}
                                toolTip={item.description}
                                index={Number(index)}
                                group='extended'
                                value={data[item.header]}
                                onChange={itemData => update({ [item.header]: itemData })}
                                mobile={state.isMobile} />
                            : item.states
                                ? <WrappedSelectFilterItem
                                    label={item.header}
                                    toolTip={item.description}
                                    index={Number(index)}
                                    group='extended'
                                    value={data[item.header]}
                                    options={item.states}
                                    onChange={itemData => update({ [item.header]: itemData })}
                                    mobile={state.isMobile} />
                                : <WrappedNumericFilterItem
                                    label={item.header}
                                    toolTip={item.description}
                                    index={Number(index)}
                                    group='extended'
                                    value={data[item.header]}
                                    onChange={itemData => update({ [item.header]: itemData })}
                                    mobile={state.isMobile} />
                    )}
                </SubHeader>
                : null
            }
        </>

    return (
        <div>
            <div>
                <Button size='small' type={Object.values(state.completedItemFilter).some(x => x.use) ? 'primary' : 'default'} onClick={() => setVisible(true)}><FilterOutlined />Filter</Button>
                <span style={{ marginLeft: 10 }}>Items: {itemLength}</span>
            </div>
            <Drawer visible={visible} onClose={() => setVisible(false)} width={state.isMobile ? '100%' : 800}>
                <h4>Completed Item Filter</h4>
                <BasicForm>
                    {formData({ data: state.completedItemFilter, update })}
                </BasicForm>
            </Drawer>
        </div>
    );
}