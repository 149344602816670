import React from 'react';
import {Input} from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label?: React.ReactNode | string;
    name?: string;
    placeholder?: string;
    help: string[];
    value : T;
    onChange: (value: T) => void;
}

export const WrappedTextBox = <T extends string | number>({
    label,
    name,
    placeholder,
    help,
    value,
    onChange,
}: IProps<T>) => {
    const isNumber = typeof value === 'number';
    return (
        <StandardFormItem label={label} name={name} help={help}>
            <Input.TextArea
                placeholder={placeholder}
                value={value}
                onChange={e => onChange((isNumber ? Number(e.target.value) : e.target.value.toString()) as T)}
                />
        </StandardFormItem>
    );
}