import React from 'react';
import {Table,TableColumnProps, Button, Spin} from 'antd';
import {ReloadOutlined, PlusOutlined} from '@ant-design/icons';

interface IProps<T> {
    allowEdit?: boolean;
    loading: boolean;
    items: T[];
    columns: TableColumnProps<T>[];
    onRefresh: () => void;
    onNew: () => void;
}

export const TableView = <T extends object>({
    allowEdit = false,
    loading,
    items, 
    columns,
    onRefresh,
    onNew,
}: IProps<T>) => {

    if (loading) return <Spin tip='Loading...' size='large'/>

    return (
        <>
            <span style={{float: 'right', margin: 5}}>
                {
                    allowEdit
                    ?    <Button 
                            style={{ margin: 5}}
                            onClick={onNew}
                            size='small'
                            type='primary'
                        >
                            <PlusOutlined/> New
                        </Button>
                    :   null
                }
                <Button 
                    style={{margin: 5}} 
                    onClick={onRefresh}
                    size='small'
                >
                    <ReloadOutlined/> Reload
                </Button>
            </span>
            <Table
                dataSource={items}
                columns={columns}
                size='small'
                pagination={{'pageSizeOptions': [10,25,50,100], showSizeChanger: true}}
            />
        </>
    )
}
