import { UUID, UnixTime } from '../utils/types';
import { validator } from '../utils/validator';
import { IModelData } from './_generic-model-data-interface';

export interface IStressStrainModel {
    id: UUID;
    job_id: UUID;
    time: UnixTime;
    data: Record<number, number | boolean>;
    curve: [number, number][];
}

export type IStressStrainListModel = Omit<IStressStrainModel, 'curve'>;

const updateKeys: Extract<keyof IStressStrainModel, string>[] = [
]

const insertKeys: Extract<keyof IStressStrainModel, string>[] = [
    'job_id',
    'time',
    'data',
    'curve',
]

export const stressStrainModelData: IModelData<IStressStrainModel, void> = {
    table: 'stress_strain',
    selectKeys: [
        'id',
        ...insertKeys,
    ],
    selectListKeys: [
        'id',
        'time',
        'data',
    ],
    insertKeys,
    updateKeys,
    getNewModel: () => ({
        id: '',
        job_id: '',
        time: 0,
        data: {},
        curve: [],
    }),
    validate: ({ partial }) => item => validator<IStressStrainModel>({
        item,
        partial,
        config: {
            job_id: {
                name: 'Job Reference',
                type: 'UUID',
            },
            time: {
                type: 'number',
            },
            data: {
                type: 'object',
            },
            curve: {
                type: 'array',
            },
        },

    })
}
