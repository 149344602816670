import React from 'react';

import {Button} from 'antd';

interface IShiftItem {
    name: string;
    color: string;
}

interface IProps {
    editable: boolean;
    items: IShiftItem[]
    selected: string | null;
    overlap: boolean;
    onChangeSelected: (name: string | null) => void;
    onChangeOverlap: () => void;
}

export const Selector = ({
    editable,
    items,
    overlap,
    selected,
    onChangeSelected,
    onChangeOverlap,
}: IProps) =>

<div>
    {   editable
        ?   <div style={{marginBottom: 20}}>
                <Button style={{margin: 2, width: 150}} type={overlap ? 'primary' : 'default'} onClick={onChangeOverlap}>Overlap</Button>
                <Button style={{margin: 2, width: 150}} type={selected ? 'default' : 'primary'} onClick={() => onChangeSelected(null)}>Erase</Button>
            </div>
        : null
    }
    <div style={{marginBottom: 20}}>
        {
            items.map(({name, color}) =>
                <Button
                    size={editable ? 'middle' : 'small'}
                    key={name}
                    style={{
                        margin: 2,
                        width: 150,
                        borderWidth: 2,
                        borderColor: color,
                        backgroundColor: selected === name ? color : '#fff',
                        color: selected === name ? '#fff' : '#000',
                    }}
                    onClick={editable ? () => onChangeSelected(name) : undefined}>
                    {name}
                </Button>
            )
        }
    </div>
</div>
