import React, { useEffect, useState } from 'react';
import { AuthenticationDetails, CognitoUserPool, CognitoUser, IAuthenticationCallback } from 'amazon-cognito-identity-js';
import { useShowMessages } from './';

const POOL_DATA = {
    UserPoolId: 'us-east-2_7NsrdIZsO',
    ClientId: '4vtefko1t6iaatko47qbnumnn5',
}

interface IUserLogin {
    user_account_id: string;
    userAuth: string;
    customer_id: string;
}

export const useLogin = () => {

    const [username, setUsername] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [passwordVerificationCodeActive, setPasswordVerificationCodeActive] = useState(false);
    const [password, setPassword] = useState('');
    const [user, setUser] = useState<CognitoUser | null>(null);
    const [passwordResetRequired, setPasswordResetRequired] = useState(false);
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [userLoginInfo, setUserLoginInfo] = useState<IUserLogin | null>(null);
    const [busy, setBusy] = useState(false);

    const { errorNotification, successNotification } = useShowMessages();

    const clear = () => {
        setUsername('');
        setPassword('');
        setNewPassword1('');
        setNewPassword2('');
    }

    const showError = (id: number) => {
        setBusy(false);
        errorNotification(`Oops, something went wrong! ID:${id}`)
    }

    if (user) {
        user.confirmPassword
    }

    useEffect(() => {
        const userPool = new CognitoUserPool(POOL_DATA);
        const userData = {
            Username: username,
            Pool: userPool,
        }
        setUser(new CognitoUser(userData))
    }, [username, password, setUser])

    const authCallbacks: IAuthenticationCallback = {
        onSuccess: result => {
            const accessToken = result.getAccessToken().getJwtToken();
            if (result.isValid()) {
                user?.getUserAttributes((err, res) => {
                    if (err) showError(7)
                    else {
                        const customer_id = res?.find(({ Name }) => Name === 'custom:customerId')?.Value || '';
                        const sub = res?.find(({ Name }) => Name === 'sub')?.Value || '';
                        if (accessToken && customer_id && sub) {
                            setUserLoginInfo({
                                user_account_id: sub,
                                userAuth: accessToken,
                                customer_id,
                            })
                        } else showError(6)
                    }
                })
            } else showError(5);
            clear();
        },
        onFailure: err => {
            setBusy(false);
            errorNotification(`Login failed! - ${err.message}`);
            if (err.message === 'Password reset required for the user')
                setPasswordVerificationCodeActive(true);
            clear();
        },

        newPasswordRequired: () => {
            setBusy(false);
            setPasswordResetRequired(true);
            setNewPassword1('');
            setNewPassword2('');
        }

    }

    const setNewPassword = () => {
        if (user) {
            if (newPassword1 === newPassword2) {
                if (newPassword1.length >= 16) {
                    setBusy(true);
                    user.completeNewPasswordChallenge(newPassword1, {}, authCallbacks);
                } else showError(4)
            } else showError(3)
        } else showError(2)
    }

    const login = () => {
        if (user) {
            setBusy(true);
            const authenticationData = {
                Username: username,
                Password: password,
            };
            const authenticationDetails = new AuthenticationDetails(authenticationData);

            user.authenticateUser(authenticationDetails, authCallbacks);
        } else showError(1)
    }

    const changePassword = () => {
        if (user) {
            if (newPassword1 === newPassword2) {
                if (newPassword1.length >= 16) {
                    setBusy(true);
                    user.confirmPassword(verificationCode, newPassword1, {
                        onSuccess() {
                            setPassword(newPassword1);
                            successNotification('Password successfully reset!');
                            setPasswordVerificationCodeActive(false);
                            setBusy(false);
                        },
                        onFailure(err) {
                            setBusy(false);
                            errorNotification('Failed to reset password.');
                        },
                    })
                } else showError(12)
            } else showError(11)
        } else showError(10)
    }

    return {
        username,
        setUsername,
        password,
        setPassword,
        login,
        changePassword,
        passwordResetRequired,
        newPassword1,
        setNewPassword1,
        newPassword2,
        setNewPassword2,
        setNewPassword,
        userLoginInfo,
        verificationCode,
        setVerificationCode,
        passwordVerificationCodeActive,
        setPasswordVerificationCodeActive,
        busy,
        setBusy,
    }

}