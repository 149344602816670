import React from 'react';
import {Input} from 'antd';
import { StandardFormItem } from './standard-form-item';

interface IProps<T> {
    label?: React.ReactNode | string;
    name?: string;
    confidential?: boolean;
    prefix?: React.ReactNode;
    placeholder?: string;
    help: string[];
    value : T;
    onChange: (value: T) => void;
    unit?: string;
}

export const WrappedInput = <T extends string | number>({
    label,
    name,
    confidential,
    prefix,
    placeholder,
    help,
    value,
    onChange,
    unit,
}: IProps<T>) => {
    const isNumber = typeof value === 'number';
    return (
        <StandardFormItem label={label} name={name} help={help}>
            {
                confidential
                ?   <Input.Password
                        prefix={prefix}
                        placeholder={placeholder}
                        type={isNumber ? 'number' : 'text'}
                        value={value}
                        onChange={e => onChange((isNumber ? Number(e.target.value) : e.target.value.toString()) as T)}
                        suffix={unit}
                        />
                :   <Input
                        prefix={prefix}
                        placeholder={placeholder}
                        type={isNumber ? 'number' : 'text'}
                        value={value}
                        onChange={e => onChange((isNumber ? Number(e.target.value) : e.target.value.toString()) as T)}
                        suffix={unit}
                        />
            }
        </StandardFormItem>
    );
}