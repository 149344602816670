import React, {useState, useEffect, ReactNode} from 'react';
import axios from 'axios';
import {Row} from 'antd';

const style={
    height: '100vh',
    backgroundImage: 'url(/assets/img/background.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
}

const MaintenanceDisplay = () => 
    <Row justify="center" align="middle" style={style}>
                <div style={{padding: 20, background: 'rgba(210,210,210,0.9)', border: '5px solid white', width: 300}}>
                    <h2>Sorry!</h2>
                    <h4>We're down for maintenance at the moment.</h4>
                    <h4>Check back later.</h4>
                </div>
    </Row>

export const useMaintenance = () => {
    const [maintenanceDisplay, setMaintenanceDisplay] = useState<ReactNode | null>(null);

    useEffect(() => {
        (async () => {
            //const {data} = await axios.get('/maintenance.txt');
            //console.log(data.toString());
            //if (data.toString().includes('true')) 
            //    setMaintenanceDisplay(MaintenanceDisplay);
        })();
    })

    return {maintenanceDisplay};
}